import React from "react";
import intToLetters from "./intToLetters";

const FormSelect = (props) => {
    const handleChange = (e) => {
        props.updateValue(e.target.value)
    }

    return (
        <>
            <div className="input-field">
                <div className="top-input">
                    { props.top }
                    {
                        props.search || props.star

                        ?

                        <span className="required-star">
                            *
                        </span>

                        :

                        <>
                        </>
                    }
                </div>
                <select className="bottom-input metric-select" required={ props.required } value={ props.value } onChange={ handleChange }>
                    {
                        props.uuids.map((uuid, index) => {
                            return <option key={uuid} value={index}>
                                        { "Form " + intToLetters(index) }
                                    </option>
                                    
                        })
                    }
                </select>
            </div>
        </>
    );
}

export default FormSelect;