import React, { useEffect } from 'react';
import Plotly from 'plotly.js-dist';

const SmallBoxPlot = (props) => {
    useEffect(() => {
        const trace1 = {
            y: props.gradesData,
            type: 'box',
            name: 'Grades (points)',
            marker: { color: 'rgba(01, 105, 244, 255)' },
        };

        const trace2 = {
            y: props.timeData,
            type: 'box',
            name: 'Time (min)',
            marker: { color: 'rgba(106, 90, 205, 1)' },
        };

        const data = [trace1, trace2];

        const layout = {
            title: 'Time | Grades',
            margin: {
                t: 60,
                b: 60,
                l: 60,
                r: 60
            },
            showlegend:false
        };

        const config = {
            displayModeBar: false // Hide Plotly logo and mode bar
        };

        Plotly.newPlot('myDiv', data, layout, config);
    }, [props.gradesData, props.timeData]);

    return (
        <div id="myDiv" style={{ width: '100%', height: '600px' }}></div>
    );
};

export default SmallBoxPlot;
