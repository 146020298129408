import React from "react";

const BankOfQuestionsOption = ({ current, index, updateCurrent }) => {
    const handleClick = () => {
        updateCurrent(index - 1);
    }

    return (
        <>
            <div className={`bank-of-qs-option ${current ? "current" : ""}`} onClick={handleClick}>
                { index }
            </div>
        </>
    );
}

export default BankOfQuestionsOption;