import React, { useState, useEffect } from "react";
import TrashQuestion from "./trashQuestion";
import Grid from "./grid";
import BubbleWithTextView from "./bubbleWithTextView";
import { useParams } from "react-router-dom";
import saveFields from "./saveFields";

const TrueFalseQuestionEdit = ({ questionsToSave, showDrag, updateQuestionsToSave, provided, updateDeleteQuestion, index, points, question, uuid, questionType, bubbled, bankUuid }) => {
    const { courseCode, assessmentCode } = useParams();
    const [form, setForm] = useState({
        question: question,
        points: Number(points).toFixed(2),
        bubbled: bubbled,
        uuid: uuid,
        questionType: questionType
    });

    function updateBubbled(newBubbled) {
        if (newBubbled === form.bubbled) {
            setForm((prev) => ({
                ...prev,
                bubbled: -1
            }))
        } else {
            setForm((prev) => ({
                ...prev,
                bubbled: newBubbled
            }))
        }
    }

    function updateForm(key, value) {
        setForm((prev) => (
            {
                ...prev,
                [key]: value
            }
        ))
    }

    const submitForm = async () => {
        // construct a new body for post with all values that have changed
        const body = {};
        body.uuid = uuid
        body.questionType = questionType
        body.fields = {}

        // Check each property for changes
        Object.keys(form).forEach(key => {
            if (form[key] !== { question, points, bubbled, uuid, questionType }[key]) {
                if (key !== "uuid" || key !== "questionType") {
                    if (key === "points") {
                        if (form.points !== Number(points).toFixed(2)) {
                            body.fields[key] = form[key];
                        }
                    } else {
                        body.fields[key] = form[key];
                    }
                }
            }
        });

        if (Object.keys(body.fields).length === 0) {
            return;
        }

        // updateQuestionsToSave
        updateQuestionsToSave((prev) => ({
            ...prev,
            [form.uuid]: body
        }))
    };

    useEffect(() => {
        // Define a timeout to submit the form after 0.5s
        const timer = setTimeout(() => {
            submitForm();
        }, 500); // 500ms delay
    
        // Cleanup function to clear the timeout if dependencies change
        return () => {
            clearTimeout(timer);
        };
    }, [form.points, form.question]);

    useEffect(() => {
        submitForm();
    }, [form.bubbled])

    useEffect(() => {
        if (uuid in questionsToSave) {
            const savedFields = questionsToSave[uuid].fields;
            if (savedFields) {
                // Iterate over savedFields and update the form with the key-value pairs
                saveFields(form, savedFields, updateForm);
            }
        }
    }, [questionsToSave, uuid]);
    
    
    return (
        <>
            <div className="true-false-question-view">
                <div className="question-number">
                    <div className="grid-number-container">
                        {
                            showDrag

                            ?

                            <>
                                <Grid provided={provided}/>
                            </>

                            :

                            <>
                            </>

                        }
                        Q{ index }
                    </div>
                    <div className="pts">
                        <>
                            <input onBlur={submitForm} className="grading-box-question" type="none" value={form.points} onChange={(e) => {
                                updateForm("points", e.target.value);
                            }}/>
                            pts
                        </>
                        <TrashQuestion uuid={uuid} updateDeleteQuestion={updateDeleteQuestion} bankUuid={bankUuid}/>
                    </div>
                </div>
                <div className="text-response-area">
                    <textarea onBlur={submitForm} className="text-response-area-input" placeholder="Enter question" style={{ minHeight: "100px" }} value={form.question} onChange={(e) => {
                        updateForm("question", e.target.value);
                    }}/>
                </div>
                <div className="true-false-answer-block">
                    <BubbleWithTextView text={"True"} bubbled={form.bubbled === 1} updateBubbled={updateBubbled}/>
                    <BubbleWithTextView text={"False"} bubbled={form.bubbled === 0} updateBubbled={updateBubbled}/>
                </div>
            </div>
        </>
    );
}

export default TrueFalseQuestionEdit;