import React, { useState } from "react";
import TrashQuestion from "./trashQuestion";
import Grid from "./grid";
import BankOfQuestionsOption from "./bankOfQuestionsOption";
import AddAQuestionToBankForm from "./addAQuestionToBankForm";

const BankOfQuestions = ({ updateQuestions, typeOfQuestionToReturn, provided, updateDeleteQuestion, index, points, questions, uuid, updateFetchData }) => {
    const [current, setCurrent] = useState(0);
    const [addAQuestion, setAddAQuestion] = useState(false);

    function updateCurrent(current) {
        setCurrent(current);
    }

    function updateAddAQuestion(bool) {
        setAddAQuestion(bool);
    }
   
    return (
        <>
            <div className="true-false-question-view">
                <div className="question-number">
                    <div className="grid-number-container">
                        <Grid provided={provided}/>
                        B{ index }
                    </div>
                    <div className="pts">
                        <>                                           
                           { Number(points).toFixed(2) } pts
                        </>
                        <TrashQuestion uuid={uuid} updateDeleteQuestion={updateDeleteQuestion} bankUuid={uuid}/>
                    </div>
                </div>
                <div className="text-response-area">
                    <div className="bank-questions-select">
                        <div style={{ cursor: "pointer" }} onClick={() => updateAddAQuestion(true)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path stroke={"var(--almost-black)"} strokeWidth={"1"} d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
                            </svg>
                        </div>
                        {
                            questions && questions.length > 0

                            ?

                            <>
                                {
                                    questions.map((question, index) => {
                                        return <BankOfQuestionsOption key={question.uuid} current={current === index} index={index + 1} updateCurrent={updateCurrent}/>
                                    })
                                }
                            </>

                            :

                            <>
                            </>

                        }
                    </div>
                </div>
                <div className="text-response-area">
                    {
                        questions && questions.length > 0
                        ?
                        <>
                            {questions.map((question, index) => (
                                <div 
                                    key={index} 
                                    style={{ display: index === current ? 'block' : 'none' }}
                                >
                                    {typeOfQuestionToReturn(question, index + 1, provided, false, uuid)}
                                </div>
                            ))}
                        </>
                        :
                        <></>
                    }
                </div>
            </div>
            { addAQuestion ? <AddAQuestionToBankForm updateQuestions={updateQuestions} bankUuid={uuid} updateAddAQuestion={updateAddAQuestion} updateFetchData={updateFetchData}/> : <></> }
        </>
    );
}

export default BankOfQuestions;