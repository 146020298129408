import React, { useState, useEffect } from "react";
import Navigation from "./navigation";
import useScrollToTop from "./useScrollToTop";
import { useParams, useNavigate } from "react-router-dom";
import NavInfoSubmissions from "./navInfoSubmissions";
import useWindowWidth from "./useWindowWidth";
import DeleteEvaluationForm from "./deleteEvaluationForm";
import DeleteFormForm from "./deleteForm";
import NavInfoSubmissionsCollapsed from "./navInfoSubmissionsCollapsed";
import LeftInfoSettings from "./leftInfoSettings";
import DeleteZipForm from "./deleteZipForm";
import TypesOfLocationEnum from "./typesOfLocationEnum";

const AssessmentPage = () => {
    useScrollToTop();
    const navigate = useNavigate();
    const width = useWindowWidth(1000);
    const { courseCode, assessmentCode } = useParams();
    const [deleteForm, setDeleteForm] = useState(false);
    const [deleteEvaluation, setDeleteEvaluation] = useState(false);
    const [assessmentInformation, setAssessmentInformation] = useState({});
    const [deleteZip, setDeleteZip] = useState(false);
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);

    useEffect(() => {
        const fetchAssessmentInformation = async () => {
            try {
                setRequestLifeCycle(true);
                const res = await fetch( process.env.REACT_APP_EVALUATION_API_URL + `/get-assessment-settings/${courseCode}/${assessmentCode}`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await res.json();
                console.log(data)
                if (res.status === 200) {
                    setAssessmentInformation(data)
                } else if (res.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                }
        
            } catch (error) {
                console.log(error);
            } finally {
                setRequestLifeCycle(false);
            }
        }

        fetchAssessmentInformation();
    }, [])
        
    const handleDetailsClick = (event) => {
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${assessmentCode}/settings`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${assessmentCode}/settings`);
        }
    }

    const handleStatisticsClick = (event) => {
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${assessmentCode}/settings`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${assessmentCode}/statistics`);
        }
    }

    const handleDashboardClick = (event) => {
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${assessmentCode}/settings`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${assessmentCode}/`);
        }
    }

    function updateDeleteEvaluation(deleteVal) {
        setDeleteEvaluation(deleteVal);
    }

    function updateDeleteForm(deleteForm) {
        setDeleteForm(deleteForm);
    }

    function updateDeleteZip(bool) {
        setDeleteZip(bool);
    }

    function updateAssessmentInformation(assessmentInformation) {
        setAssessmentInformation(assessmentInformation)
    }

    return (
        <>
            <Navigation info={ <NavInfoSubmissions isProgramming={assessmentInformation.isProgramming} updateDeleteEvaluation={updateDeleteEvaluation} handleDashboardClick={handleDashboardClick} handleStatisticsClick={handleStatisticsClick} handleDetailsClick={handleDetailsClick} name={assessmentInformation.name} description={"evaluation settings. You may change evaluation settings up to 30 minutes before the scheduled start time."} title={"Manage Settings"}/> } infoCollapsed={ <NavInfoSubmissionsCollapsed handleDashboardClick={handleDashboardClick} updateDeleteEvaluation={updateDeleteEvaluation} handleStatisticsClick={handleStatisticsClick} handleDetailsClick={handleDetailsClick}/>}/>
            <div className="courses-container">
                <div className={"single-course-container viewer full height"}>
                    <div className="settings-container">                        
                        <LeftInfoSettings assessmentInformation={assessmentInformation} requestLifeCycle={requestLifeCycle} updateAssessmentInformationParent={updateAssessmentInformation} updateDeleteZip={updateDeleteZip} updateAssessmentInformation={updateAssessmentInformation} updateDeleteForm={updateDeleteForm}/>
                    </div>
                </div>
            </div>        
            { deleteEvaluation ? <DeleteEvaluationForm name={assessmentInformation.name} updateDeleteEvaluation={updateDeleteEvaluation} /> : <></> }
            { deleteForm ? <DeleteFormForm updateDeleteForm={updateDeleteForm} uuids={assessmentInformation.formIds} isProgramming={assessmentInformation.isProgramming}/> : <></> }
            { deleteZip ? <DeleteZipForm updateDeleteZip={updateDeleteZip}/> : <></> }
        </>
    );
}

export default AssessmentPage;