import React from "react";

const Keybind = (props) => {
    return (
        <>
            <div className="keybind">
                <span className="keybind-text">
                    { props.text + ": "}
                </span>
                <span className="keybind-text">
                    { props.command }
                </span>
            </div>
        </>
    );
}

export default Keybind;