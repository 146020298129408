import React, { useState, useEffect } from "react";
import GradingBoxQuestion from "./gradingBoxQuestion";
import MarkdownWithLaTeX from "./markDownWithLatex";
import PDFIcon from "./pdfIcon";
import Loading from "./loading";
import { useParams } from "react-router-dom";
import TrashOption from "./trashOption";
import uploadFile from "./uploadFileToS3";

const CompleteOnPaperView = ({ questionsToSave, questionNumber, question, points, grading, pointsEarned, uuid }) => {
    const [pdf, setPdf] = useState("");
    const [pdfToUpload, setPdfToUpload] = useState("");
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const { courseCode, assessmentCode, email } = useParams();
    const [form, setForm] = useState({
        points: points,
        question: question
    })

    // fetch pdf on component load
    useEffect(() => {
        const fetchPdf = async () => {
            try {
                setRequestLifeCycle(true);
                const res = await fetch( process.env.REACT_APP_SUBMISSION_API_URL + `/get-question-pdf/${courseCode}/${assessmentCode}/${email}/${uuid}`, {
                    method: "GET",
                    credentials: "include"
                });
                if (res.status === 200) {
                    const blob = await res.blob();
                    const fileReader = new FileReader();
                    fileReader.onload = (e) => {
                        const pdfDataUrl = e.target.result;
                        setPdf(pdfDataUrl); // This will store the PDF data as base64
                    };
                    fileReader.readAsDataURL(blob);
                } else if (res.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                } 

            } catch (error) {
                console.log(error);
            } finally {
                setRequestLifeCycle(false);
            }
        };

        fetchPdf();
    }, [pdf])

    useEffect(() => {
        const updateQuestionPdf = async () => {
            try {
                setRequestLifeCycle(true);

                // create form data
                const formData = new FormData();
                formData.append("courseCode", courseCode)
                formData.append("assessmentCode", assessmentCode)
                formData.append("email", email)
                formData.append("uuid", uuid)

                const url = process.env.REACT_APP_SUBMISSION_API_URL + "/update-question-pdf"

                const urlOptions = {
                    method: "GET",
                    credentials: "include",
                    body: formData
                }

                const response = await fetch(url, urlOptions);
                const data = await response.json();
                if (response.status === 200 && data.url) {
                    const status = await uploadFile(pdfToUpload, data.url); // Get the ZIP as a blob
                    
                    if (status !== 200) {
                        return;
                    }

                    setPdf("New")
                } else if (response.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                }
                console.log(data)
            } catch (error) {
                console.log(error)
            } finally {
                setRequestLifeCycle(false);
            }
        }

        if (pdfToUpload) {
            updateQuestionPdf();
        }

    }, [pdfToUpload])

    const deleteUploadPdf = async () => {
        try {
            setRequestLifeCycle(true);
            const url = process.env.REACT_APP_SUBMISSION_API_URL + "/delete-question-pdf"
            const urlOptions = {
                method: "POST",
                credentials: "include",
                headers: {
                    'Content-Type': "application/json"
                },
                body: JSON.stringify({
                    courseCode: courseCode,
                    assessmentCode: assessmentCode,
                    email: email,
                    uuid: uuid,
                })
            }

            const response = await fetch(url, urlOptions);
            const data = await response.json();
            if (response.status === 200) {
                setPdf("")
            } else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            }
            console.log(data)
        } catch (error) {
            console.log(error)
        } finally {
            setRequestLifeCycle(false);
        }
    }

    function updateForm(key, value) {
        setForm((prev) => (
            {
                ...prev,
                [key]: value
            }
        ))
    }

    useEffect(() => {
        if (uuid in questionsToSave) {
            const savedFields = questionsToSave[uuid].fields;
            
            if (savedFields) {
                // Update the local state for points and question
                if (savedFields.points && savedFields.points !== points) {
                    updateForm("points", savedFields.points)
                }
                if (savedFields.question && savedFields.question !== question) {
                    updateForm("question", savedFields.question)
                }
            }
        }
    }, [questionsToSave, points, question]);

    return (
        <>
            <div className="true-false-question-view">
                <div className="question-number">
                    <>
                        Q{ questionNumber }
                    </>
                    <div className="pts">
                        {
                            grading

                            ?

                            <>
                                <GradingBoxQuestion pointsEarned={pointsEarned} uuid={uuid}/>
                            </>

                            :

                            <>
                            </>
                        }
                        { (grading ? "/ " : "") + Number(form.points).toFixed(2) } pts
                    </div>
                </div>
                <div className="true-false-top">
                    <MarkdownWithLaTeX content={form.question} />
                </div>
                {/* render the pdf that is uploaded and have an upload region */}
                {
                    grading 

                    ?

                    <>
                        {
                            requestLifeCycle

                            ?

                            <div className="grading-pdf-question">
                                <Loading />
                            </div>

                            :

                            <>  
                                {/* file upload and delete trash can */}
                                <>
                                    <div className="upload-delete-pdf-question-container">
                                        <input type="file" accept="application/pdf" onChange={(e) => setPdfToUpload(e.target.files[0])}/>
                                        {
                                            pdf !== ""

                                            ?
                                        
                                                <div onClick={deleteUploadPdf}>
                                                    <TrashOption />
                                                </div>

                                            :

                                            <>
                                            </>

                                        }
                                    </div>
                                </>
                                {
                                    pdf === "" 
                
                                    ?
                
                                    <div className="grading-pdf-question">
                                        <PDFIcon />
                                    </div>
                
                                    :

                                    <div className="grading-pdf-question">
                                        <iframe
                                            src={pdf}
                                            style={{ width: "100%", height: "100%", margin: "5px" }}
                                        />
                                    </div>
                                }
                            </>
                        }                    
                    </>

                    :

                    <>
                    </>
                }
            </div>
        </>
    );
}

export default CompleteOnPaperView;