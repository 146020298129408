import React, { useState } from "react";
import Plus from "./plus";
import AddSubmissionForm from "./addSubmissionForm";
import AddSubmissionProgrammingForm from "./addSubmissionProgrammingForm";

const AddSubmission = (props) => {
    const [submission, setSubmission] = useState(false);

    const updateSubmission = (bool) => {
        setSubmission(bool)
    }

    return (
        <>
            <div className={`add-course basic-container`} onClick={ () => {updateSubmission(true)} }>
                <Plus bool={false}/>
                { "Submit Upload" }
            </div>
            {/* { submission ? props.isProgramming ? <AddSubmissionProgrammingForm formIds={props.formIds} updateSubmission={updateSubmission}/> : <AddSubmissionForm formIds={props.formIds} updateSubmission={updateSubmission}/> : <></> } */}
            {
                submission 

                ?

                <AddSubmissionProgrammingForm formIds={props.formIds} updateSubmission={updateSubmission}/>

                :

                <>
                </>
            }
        </>
    );
}

export default AddSubmission;