import React, { useState, useEffect } from "react";
import Keybind from "./keybind";
import { useLocation } from "react-router-dom";
import TypesOfLocationEnum from "./typesOfLocationEnum";

const KeybindHints = (props) => {
    const location = useLocation();
    const [info, setInfo] = useState([
        {
            text: "Previous Ungraded",
            command: "a"
        },
        {
            text: "Previous",
            command: "s"
        },
        {
            text: "Next",
            command: "d"
        },
        {
            text: "Next Ungraded",
            command: "f"
        }
    ]);

    useEffect(() => {
        if (props.currentViewer === TypesOfLocationEnum.SETTINGS && !location.pathname.endsWith("/settings")) {            
            setInfo((prev) => (
                [...prev,
                { text: "Embed Block LaTeX", command: "$$ ... $$" },
                { text: "Embed Inline LaTeX", command: "$ ... $" }
                ]
            ))
        } else {
            setInfo([
                {
                    text: "Previous Ungraded",
                    command: "a"
                },
                {
                    text: "Previous",
                    command: "s"
                },
                {
                    text: "Next",
                    command: "d"
                },
                {
                    text: "Next Ungraded",
                    command: "f"
                }
            ])
        }

    }, [props.currentViewer])

    const infoExam = [
        {
            text: "All questions render markdown",
            command: ""
        },
        {
            text: "Embed Block LaTeX",
            command: "$$ ... $$"
        },
        {
            text: "Embed Inline LaTeX",
            command: "$ ... $"
        }        
    ]

    return (
        <>
            <div className="keybind-hints">
                {
                    (location.pathname.endsWith("/settings") ? infoExam : info ).map((item) => {
                        return <Keybind key={item.command} text={item.text} command={item.command}/>
                    })
                }
            </div>
            <div className="arrow">

            </div>
        </>
    );
}

export default KeybindHints;