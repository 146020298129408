import moment from "moment-timezone";

function getCurrentSeason() {
    const currentMonth = moment().month();
    const currentYear = moment().year();
    let season;

    if (currentMonth >= 0 && currentMonth <= 4) { // January to May
        season = 'Spring';
    } else if (currentMonth >= 5 && currentMonth <= 7) { // June to August
        season = 'Summer';
    } else if (currentMonth >= 8 && currentMonth <= 10) { // September to November
        season = 'Fall';
    } else {
        season = 'Winter';
    }

    return `${season} ${currentYear}`;
}

export default getCurrentSeason;
