import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import InitSortQuestions from "./initSortQuestions";
import TypesOfQuestionsEnum from "./typesOfQuestionsEnum";
import TypesOfLocationEnum from "./typesOfLocationEnum";
import WhiteLoading from "./whiteLoading";
import ASingleTest from "./aSingleTest";
import parseFileStructure from "./parseFileStructureToReturnPaths";
import parseZipFile from "./parseZipFile";
import TypesTestsEnum from "./typesTestsEnum";
import UploadAndViewTests from "./uploadAndViewTests";
import fetchWithPreSigned from "./fetchWithPreSigned";

const TestsViewer = ({ updateCurrentViewer }) => {
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const [questions, setQuestions] = useState([])
    const [testsConfigDict, setTestsConfigDict] = useState({});
    const [currentTestViewer, setCurrentTestViewer] = useState(TypesTestsEnum.VIEW);
    const { courseCode, assessmentCode } = useParams();

    const fetchCustomExam = async () => {
        try {
            setRequestLifeCycle(true);
            const response = await fetch( process.env.REACT_APP_EVALUATION_API_URL + `/get-custom-exam/${courseCode}/${assessmentCode}`, {
                method: "GET",
                credentials: "include"
            });
            const data = await response.json();
            if (response.status === 200) {
                const questionsInOrder = InitSortQuestions(data.questionsOrder, data.questions);
                console.log(questionsInOrder)
                setQuestions(questionsInOrder.filter((question, index) => {
                    question.index = index;
                    return (question.questionType === TypesOfQuestionsEnum.PROGRAMMING) && question.tests.length > 0
                }))

            } else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            }
        } catch (error) {
            console.log(error);
        } finally {
            setRequestLifeCycle(false);
        }
    }

    useEffect(() => {
        fetchCustomExam();
    }, [])

    const fetchZip = async () => {
        try {
            const res = await fetch( process.env.REACT_APP_EVALUATION_API_URL + `/get-assessment-zip/${courseCode}/${assessmentCode}`, {
                method: "GET",
                credentials: "include"
            });
            const data = await res.json();
            if (res.status === 200) {
                const [status, blob] = await fetchWithPreSigned(data.url); // Get the ZIP as a blob
                    
                if (status !== 200) {
                    return;
                }

                const contents = parseFileStructure(await parseZipFile(blob));
                const testsConfigArray = "tests_config.json" in contents ? JSON.parse(contents["tests_config.json"].content) : []
                setTestsConfigDict(testsConfigArray.tests.reduce((acc, curr) => {
                    acc[curr.id] = curr;
                    return acc;
                  }, {}));
            } else if (res.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            }
        } catch (error) {
            console.log(error);
        }
    }; 

    useEffect(() => {
        fetchZip();
    }, [])

    console.log(questions)

    return (
        <>
            <div className="tests-container-parent">
                <>
                    {
                        currentTestViewer === TypesTestsEnum.VIEW ?

                        <>
                            {
                                requestLifeCycle

                                ?

                                <div className="loading-zip-container">
                                    <WhiteLoading />
                                </div>

                                :

                                <>
                                    {
                                        questions && questions.length > 0

                                        ?

                                        <>
                                            <div className="question-tests-container">
                                                {
                                                    questions.map((question) => (
                                                        question.tests.map((id) => {
                                                            if (id in testsConfigDict) {
                                                                return <ASingleTest key={id} {...testsConfigDict[id]} index={question.index + 1}/>
                                                            }
                                                        })
                                                    ))
                                                }
                                            </div>
                                        </>

                                        :

                                        <>
                                            <div className="no-questions">
                                                <div className="no-assignments-container" style={{ paddingTop: "0px"}}>
                                                    <div className="no-assignments"> 
                                                        There are currently no autograde tests for any questions
                                                        <div className="save-changes-2" onClick={() => {
                                                            updateCurrentViewer(TypesOfLocationEnum.CUSTOM_EXAM);
                                                        }}>
                                                            Back To Questions
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>

                                    }
                                </>
                            }
                        </>

                        :

                        <>
                            <UploadAndViewTests initQuestions={questions} updateCurrentViewer={updateCurrentViewer}/>
                        </>
                    }
                </>
                <div className="start-a-new-test">
                    {
                        currentTestViewer === TypesTestsEnum.VIEW ?

                        <>
                            <div className="top-right-item" style={{ width: "160px" }} onClick={() => setCurrentTestViewer(TypesTestsEnum.TEST)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M5.5 9.438V8.5h1v.938a1 1 0 0 0 .03.243l.4 1.598-.93.62-.93-.62.4-1.598a1 1 0 0 0 .03-.243"/>
                                    <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1m-4-.5V2h-1V1H6v1h1v1H6v1h1v1H6v1h1v1H5.5V6h-1V5h1V4h-1V3zm0 4.5h1a1 1 0 0 1 1 1v.938l.4 1.599a1 1 0 0 1-.416 1.074l-.93.62a1 1 0 0 1-1.109 0l-.93-.62a1 1 0 0 1-.415-1.074l.4-1.599V8.5a1 1 0 0 1 1-1"/>
                                </svg> 
                                Test Zip File
                            </div>
                        </>

                        :

                        <>                        
                            <div className="top-right-item" style={{ width: "160px" }} onClick={() => setCurrentTestViewer(TypesTestsEnum.VIEW)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>
                                </svg>
                                View Base Tests
                            </div>
                        </>

                    }
                </div>
            </div>            
        </>
    );
}

export default TestsViewer;