import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import HandleStatus from "./handleStatus";
import convertDate from "./convertDate";
import GradedPercent from "./gradedPercent";

const Assessment = (props) => {
    const navigate = useNavigate();
    const { courseCode } = useParams();

    const findNumberSubmissions = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_EVALUATION_API_URL + `/get-submission-count/${courseCode}/${props.assessmentCode}`, {
                method: "GET",
                credentials: "include"
            })
            const data = await response.json();
            if (response.status === 200) {
                return data.count
            } else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            } else {
                return 0;
            }

        } catch (error) {
            console.log(error);
            return 0;
        }
    }

    const handleNameClick = async (event) => {
        const count = await findNumberSubmissions();

        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${props.assessmentCode}/${count === 1 ? "" : "edit"}`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${props.assessmentCode}/${count === 1 ? "" : "edit"}`);
        }
    }

    const handleSubmitted = () => {
        if (props.status.includes("Submitted")) {
            return props.submissionsLength
        } else {
            return props.status
        }
    }

    return (
        <>
            <div className="exam-container">
                <div className="course-metadata-2"> 
                    <div className={ props.widthTwo ? "arrow-container-1 color fifty" : "arrow-container-1 color" }>
                        <div className="assessment-name-container">
                            { props.widthTwo ? HandleStatus(props.status) : ""} 
                            <div className="assessname" onClick={handleNameClick}> { props.name } </div>
                        </div>
                    </div>
                    {
                        props.widthTwo

                        ?
                            <></>
                        :

                            <div className={ props.width ? "arrow-container-2-2 color" : "arrow-container-2 color" }>
                                { HandleStatus(props.status) } { handleSubmitted() }
                            </div>

                    }
                    
                    {
                        props.width

                        ?
                            <></>
                        :

                            <div className="arrow-container-3 color">
                                <GradedPercent submissionsLength={props.submissionsLength} gradedLength={props.gradedLength}/>
                            </div> 
                    }
                    <div className={ props.width ? props.widthTwo ? "arrow-container-4-2 color special fifty" : "arrow-container-4-2 color special" : "arrow-container-4 color special" }>
                        <div>
                            { convertDate(props.start, true) }
                        </div> 
                        <div>
                            { convertDate(props.end, true) }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Assessment;