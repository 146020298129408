import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GradedPercent from "./gradedPercent";

const GradedPercentWrapper = () => {
    const [countsInformation, setCountsInformation] = useState({});
    const [countsFetched, setCountsFetched] = useState(false);
    const { courseCode, assessmentCode } = useParams(); // need for api call later

    useEffect(() => {
        const fetchSubmissionsAndGradedCount = async () => {
            try {
                const res = await fetch( process.env.REACT_APP_SUBMISSION_API_URL + `/get-submissions-and-graded-count/${courseCode}/${assessmentCode}`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await res.json();
                console.log(data)
                if (res.status === 200) {
                    setCountsInformation(data)
                    setCountsFetched(true);
                } else if (res.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                }
        
            } catch (error) {
                console.log(error);
            }
        }

        fetchSubmissionsAndGradedCount();
        
    }, []);

    return (
        <>
            {
                countsFetched

                ?

                <>
                    <div className="side-nav-info-header">
                        Autograded
                    </div>
                    <div className="text-side-nav display-2" style={{ width: "100%" }}>
                        <GradedPercent submissionsLength={countsInformation.submissionsLength} gradedLength={countsInformation.gradedLength} moreInfo={true}/>
                    </div>
                </>

                :

                <>
                </>
            }
        </> 
    );
}

export default GradedPercentWrapper;