import TypesOfQuestionsEnum from "./typesOfQuestionsEnum";

const InitSortQuestions = (questionsOrder, questions) => {
    const questionsInOrder = questionsOrder.map((uuid) => {
        if (questions[uuid].questionType === TypesOfQuestionsEnum.BANK_OF_QUESTIONS) {
            questions[uuid].questions = questions[uuid].questions.map((innerUuid) => {
                // Check if the inner question exists in the outer question order and delete it
                const indexInOrder = questionsOrder.indexOf(innerUuid);
                if (indexInOrder !== -1) {
                    questionsOrder.splice(indexInOrder, 1); // Remove nested question from the order
                }
                return questions[innerUuid];
            });
        }
        return questions[uuid];
    });

    return questionsInOrder;
}

export default InitSortQuestions;