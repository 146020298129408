import React, { useState } from "react";
import CourseInput from "./courseInput";
import SaveChanges from "./saveChanges";
import WhiteLoading from "./whiteLoading";
import AddCourseDescription from "./addCourseDescription";
import YearAndTerm from "./yearAndTerm";
import { useParams, useNavigate } from "react-router-dom";

const EditCourseForm = (props) => {
    const [text, setText] = useState("Enter new course metadata below");
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const navigate = useNavigate();
    const { courseCode } = useParams();
    const [form, setForm] = useState({
        courseCode: courseCode,
        name: props.name,
        description: props.description,
        year: props.year,
        term: props.term
    })
    
    const resetForm = () => {
        props.updateEditCourse(false);
    }

    const submitForm = async (e) => {
        e.preventDefault()

        if (form.name.length > 150) {
            setText("Course name must be 149 characters or fewer")
            return;
        }

        if (form.description.length > 200) {
            setText("Description must be 199 characters or fewer")
            return;
        }
        
        const url = process.env.REACT_APP_COURSE_API_URL + "/update-course"

        const urlOptions = {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(form)
        }

        try {
            setRequestLifeCycle(true)
            const res = await fetch(url, urlOptions);
            const data = await res.json();
            if (res.status === 200) {
                setText("Course updated")
                props.updateEditCourse(false);
                navigate("/")
            } else if (res.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL
            } else if (res.status === 403) {
                setText("There is more than one instructor")
            } else if (res.status === 409) {
                setText("Student roster is not empty")
            } else if (res.status === 404) {
                setText("At least one resource was not found")
            } else {
                setText("An error occurred")
            }

            console.log(data);
    
        } catch (error) {
            setText("An error occurred")
            console.log(error);
        } finally {
            setRequestLifeCycle(false)
        }
    }

    function updateName(name) {
        setForm(prevForm => ({
            ...prevForm,
            name: name,
        }))
        console.log(form);
    }

    function updateDescription(description) {
        setForm(prevForm => ({
            ...prevForm,
            description: description,
        }))
        console.log(form);
    }

    function updateYear(year) {
        setForm(prevForm => ({
            ...prevForm,
            year: year,
        }))
        console.log(form);
    }

    function updateTerm(term) {
        setForm(prevForm => ({
            ...prevForm,
            term: term,
        }))
        console.log(form);
    }

    console.log(form)

    return (
        <>
            <div className="black-back" onClick={ () => {props.updateEditCourse(false)} }/>
            <div className="add-a-course-form-container">
                <div className="add-a-course-form-content-container">
                    <form className="form-width">
                        <div className="add-course-title">
                            Update Course Metadata
                        </div>
                        <div className="account-settings-content">
                            <div className="required-container">
                                { props.name }
                            </div>   
                            <div className="banner">                                
                                {
                                    requestLifeCycle 

                                    ?

                                    <>
                                        <WhiteLoading />
                                    </>

                                    :

                                    <>
                                        { text }
                                    </>

                                }
                            </div>
                            <CourseInput top={"Course Name"} value={form.name} updateValue={updateName} min={""} type={"text"} required={true} autoComplete={"off"} placeholder={"Ex. Math 233: Calculus of Functions of Several Variables"}/>
                            <AddCourseDescription value={form.description} updateValue={updateDescription} top={"Course Description"} required={true}/>    
                            <YearAndTerm year={form.year} term={form.term} updateYear={updateYear} updateTerm={updateTerm} required={false}/>
                            <SaveChanges left="Cancel" right={{ small : "Update Course", large : "Update Course" }} resetForm={resetForm} submitForm={submitForm}/> 
                        </div>
                    </form> 
                </div>
            </div>
        </>
    );
}

export default EditCourseForm;