import React from "react";
import StudentRow from "./studentRow";

const NamesDropdown = (props) => {
    const filteredStudents = props.students.filter(student =>
        student.name.toLowerCase().includes(props.searchName.toLowerCase()) && props.searchName.length !== 0
    );

    return (
        <>
            {props.searchBool && filteredStudents.length > 0 && (
                <div className="names-dropdown">
                    {filteredStudents.map((student, index) => (
                        <StudentRow
                            index={index}
                            updateSearchName={props.updateSearchName}
                            key={student.email}
                            student={student}
                            assessmentCode={props.assessmentCode}
                            updateName={props.updateName}
                            updateEmail={props.updateEmail}
                            updateExtraTime={props.updateExtraTime}
                            updateSearchBool={props.updateSearchBool}
                            updateMetric={props.updateMetric}
                        />
                    ))}
                </div>
            )}
        </>
    );
};

export default NamesDropdown;