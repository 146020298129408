import React from "react";
import Circle from "./circle";

const CircleWithTextView = (props) => {
    return (
        <>
            <div className="square-with-text-view">
                <Circle correct={props.correct} updateIndex={props.updateIndex} index={props.index}/>
                { props.text }
            </div>
        </>
    );
}

export default CircleWithTextView;