import React from "react";
import handleState from "./handleState";

const SquareWithTextViewGradingPage = ({ text, state }) => {
    return (
        <>
            <div className="square-with-text-view">
                <div className={`square ${handleState(state)}`} style={{ cursor: "auto" }}/>
                { text }
            </div>
        </>
    );
}

export default SquareWithTextViewGradingPage;