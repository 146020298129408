import React from "react";
import Burger from "./burger";
import X from "./x";
import { useParams , useNavigate} from "react-router-dom";
import BackArrow from "./backArrow";

const NavIcon = (props) => {
    const navigate = useNavigate();
    const { courseCode, assessmentCode } = useParams();

    const handleClick = (bool) => {
        props.updateOpen(bool);
    }

    const handleBack = () => {
        navigate(`/${courseCode}`)
    }

    const showBack = courseCode && assessmentCode

    return (
        <>
            <div className="nav-icon-with-back">
                {
                    props.open 
                    
                    ? 

                        <>
                            <div onClick={() => {handleClick(false)}} className="nav-icon">
                                <X />
                            </div>                        
                        </>

                    : 

                        <>
                            <div onClick={() => {handleClick(true)}} className="nav-icon">
                                <Burger />
                            </div>
                            {
                                showBack && (
                                    <div onClick={handleBack}>
                                        <BackArrow />
                                    </div>
                                )
                            }
                        </>
                }
            </div>
            {
                showBack && props.info && props.open && (
                    <div onClick={handleBack} className="back-absolute">
                        <BackArrow open={props.open}/>
                        Back to Eval List
                    </div>
                )
            }
        </>
    );
}

export default NavIcon;