import React from "react";

const ColorPicker = (props) => {
    const handleColorChange = (event) => {
        props.updateColor(event.target.value); 
    };

    return (
        <>
            <div>
                <form>
                    <input
                        type="color"
                        className="custom-color"
                        style={{ marginRight: props.collapsed ? "-10.70px" : "", cursor: "pointer" }}
                        value={props.color}
                        onChange={ handleColorChange }
                    />
                </form>
            </div>
        </>
    );
}

export default ColorPicker;