import React, { useEffect } from 'react';
import Plotly from 'plotly.js-dist';

const BoxPlotCustom = (props) => {
    useEffect(() => {
        // Calculate averages
        const calculateAverage = (data) => {
            if (data.length > 0) {
                const sum = data.reduce((a, b) => a + b, 0);
                return (sum / data.length).toFixed(2); // 2 decimal places
            } else {
                return 0;
            }
        };

        const averageGrades = calculateAverage(props.gradesData);
        const averageTime = calculateAverage(props.timeData);

        const trace1 = {
            x: props.gradesData,
            type: 'box',
            name: 'Grades',
            marker: { color: 'rgba(01, 105, 244, 255)' },
        };

        const trace2 = {
            x: props.timeData,
            type: 'box',
            name: 'Time',
            marker: { color: 'rgba(106, 90, 205, 1)' },
        };

        const data = [trace1, trace2];

        const layout = {
            title: 'Completion Time (min) | Evaluation Grades (points)',
            margin: {
                t: 60,
                b: 60,
                l: 60,
                r: 60
            },
            legend: {
                x: 1,
                y: 1
            },
            annotations: [
                {
                    x: 0.5,
                    y: 1.0,
                    xref: 'paper',
                    yref: 'paper',
                    text: `Average Time: ${averageTime}`,
                    showarrow: false,
                    font: {
                        size: 14,
                        color: 'rgba(106, 90, 205, 1)'
                    }
                },
                {
                    x: 0.5,
                    y: 1.05,
                    xref: 'paper',
                    yref: 'paper',
                    text: `Average Score: ${averageGrades}`,
                    showarrow: false,
                    font: {
                        size: 14,
                        color: 'rgba(01, 105, 244, 255)'
                    }
                }
            ]
        };

        const config = {
            displayModeBar: false // Hide Plotly logo and mode bar
        };

        Plotly.newPlot('myDiv', data, layout, config);
    }, [props.gradesData, props.timeData]);

    return (
        <div id="myDiv" style={{ width: '100%', height: '600px' }}></div>
    );
};

export default BoxPlotCustom;
