import React from "react";

const NavInfoAutograder = () => {
    return (
        <>
            <div className="semester-nav-info">
                <div className="side-nav-info-header">
                    Autograder Docs
                </div>
                <div className="text-side-nav">
                    See the GoValuate Autograder documentation and an example to the right.
                </div>
            </div>
        </>
    );
}

export default NavInfoAutograder;