import React, { useState } from "react";
import Plus from "./plus";
import AddInstructorForm from "./addInstructorForm";

const AddInstructor = () => {
    const [adding, setAdding] = useState(false);

    const updateCreating = (bool) => {
        setAdding(bool)
    }

    return (
        <>
            <div className={`add-course basic-container`} onClick={ () => {updateCreating(true)} }>
                <Plus bool={false}/>
                { "Add Instructor" }
            </div>
            { adding ? <AddInstructorForm updateCreating={updateCreating}/> : <></> }
        </>
    );
}

export default AddInstructor;