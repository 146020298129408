import React from "react";

const CourseSelect = (props) => {
    const handleChange = (e) => {
        props.updateValue(e.target.value)
    }

    return (
        <>
            <div className="input-field">
                <div className="top-input">
                    { props.top }
                </div>
                <select className="bottom-input metric-select" required={ props.required } value={ props.value } onChange={ handleChange }>
                    {
                        props.assessments.map((assessment) => {
                            return <option key={assessment.assessmentCode} value={assessment.assessmentCode}>
                                        { assessment.name }
                                    </option>
                                    
                        })
                    }
                </select>
            </div>
        </>
    );
}

export default CourseSelect;