import React from "react";
import ColorPicker from "./colorPicker";
import PaintBrush from "./paintBrush";
import { isDesktop } from "react-device-detect";

const NavInfoSemester = ({ updateColor, color }) => {
    return (
        <>
            <div className="semester-nav-info">
                <div className="side-nav-info-header">
                    Your Courses
                </div>
                <div className="text-side-nav">
                    Welcome to GoValuate! Click any course to continue to view your assessments, or add a course by entering a course code.
                </div>
                <div className="side-nav-info-header">
                    Paint A Course
                </div>
                {
                    isDesktop

                    ?

                        <>
                            <div className="side-nav-color-container">
                                <div className="text-side-nav container">
                                    Select <ColorPicker color={color} updateColor={updateColor}/>
                                </div>
                                <div className="text-side-nav container">
                                    Drag And Drop <PaintBrush color={color} />
                                </div>
                            </div>
                        </>

                    :

                        <>
                            <div className="text-side-nav">
                                Customizing course colors is currently available on desktop only.
                            </div>
                        </>

                }
                
            </div>
        </>
    );
}

export default NavInfoSemester;
