import React, { useState } from "react";
import Plus from "./plus";
import AddACourseForm from "./addACourseForm";

const AddCourse = (props) => {
    const [adding, setAdding] = useState(false);

    const updateAdding = (bool) => {
        setAdding(bool)
    }

    return (
        <>
            <div className={`add-course basic-container`} onClick={ () => {updateAdding(true)} }>
                <Plus bool={false}/>
                { "Create Course" }
            </div>
            { adding ? <AddACourseForm numCourses={props.numCourses} updateAdding={updateAdding}/> : <></> }
        </>
    );
}

export default AddCourse;