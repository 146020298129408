import React from "react";

const EyeClosed = (props) => {
    const handleClick = () => {
        props.updateSeePassword(false);
    }

    return (
        <>
            <div className="eye" onClick={handleClick}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="var(--accent-one)" viewBox="0 0 16 16">        
                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0"/>
                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7"/>
                </svg>
            </div>
        </>
    );
}

export default EyeClosed;