import React from "react";

const GradedPercent = (props) => {
    // Calculate the percentage
    const percentage = props.submissionsLength !== 0 
        ? 100 * (props.gradedLength / props.submissionsLength) 
        : 0;

    // Clip the percentage to stay between 0 and 100
    const clippedPercentage = Math.min(100, Math.max(0, percentage));

    // Format the percentage: show as whole number if it's an integer, otherwise show with two decimal places
    const formattedPercentage = Number.isInteger(clippedPercentage)
        ? clippedPercentage.toString()
        : clippedPercentage.toFixed(2);

    return (
        <>
            <div className="graded-percent-container">
                <div className="graded-percent" style={{ backgroundColor: props.moreInfo ? "white" : "", border: props.moreInfo ? "1px solid var(--gray-four)" : "", maxWidth: props.moreInfo ? "none" : "300px" }}>
                    <div className="graded-percent-bar" style={{ width: `${formattedPercentage}%` }}>
                    </div>
                </div>
                <span className="graded-percent-box">
                    { props.moreInfo ? props.gradedLength + '/' + props.submissionsLength : `${formattedPercentage}%` }
                </span>
            </div>
        </>
    );
}

export default GradedPercent;
