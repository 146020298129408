import React, { useState, useEffect } from "react";
import Navigation from "./navigation";
import { useParams } from "react-router-dom"; // need for api call later
import DownArrow from "./downArrow";
import Assessment from "./assessment";
import Materials from "./materials";
import useScrollToTop from "./useScrollToTop";
import NavInfoCourse from "./navInfoCourse";
import useWindowWidth from "./useWindowWidth";
import EditCourseForm from "./editCourseForm";
import RemoveInstructorForm from "./removeInstructorForm";
import Loading from "./loading";
import handleStatusDate from "./handleStatusDate";
import moment from "moment-timezone";
import DuplicateForm from "./duplicateForm";
import DeleteCourseForm from "./deleteCourseForm";
import Search from "./search";
import NavInfoCourseCollapsed from "./navInfoCourseCollapsed";
import Copy from "./copy";

const CourseDashboard = () => {
    useScrollToTop();
    const [creating, setCreating] = useState(false);
    const [deleteCourse, setDeleteCourse] = useState(false);
    const [duplicate, setDuplicate] = useState(false);
    const [remove, setRemove] = useState(false);
    const [editCourse, setEditCourse] = useState(false);
    const [instructor, setInstructor] = useState({
        name : "",
        email : "",
    });
    const { courseCode } = useParams(); // need for api call later
    const width = useWindowWidth(850);
    const widthTwo = useWindowWidth(525);
    const widthThree = useWindowWidth(340);
    const widthFour = useWindowWidth(550);
    const [arr, setArr] = useState([]);
    const [course, setCourse] = useState({});
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const [sortUngradedFirst, setSortUngradedFirst] = useState(false);
    const [searchName, setSearchName] = useState("");
    const [alphabetical, setAlphabetical] = useState(true)

    useEffect(() => {
        const fetchCourse = async () => {
            try {
                setRequestLifeCycle(true);
                const res = await fetch( process.env.REACT_APP_COURSE_API_URL + `/get-course/${courseCode}`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await res.json();
                console.log(data)
                if (res.status === 200) {
                    setCourse(data)
                    data.assessments.map((assessment) => {
                        const status = handleStatusDate(assessment.start, assessment.end, assessment.submissionsLength)
                        assessment.status = status
                        setArr(prevArr => (
                            prevArr.includes(status) ? prevArr : [...prevArr, status]
                        ))
                    })
                    console.log(data.assessments)
                    if (data.assessments && data.assessments?.length > 1) {
                        setAlphabetical(data.assessments[0].name.localeCompare(data.assessments[1].name) > 0)
                    }

                    setAssessmentsArr(data.assessments.sort((a,b) => {
                        if ((a.submissionsLength !== 0 ? a.gradedLength / a.submissionsLength : 0) > (data.assessments[0].submissionsLength !== 0 ? data.assessments[0].gradedLength / data.assessments[0].submissionsLength : 0)) {
                            setSortUngradedFirst(true)
                        }
                        const now = moment();
                        const startA = moment.utc(a.start)
                        const endA = moment.utc(a.end)
                        const startB = moment.utc(b.start)
                        const endB = moment.utc(b.end)
                        if (endA.isAfter(now) && endB.isAfter(now)) { 
                            // if both are in progress
                            if (startA.isBefore(now) && startB.isBefore(now)) {
                                return 0;
                            }
                            // if a is in progress and b is not in progress 
                            else if (startA.isBefore(now)) {
                                return -1;
                            }
                            // if b is in progress and a is not in progress
                            else if (startB.isBefore(now)) {
                                return 1;
                            } 
                            // if all are upcoming, sort by end time
                            return endA.isBefore(endB) ? -1 : 1 ;
                        } else if (endA.isAfter(now)) {
                            // Only endA is after now, so it should come first
                            return -1;
                        } else if (endB.isAfter(now)) {
                            // Only endB is after now, so it should come first
                            return 1;
                        } else {
                            // Both are before now, so sort in reverse chronological order (later dates first)
                            return endA.isAfter(endB) ? -1 : 1;
                        }
                    }));
                    setInstructorsArr(data.instructors);
                } else if (res.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                }
        
            } catch (error) {
                console.log(error);
            } finally {
                setRequestLifeCycle(false);
            }
        }

        fetchCourse();
    }, [])

    const [assessmentsArr, setAssessmentsArr] = useState([]);
    const [instructorsArr, setInstructorsArr] = useState([]);

    const changeStatusSort = () => {
        const newArr = [...arr];
        const lastElement = newArr.pop();
        newArr.unshift(lastElement);
        setArr(newArr);

        setAssessmentsArr(assessmentsArr.sort((a, b) => {
            return newArr.indexOf(b.status) - newArr.indexOf(a.status);
        }))
    }

    const changeNameSort = () => {
        setAssessmentsArr(assessmentsArr.sort((a, b) => {
            if (alphabetical) {
                return a.name.localeCompare(b.name);  // Correctly compare strings
            } else {
                return b.name.localeCompare(a.name);  // Correctly compare strings in reverse order
            }
        }));
    
        setAlphabetical((prev) => !prev);
    };    

    function updateCreating(bool) {
        setCreating(bool);
    }

    function updateDeleteCourse(bool) {
        setDeleteCourse(bool);
    }

    function updateRemove(bool) {
        setRemove(bool);
    }

    function updateInstructor(instructor, email) {
        setInstructor({
            name: instructor,
            email: email
        });
    }

    function updateDuplicate(bool) {
        setDuplicate(bool);
    }

    function updateEditCourse(bool) {
        setEditCourse(bool)
    }

    function updateSearchName(e) {
        setSearchName(e.target.value);
    }

    const changeGradedStatusSort = () => {
        const sortedAssessments = [...assessmentsArr].sort((a, b) => {
            const aProportion = a.submissionsLength !== 0 ? a.gradedLength / a.submissionsLength : 0;
            const bProportion = b.submissionsLength !== 0 ? b.gradedLength / b.submissionsLength : 0;
            if (sortUngradedFirst) {
                return aProportion - bProportion;
            } else {
                return bProportion - aProportion;
            }
        });
        setAssessmentsArr(sortedAssessments);
        setSortUngradedFirst(!sortUngradedFirst);
        console.log(sortedAssessments);
    }

    return (
        <>
            <Navigation updateCreating={updateCreating} creating={creating} boolThree={false} boolTwo={true} bool={false} info={<NavInfoCourse updateCourse={updateEditCourse} updateDuplicate={updateDuplicate} updateRemove={updateRemove} updateInstructor={updateInstructor} name={course.name} description={course.description} instructors={instructorsArr} updateDeleteCourse={updateDeleteCourse}/>} infoCollapsed={<NavInfoCourseCollapsed />}/>
            <div className="courses-container">
                <div className="header">
                    <div className="single-course-container">
                        <div className="your-course" style={{ position: "relative" }}>
                            <div className="course-metadata" style={{ flexDirection: width ? "column" : "row", alignItems:  width ? "flex-start" : "center" }}> 
                                {
                                    course && Object.keys(course).length > 0

                                    ?

                                    <>
                                        <div className="course-name overflow-name">
                                            { course.name }
                                        </div>
                                        { width ? <></> : <div className="bar"></div> }
                                        <div className="font two three overflow-code flex-code">
                                            <div>
                                                { "Code: " + course.courseCode }
                                            </div>
                                            <Copy text={course.courseCode}/>
                                        </div>
                                        <div className={ widthFour ? "search-holder-bottom" : "search-holder"} style={{width: "180px"}}>
                                            <Search searchName={searchName} updateSearchName={updateSearchName}/>
                                        </div>
                                    </>
                                

                                    :

                                    <>
                                    </>

                                }
                            </div>
                            <div className="course-metadata-2" style={{ paddingBottom: "10px" }}> 
                                <div className={ widthTwo ? "arrow-container-1-2 no-under" : "arrow-container-1 no-under" }>
                                    <div className={ widthTwo ? "arrow-container-1-2 no-under" : "arrow-container-1 no-under" } style={{ width: "fit-content", cursor: "pointer", userSelect: "none" }} onClick={ widthTwo ? changeStatusSort : changeNameSort }>
                                        <DownArrow />
                                        <span> Name </span>
                                        <Materials />
                                    </div>
                                </div>
                                {  
                                
                                widthTwo

                                ?
                                    <></>
                                :

                                    <div className={ width ? "arrow-container-2-2" : "arrow-container-2" }>
                                        <div className="status-container" onClick={ changeStatusSort } style={{ userSelect: "none" }}>
                                            <DownArrow />
                                            Status
                                        </div>
                                    </div> 

                                }
                               { 

                                width

                                ?

                                    <>
                                    </>

                                : 

                                    <div className="arrow-container-3" style={{ userSelect: "none" }}>
                                        <div className="status-container" onClick={ changeGradedStatusSort } style={{ userSelect: "none" }}>
                                            <DownArrow />
                                            Graded %
                                        </div>
                                    </div> 

                                }
                                <div className={ width ? widthTwo ? "arrow-container-4-2 fifty" : "arrow-container-4-2" : "arrow-container-4" } style={{ userSelect: "none" }}>
                                    { widthThree ? "Times" : "Schedule" } ({moment.tz(moment.tz.guess()).format('z')})
                                </div>
                            </div>
                        </div>
                        <div className="exams-container">
                            {
                                requestLifeCycle

                                ?                                                        

                                <>
                                    <div className="loading-container" style={{ height: "200px" }}>
                                        <Loading />
                                    </div>  
                                </>

                                :

                                <>
                                    {
                                        assessmentsArr && assessmentsArr.length > 0

                                        ?

                                        <>
                                            {
                                                assessmentsArr.map((assessment) => {
                                                    // logic for checking start time to either pass "In Progress", "Submitted", "Upcoming"
                                                    // determine the avgCompletionTime by the submissions array
                                                    if (assessment.name.toLowerCase().includes(searchName.toLowerCase())) {
                                                        return <Assessment status={assessment.status} points={assessment.points} key={assessment.assessmentCode} assessmentCode={assessment.assessmentCode} gradedLength={assessment.gradedLength} submissionsLength={assessment.submissionsLength} widthTwo={widthTwo} width={width} name={assessment.name} start={assessment.start} end={assessment.end}/>
                                                    } else {
                                                        return <></>
                                                    }
                                                })
                                            }
                                        </>

                                        :

                                        <>
                                            <div className="no-assignments-container">
                                                <div className="no-assignments"> 
                                                    You currently have no evaluations
                                                    <div className="save-changes-2" onClick={() => { updateCreating(true); }}>
                                                        Create An Evaluation
                                                    </div>
                                                </div>
                                            </div>
                                        </>

                                    }
                                </>

                            }
                        </div>
                    </div>
                </div>
            </div>
            { deleteCourse ? <DeleteCourseForm name={course.name} updateDeleteCourse={updateDeleteCourse}/> : <></>} 
            { remove ? <RemoveInstructorForm email={instructor.email} name={instructor.name} updateRemove={updateRemove}/> : <></> }
            { duplicate ? <DuplicateForm name={course.name} year={course.year} term={course.term} description={course.description} assessmentObjects={course.assessments} updateDuplicate={updateDuplicate}/> : <></> }
            { editCourse ? <EditCourseForm updateEditCourse={updateEditCourse} name={course.name} year={course.year} term={course.term} description={course.description} color={course.color}/> : <></> }
        </>
    );
}

export default CourseDashboard;