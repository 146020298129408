import React from "react";
import TypesOfQuestionsEnum from "./typesOfQuestionsEnum";

const TypeQuestionSelect = ({ updateQuestion, questionType }) => {
    return (
        <>
            <select className="metric-select question" value={questionType} onChange={(e) => {
                console.log(e.target.value)
                updateQuestion("questionType", e.target.value)
            }} style={{ width: "100%" }}>
                {Object.entries(TypesOfQuestionsEnum).map(([key, value]) => (
                    <option key={key} value={value}>
                        {value
                            .replace(/_/g, " ") // Replace underscores with spaces
                            .toLowerCase()      // Convert to lowercase
                            .split(" ")         // Split into words
                            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
                            .join(" ")          // Join back into a string
                        }
                    </option>
                ))}
            </select>
        </>
    );
}

export default TypeQuestionSelect;
