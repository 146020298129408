import StatusCircle from "./statusCircle"

const HandleSubmissionStatus = (graded) => {
    if (graded) {
        return <StatusCircle color={"rgba(1, 164, 33, 255)"}/>
    } else {
        return <StatusCircle color={"rgba(11, 115, 254, 255)"}/>
    }
}

export default HandleSubmissionStatus;