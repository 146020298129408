import JSZip from 'jszip';

// Helper function to generate unique IDs
let uniqueIdCounter = 1;
function generateUniqueId(prefix = 'id') {
    return `${prefix}${uniqueIdCounter++}`;
}

// Function to build the tree structure iteratively
async function buildTree(zip) {
    const root = [];
    const pathMap = { '': root }; // Maps folder paths to their corresponding tree nodes

    const files = Object.values(zip.files).sort((a, b) => a.name.localeCompare(b.name));

    for (const file of files) {
        const parts = file.name.split('/').filter(part => part); // Split path and remove empty parts
        let currentPath = '';
        let parent = root;

        for (let i = 0; i < parts.length; i++) {
            const part = parts[i];
            const isLast = i === parts.length - 1;
            currentPath = currentPath ? `${currentPath}/${part}` : part;

            if (!pathMap[currentPath]) {
                const node = {
                    id: generateUniqueId(),
                    name: part,
                };

                if (isLast && !file.dir) {
                    // It's a file
                    node.text = ''; // Placeholder, will fill later
                } else {
                    // It's a directory
                    node.children = [];
                }

                parent.push(node);
                pathMap[currentPath] = node.children || node; // If directory, map to children array

                parent = node.children || parent; // Update parent for next iteration
            } else {
                parent = pathMap[currentPath];
            }
        }

        if (!file.dir) {
            // It's a file, read its content
            const fileContent = await file.async("string");
            // Find the node in the tree to assign 'rac'
            const nodePath = parts.join('/');
            const node = findNodeByPath(root, parts);
            if (node) {
                node.text = fileContent;
            }
        }
    }

    return root;
}

// Helper function to find a node by path parts
function findNodeByPath(root, parts) {
    let current = root;
    for (const part of parts) {
        const node = current.find(n => n.name === part);
        if (!node) return null;
        if (node.children) {
            current = node.children;
        } else {
            current = current;
        }
    }
    return current[current.length - 1];
}

// Main function to parse the zip file
async function parseZipFile(zipData) {
    const zip = await JSZip.loadAsync(zipData);
    const tree = await buildTree(zip);
    return tree;
}

export default parseZipFile;