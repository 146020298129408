import React, { useState, useEffect } from "react";
import TrashQuestion from "./trashQuestion";
import Grid from "./grid";
import saveFields from "./saveFields";

const TextResponseEdit = ({ questionsToSave, answerString, showDrag, updateQuestionsToSave, provided, updateDeleteQuestion, index, points, question, uuid, questionType, bankUuid }) => {
    const [form, setForm] = useState({
        question: question,
        points: Number(points).toFixed(2),
        uuid: uuid,
        questionType: questionType,
        answerString: answerString
    });

    function updateForm(key, value) {
        setForm((prev) => (
            {
                ...prev,
                [key]: value
            }
        ))
    }

    const submitForm = async () => {
        // construct a new body for post with all values that have changed
        const body = {};
        body.uuid = uuid
        body.questionType = questionType
        body.fields = {}

        // Check each property for changes
        Object.keys(form).forEach(key => {
            if (form[key] !== { points, question, uuid, questionType, answerString }[key]) {
                if (key !== "uuid" || key !== "questionType") {
                    if (key === "points") {
                        if (form.points !== Number(points).toFixed(2)) {
                            body.fields[key] = form[key];
                        }
                    } else {
                        body.fields[key] = form[key];
                    }
                }
            }
        });

        if (Object.keys(body.fields).length === 0) {
            return;
        }

        // updateQuestionsToSave
        updateQuestionsToSave((prev) => ({
            ...prev,
            [form.uuid]: body
        }))
    };

    useEffect(() => {
        // Define a timeout to submit the form after 0.5s
        const timer = setTimeout(() => {
            submitForm();
        }, 500); // 500ms delay
    
        // Cleanup function to clear the timeout if dependencies change
        return () => {
            clearTimeout(timer);
        };
    }, [form]);

    useEffect(() => {
        if (uuid in questionsToSave) {
            const savedFields = questionsToSave[uuid].fields;
            if (savedFields) {
                // Iterate over savedFields and update the form with the key-value pairs
                saveFields(form, savedFields, updateForm);
            }
        }
    }, [questionsToSave, uuid]);
    
    return (
        <>
            <div className="true-false-question-view">
                <div className="question-number">
                    <div className="grid-number-container">
                        {
                            showDrag

                            ?

                            <>
                                <Grid provided={provided}/>
                            </>

                            :

                            <>
                            </>

                        }
                        Q{ index }
                    </div>
                    <div className="pts">
                        <>
                            <input onBlur={submitForm} className="grading-box-question" type="none" value={form.points} onChange={(e) => {
                                updateForm("points", e.target.value);
                            }}/>
                            pts
                        </>
                        <TrashQuestion uuid={uuid} updateDeleteQuestion={updateDeleteQuestion} bankUuid={bankUuid}/>
                    </div>
                </div>
                <div className="text-response-area">
                    <textarea onBlur={submitForm} className="text-response-area-input" placeholder="Enter question" style={{ minHeight: "100px" }} value={form.question} onChange={(e) => {
                        updateForm("question", e.target.value);
                    }}/>
                </div>
                <div className="text-response-area">
                    <div className="autograde-answer-string">
                        Answer:
                        <input placeholder="Leave blank for no autograding (case insensitive and whitespace stripped)" className="auto-answer-input" value={form.answerString} onChange={(e) => updateForm("answerString", e.target.value)}/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TextResponseEdit;