import React, { useState, useEffect } from "react";
import TrashQuestion from "./trashQuestion";
import Grid from "./grid";
import { languageMap } from "./languagemap";
import { languageReadableMap } from "./languageReadableMap";
import useWindowWidth from "./useWindowWidth";
import AddTestsForm from "./addTestsForm";
import saveFields from "./saveFields";


const CodeResponseEdit = ({ questionsToSave, ignoredPaths, testsConfigDict, tests, showDrag, updateQuestionsToSave, provided, useAutocompletion, contents, updateDeleteQuestion, index, points, question, uuid, questionType, location, language, useSyntaxHighlighting, zipFileExists, bankUuid }) => {    
    const width = useWindowWidth(500);
    const [selectTests, setSelectTests] = useState(false);
    const [form, setForm] = useState({
        question: question,
        points: Number(points).toFixed(2),
        uuid: uuid,
        questionType: questionType,
        location: location,
        language: language,
        tests: tests,
        useAutocompletion: useAutocompletion
    });

    function updateSelectTests(bool) {
        setSelectTests(bool);
    }

    function updateForm(key, value) {
        setForm((prev) => (
            {
                ...prev,
                [key]: value
            }
        ))
    }

    function updateTests(testId) {
        if (testId && !form.tests.includes(testId)) {
            setForm((prev) => (
                {
                    ...prev,
                    tests: [...form.tests.filter((id) => (id in testsConfigDict)), testId]
                }
            ))
        } else if (!testId && !form.tests.includes(testId)) {
            setForm((prev) => (
                {
                    ...prev,
                    tests: []
                }
            ))  
        }          
    }

    const submitForm = async () => {
        // construct a new body for post with all values that have changed
        const body = {};
        body.uuid = uuid
        body.questionType = questionType
        body.fields = {}

        // Check each property for changes
        Object.keys(form).forEach(key => {
            if (form[key] !== { points, question, uuid, tests, questionType, language, location, useAutocompletion }[key]) {
                if (key !== "uuid" || key !== "questionType") {                
                    if (key === "points") {
                        if (form.points !== Number(points).toFixed(2)) {
                            body.fields[key] = form[key];
                        }
                    } else {
                        body.fields[key] = form[key];
                    }
                }
            }
        });

        if (Object.keys(body.fields).length === 0) {
            return;
        }

        // updateQuestionsToSave
        updateQuestionsToSave((prev) => ({
            ...prev,
            [form.uuid]: body
        }))
    };

    useEffect(() => {
        // Define a timeout to submit the form after 0.5s
        const timer = setTimeout(() => {
            submitForm();
        }, 500); // 500ms delay
    
        // Cleanup function to clear the timeout if dependencies change
        return () => {
            clearTimeout(timer);
        };
    }, [form.points, form.question]);

    useEffect(() => {
        if (form.location !== location) {
            const lastSegment = form.location.split('.').pop(); // Gets the string after the last .
            const newLanguage = lastSegment in languageMap ? languageMap[lastSegment] : "plaintext";
            updateForm("language", newLanguage);
        }
    }, [form.location]);

    useEffect(() => {
        submitForm();
    }, [form.language, form.useAutocompletion, form.tests])

    const handleTestDeletion = (idOfTest) => {
        setForm((prev) => (
            {
                ...prev,
                tests: form.tests.filter((id) => id !== idOfTest && id in testsConfigDict)
            }
        ))
    }

    useEffect(() => {
        if (uuid in questionsToSave) {
            const savedFields = questionsToSave[uuid].fields;
            if (savedFields) {
                // Iterate over savedFields and update the form with the key-value pairs
                saveFields(form, savedFields, updateForm);
            }
        }
    }, [questionsToSave, uuid]);
    
    return (
        <>
            <div className="true-false-question-view">
                <div className="question-number">
                    <div className="grid-number-container">
                        {
                            showDrag

                            ?

                            <>
                                <Grid provided={provided}/>
                            </>

                            :

                            <>
                            </>

                        }
                        Q{ index }
                    </div>
                    <div className="pts">
                        <>
                            <input className="grading-box-question" type="none" value={form.points} onChange={(e) => {
                                updateForm("points", e.target.value);
                            }}/>
                            pts
                        </>
                        <TrashQuestion uuid={uuid} updateDeleteQuestion={updateDeleteQuestion} bankUuid={bankUuid}/>
                    </div>
                </div>
                <div className="text-response-area">
                    <textarea className="text-response-area-input" placeholder="Enter question" style={{ minHeight: "100px" }} value={form.question} onChange={(e) => {
                        updateForm("question", e.target.value);
                    }}/>
                </div>
                <div className="container-zip-location">
                    {
                        zipFileExists

                        ?

                        <>
                            <div className="message-container" style={{ flexDirection: width ? "column" : "" }}>
                                <div className="message-container" style={{ alignItems: "center" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{ minWidth: "16px" }}>
                                        <path d="M8.5 9.438V8.5h-1v.938a1 1 0 0 1-.03.243l-.4 1.598.93.62.93-.62-.4-1.598a1 1 0 0 1-.03-.243"/>
                                        <path d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m2.5 8.5v.938l-.4 1.599a1 1 0 0 0 .416 1.074l.93.62a1 1 0 0 0 1.109 0l.93-.62a1 1 0 0 0 .415-1.074l-.4-1.599V8.5a1 1 0 0 0-1-1h-1a1 1 0 0 0-1 1m1-5.5h-1v1h1v1h-1v1h1v1H9V6H8V5h1V4H8V3h1V2H8V1H6.5v1h1z"/>
                                    </svg>
                                    Zip File Location:
                                </div>
                                <select className="metric-select question" value={form.location in contents ? form.location : ""} onChange={(e) => {
                                    updateForm("location", e.target.value)
                                }} style={{ width: "auto", maxWidth: "150px" }}>
                                    {Object.entries(contents)
                                        .filter(([key, value]) => !value['isDir']) // Filter where 'isDir' is true
                                        .map(([key, value]) => (
                                            !key.endsWith(".json") && !(key in ignoredPaths)

                                            ?

                                            <option key={key} value={key}>
                                                {key}
                                            </option>

                                            :

                                            null
                                        ))
                                    }
                                    <option key={""} value={""}>
                                        None
                                    </option>

                                </select>
                            </div>    
                        </>

                        :

                        <>
                            <div className="message-container">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{ minWidth: "16px" }}>
                                    <path d="M8.5 9.438V8.5h-1v.938a1 1 0 0 1-.03.243l-.4 1.598.93.62.93-.62-.4-1.598a1 1 0 0 1-.03-.243"/>
                                    <path d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m2.5 8.5v.938l-.4 1.599a1 1 0 0 0 .416 1.074l.93.62a1 1 0 0 0 1.109 0l.93-.62a1 1 0 0 0 .415-1.074l-.4-1.599V8.5a1 1 0 0 0-1-1h-1a1 1 0 0 0-1 1m1-5.5h-1v1h1v1h-1v1h1v1H9V6H8V5h1V4H8V3h1V2H8V1H6.5v1h1z"/>
                                </svg>
                                Upload a zip file to set a location for autograding
                            </div>
                        </>

                    }
                </div>
                <div className="container-zip-location">
                    {
                        zipFileExists

                        ?

                        form.location in contents 
                        
                        ?

                        <>
                            <div className="container-for-test-config">
                                <div className="message-container" style={{ flexDirection: width ? "column" : "" }}>
                                    <div className="message-container" style={{ alignItems: "center" }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{ minWidth: "16px" }}>
                                            <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M6 5.883a.5.5 0 0 1 .757-.429l3.528 2.117a.5.5 0 0 1 0 .858l-3.528 2.117a.5.5 0 0 1-.757-.43V5.884z"/>
                                        </svg>
                                        Autograde Tests:
                                    </div>
                                    <div className="tests-button" onClick={() => updateSelectTests(true)}>
                                        Select Tests
                                    </div>                               
                                </div>
                                {
                                    form && form?.tests.length > 0

                                    ?
                                
                                    <div className="selected-tests">
                                        {
                                            form.tests.filter((id) => (id in testsConfigDict)).map((idOfTest, index) => (
                                                <div key={idOfTest} className="selected-test">                                                   
                                                    <svg onClick={() => handleTestDeletion(idOfTest)} style={{ cursor: "pointer", minWidth: "16px" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                        <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/>
                                                    </svg> 
                                                    {index + 1}: {testsConfigDict[idOfTest].name}
                                                </div>
                                            ))
                                        }
                                    </div>

                                    :

                                    <>
                                    </>

                                }
                            </div>                             
                        </>

                        :

                        <>
                            <div className="message-container">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{ minWidth: "16px" }}>
                                    <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M6 5.883a.5.5 0 0 1 .757-.429l3.528 2.117a.5.5 0 0 1 0 .858l-3.528 2.117a.5.5 0 0 1-.757-.43V5.884z"/>
                                </svg>
                                Choose a valid zip file location to select tests for autograding
                            </div>
                        </>

                        :

                        <>
                            <div className="message-container">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{ minWidth: "16px" }}>
                                    <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M6 5.883a.5.5 0 0 1 .757-.429l3.528 2.117a.5.5 0 0 1 0 .858l-3.528 2.117a.5.5 0 0 1-.757-.43V5.884z"/>
                                </svg>
                                Upload a zip file to select tests for autograding
                            </div>
                        </>

                    }
                </div>
                <div className="container-zip-location">
                    {
                        useSyntaxHighlighting

                        ?

                        <>
                            <div className="message-container" style={{ flexDirection: width ? "column" : "", width: "100%"  }}>
                                <div className="message-container" style={{ alignItems: "center" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{ minWidth: "16px" }}>
                                        <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M6.646 5.646a.5.5 0 1 1 .708.708L5.707 8l1.647 1.646a.5.5 0 0 1-.708.708l-2-2a.5.5 0 0 1 0-.708zm2.708 0 2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L10.293 8 8.646 6.354a.5.5 0 1 1 .708-.708"/>
                                    </svg>
                                    Programming Language:
                                </div>
                                <select className="metric-select question" value={form.language} onChange={(e) => {
                                    updateForm("language", e.target.value)
                                }} style={{ width: "auto", maxWidth: "150px" }}>
                                    {Object.entries(languageReadableMap).map(([key, value]) => (
                                        <option key={key} value={value}>
                                            { key }
                                        </option>
                                    ))}
                                </select>
                            </div>    
                        </>                        

                        :

                        <>
                            <div className="message-container">                                
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{ minWidth: "16px" }}>
                                    <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M6.646 5.646a.5.5 0 1 1 .708.708L5.707 8l1.647 1.646a.5.5 0 0 1-.708.708l-2-2a.5.5 0 0 1 0-.708zm2.708 0 2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L10.293 8 8.646 6.354a.5.5 0 1 1 .708-.708"/>
                                </svg>
                                Turn on syntax highlighting to select a programming language
                            </div>
                        </>
                    }
                </div>
                <div className="container-zip-location">
                    {
                        useSyntaxHighlighting

                        ?

                        <>
                            <div className="message-container" style={{ flexDirection: width ? "column" : "", alignItems: width ? "flex-start" : "center" }}>
                                <div className="message-container" style={{ alignItems: "center" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                        <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2m-1.146 6.854-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708.708"/>
                                    </svg>                                    
                                    Code Autocompletion:
                                </div>
                                <input className="check-auto" type="checkbox" checked={form.useAutocompletion} onChange={(e) => updateForm("useAutocompletion", e.target.checked)} />
                            </div>    
                        </>                        

                        :

                        <>
                            <div className="message-container">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2m-1.146 6.854-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708.708"/>
                                </svg> 
                                Turn on syntax highlighting to use code autocompletion
                            </div>
                        </>
                    }
                </div>
            </div>
            { selectTests ? <AddTestsForm testsConfigDict={testsConfigDict} updateTests={updateTests} updateSelectTests={updateSelectTests} handleTestDeletion={handleTestDeletion} tests={form.tests}/> : <></> }
        </>
    );
}

export default CodeResponseEdit;