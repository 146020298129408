const TypesOfQuestionsEnum = {
    "PROGRAMMING": "PROGRAMMING",
    "MULTIPLE_CHOICE": "MULTIPLE_CHOICE",
    "SELECT_ALL": "SELECT_ALL",
    "SHORT_RESPONSE": "SHORT_RESPONSE",
    "TRUE_FALSE": "TRUE_FALSE",
    "CONTEXT_BLOCK": "CONTEXT_BLOCK",
    "BANK_OF_QUESTIONS": "BANK_OF_QUESTIONS",
    "SCAN_AND_GRADE": "SCAN_AND_GRADE"
}

export default TypesOfQuestionsEnum;