import React from 'react';

const PrivacyPolicy = () => (
    <div className="signup-container">
        <div className="add-a-course-form-container" style={{ width: "800px", fontSize: "14px", color: "var(--almost-black)" }}>
    <h1>Privacy Policy</h1>
    <div>
    <p>
      At GoValuate, accessible at GoValuate.com, safeguarding the privacy of our users is a top priority. This Privacy Policy outlines the types of information collected and recorded by GoValuate, how we use it, and our commitment to protecting your personal data.
    </p>

    </div>

    <div>
        <h2>1. Scope</h2>
        <p>
        This Privacy Policy applies solely to our online activities and is valid for visitors to our website or mobile apps regarding the information they share and/or collect on GoValuate. It does not apply to information collected offline or through other channels.
        </p>
    </div>
    

    <div style={{ width: "100%" }}>
        <h2>2. Consent</h2>
        <p>
        By using our website, you consent to our Privacy Policy and agree to its terms.
        </p>
    </div>

    <div>
        <h2>3. Information We Collect</h2>
        <p>
        We collect personal information you provide when registering for an account or contacting us directly. This may include your name, email address, and other details necessary for us to provide our services. The reasons for collecting this information will be made clear to you at the point of collection.
        </p>
    </div>

    <div style={{ width: "100%" }}>
    <h2>4. How We Use Your Information</h2>
    <p>
      We use the information we collect to:
    </p>
    <ul>
      <li>Operate, maintain, and improve our website</li>
      <li>Personalize your experience on our site</li>
      <li>Analyze usage patterns to enhance our services</li>
      <li>Develop new features and functionality</li>
      <li>Communicate with you about updates, customer service, and marketing</li>
      <li>Prevent fraud and ensure site security</li>
    </ul>

    <p>WE DO NOT SELL YOUR INFORMATION TO ANY THIRD PARTY ENTITIES. <br/>WE DO NOT COLLECT ANY USER INFORMATION FOR ADVERTISING. <br/>WE DO NOT SHARE ANY USER INFORMATION.</p>
    </div>

    <div>
    <h2>5. Cookies</h2>
    <p>
      GoValuate uses cookies solely to maintain and enhance the functionality of our site. Cookies help us store user information and keep you signed in as you explore our site and apps. We do not use cookies to collect personal information or sell it to third parties.
    </p>

    </div>

    <div>
    <h2>6. Third-Party Cookies and Advertising</h2>
    <p>
      We do not use third-party cookies or serve third-party advertisements on our site. All cookies used are for site maintenance and functionality only.
    </p>

    </div>

    <div>
    <h2>7. Your Data Protection Rights</h2>
    <p>
      Under applicable data protection laws, you have the following rights regarding your personal data:
    </p>
    <ul>
      <li><strong>Access</strong>: Request copies of your personal data.</li>
      <li><strong>Rectification</strong>: Correct any inaccurate or incomplete information.</li>
      <li><strong>Erasure</strong>: Request deletion of your personal data under certain conditions.</li>
      <li><strong>Restriction</strong>: Request restriction on the processing of your data under certain conditions.</li>
      <li><strong>Objection</strong>: Object to our processing of your personal data under certain conditions.</li>
      <li><strong>Portability</strong>: Request transfer of your data to another organization or directly to you under certain conditions.</li>
    </ul>
    <p>
      To exercise any of these rights, please contact us, and we will respond as soon as we can.
    </p>

    </div>

    <div>

    <h2>8. Changes to This Policy</h2>
    <p>
      We may update this Privacy Policy periodically. Any changes will be posted on this page with an updated effective date. Thank you for trusting GoValuate with your information. We are committed to protecting your privacy and ensuring a safe and secure experience on our site.
    </p>
    </div>
  </div>
  </div>
);

export default PrivacyPolicy;
