import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollToTop = () => {  
    const { pathname } = useLocation();

    useEffect(() => {
    const scrollToTop = () => {
        window.scrollTo({
        top: 0,
        behavior: 'instant',
        });
    };

    scrollToTop();
    }, [pathname]);

};

export default useScrollToTop;
