import React from "react";
import useWindowWidth from "./useWindowWidth";
import { useNavigate, useParams } from "react-router-dom";
import HandleSubmissionStatus from "./handleSubmissionStatus";
import convertDate from "./convertDate";
import Remove from "./remove";

const StudentSubmission = (props) => {
    const width = useWindowWidth(800);
    const { courseCode, assessmentCode } = useParams();
    const navigate = useNavigate();
    const handleClick = (event) => {
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${assessmentCode}/${props.email}`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${assessmentCode}/${props.email}`)
        }
    }

    const handleRemoveClick = async () => {
        props.updateDeleteSubmission(true);
        props.updateEmail(props.email);
    }

    return (
        <>
            <div className="exam-container">
                <div className="course-metadata-2"> 
                    <div className="roster-meta">
                        {
                            width

                            ?

                            <>
                                <div className="color one roster-student small" style={{ marginRight: "20px" }}>
                                    <div className="sub-del">
                                        <div className="remove" onClick={handleRemoveClick}>
                                            <Remove />   
                                        </div>                                
                                        <div className="name-submission" style={{ width: "fit-content" }} onClick={ handleClick }>
                                            { props.name }
                                        </div> 
                                    </div>
                                </div>
                                <div className="color roster-student small handle-status" style={{ textAlign: "right" }}>
                                { HandleSubmissionStatus(props.endedExam) } { props.endedExam ? "Graded" : "Unfinished" }
                                </div>
                            </>

                            :

                            <>
                                <div className="color roster-student" style={{ marginRight: "20px", width: "35%" }}>
                                    <div className="sub-del">
                                        <div className="remove" onClick={handleRemoveClick}>
                                            <Remove />   
                                        </div>                                
                                        <div className="name-submission" style={{ width: "fit-content" }} onClick={ handleClick }>
                                            { props.name }
                                        </div> 
                                    </div>                                
                                </div>
                                <div className="color roster-student handle-status" style={{ width: "30%" }}>
                                    { HandleSubmissionStatus(props.endedExam) } { props.endedExam ? "Graded" : "Unfinished" }
                                </div>
                                <div className="color roster-student" style={{ width: "20%" }}>
                                    { convertDate(props.startTime, true) }
                                </div>
                                <div className="color roster-student three">
                                    { convertDate(props.endTime, true) }
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default StudentSubmission;