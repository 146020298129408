import JSZip from 'jszip';

async function validateZipFile(zipFile) {  

  // Check if the file size is less than or equal to 5MB
  if (zipFile.size > 5 * 1024 * 1024) {
    return [false, "Zip file size exceeds 5MB"];
  }

  const zip = new JSZip();

  // Load the zip file
  const loadedZip = await zip.loadAsync(zipFile);

  // Check for setup.sh at the root
  if (!loadedZip.files['setup.sh']) {
    return [false, "Missing setup.sh at root"];
  }

  // Check for tests_config.json at the root
  if (!loadedZip.files['tests_config.json']) {
    return [false, "Missing tests_config.json at root"];
  }

  // Load and parse tests_config.json
  const testsConfig = await loadedZip.file('tests_config.json').async('string');
  let parsedConfig;
  
  try {
    parsedConfig = JSON.parse(testsConfig);
  } catch (error) {
    return [false, "tests_config.json is not valid JSON"];
  }

  // Check for 'ignore' and 'tests' arrays in tests_config.json
  if (!Array.isArray(parsedConfig.ignore)) {
    return [false, "Missing or invalid 'ignore' array in tests_config.json"];
  }

  if (!Array.isArray(parsedConfig.tests)) {
    return [false, "Missing or invalid 'tests' array in tests_config.json"];
  }

  // All requirements are met
  return [true, "All requirements met"];
}

export default validateZipFile;