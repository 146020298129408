import React from "react";
import useWindowWidth from "./useWindowWidth";

const StatusCircle = (props) => {
    const width = useWindowWidth(500);

    return (
        <>
            <div className="course-dropdown-container-custom" style={{ marginRight: width ? "5px" : "10px" }}>
                <div className="course-dropdown-custom" style={{ backgroundColor: props.color }}>

                </div>
            </div>
        </>
    );
}

export default StatusCircle;