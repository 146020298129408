import React from "react";

const LocationSettingsItem = (props) => {
    return (
        <>
            <div className="location-settings-hover-item" onClick={() => {
                if (props.fetchdata) {
                    props.updateFetchData(true);
                }
                props.updateCurrentViewer(props.path)
            }}>
                { props.svgOne }
            </div>
        </>
    );
}

export default LocationSettingsItem;