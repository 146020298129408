import React, { useState, useEffect, useRef } from "react";
import TrueFalseQuestionView from "./trueFalseQuestionView";
import SelectAllThatApplyView from "./selectAllThatApplyView";
import TextResponseView from "./textResponseView";
import CodeResponseView from "./codeResponseView";
import MultipleChoiceView from "./multipleChoiceView";
import TypesOfQuestionsEnum from "./typesOfQuestionsEnum";
import ContextBlockView from "./contextBlockView";
import TypesOfLocationEnum from "./typesOfLocationEnum";
import CompleteOnPaperView from "./completeOnPaperView";

const CustomExamWrapperView = ({ questionsToSave, useSyntaxHighlighting, contents, questions, grading, updateIsEditing, updateAddAQuestion, updateCurrentViewer }) => {
    const [arrayOfIndexes, setArrayOfIndexes] = useState([])
    const [isDarkTheme, setIsDarkTheme] = useState(true);
    const [initCall, setInitCall] = useState(false);
    const ref = useRef(null);
    
    useEffect(() => {
        const fetchTheme = async () => {
            try {
                const response = await fetch( process.env.REACT_APP_SUBMISSION_API_URL + `/get-theme`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await response.json();
                if (response.status === 200) {
                    setIsDarkTheme(data.isDarkTheme);
                } else if (response.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                }
            } catch (error) {
                console.log(error);
            }
        };
        
        fetchTheme();
    }, [])

    function updateIsDarkTheme(theme) {
        setIsDarkTheme(theme);        
    }

    useEffect(() => {
        if (initCall) {
            submitForm(isDarkTheme);
        } else {
            setInitCall(true);
        }
    }, [isDarkTheme])

    const submitForm = async (theme) => {
        try {
            const url = process.env.REACT_APP_SUBMISSION_API_URL + "/update-theme";
            const urlOptions = {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    isDarkTheme: theme
                })
            }

            const response = await fetch(url, urlOptions);
            const data = await response.json();
            console.log(data);
            if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL
            }

        } catch(error) {
            console.log(error);
        }
    }

    function updateArrayOfIndexes(newElement) {
        setArrayOfIndexes((prevArray) => ([
            ...prevArray,
            newElement
        ]))
    }

    const typeOfQuestionToReturn = (question, index) => {
        switch (question.questionType) {
            case TypesOfQuestionsEnum.TRUE_FALSE: {
                return <TrueFalseQuestionView questionsToSave={questionsToSave} studentAnswer={question.studentAnswer} bubbledAnswer={question.bubbled} pointsEarned={question.pointsEarned} grading={grading} key={question.uuid} uuid={question.uuid} questionNumber={index} points={question.points} question={question.question}/>
            }
            case TypesOfQuestionsEnum.SELECT_ALL: {
                return <SelectAllThatApplyView questionsToSave={questionsToSave} studentAnswer={question.studentAnswer} answerArrayAnswer={question.answerArray} pointsEarned={question.pointsEarned} grading={grading} key={question.uuid} uuid={question.uuid} questionNumber={index} points={question.points} question={question.question} options={question.shuffle && !grading ? [...question.options].sort(() => Math.random() - 0.5) : question.options}/>
            }
            case TypesOfQuestionsEnum.SHORT_RESPONSE: {
                return <TextResponseView questionsToSave={questionsToSave} studentAnswer={question.studentAnswer} pointsEarned={question.pointsEarned} grading={grading} key={question.uuid} uuid={question.uuid} questionNumber={index} points={question.points} question={question.question}/>
            }
            case TypesOfQuestionsEnum.SCAN_AND_GRADE: {
                return <CompleteOnPaperView questionsToSave={questionsToSave} pointsEarned={question.pointsEarned} grading={grading} key={question.uuid} uuid={question.uuid} questionNumber={index} points={question.points} question={question.question}/>
            }
            case TypesOfQuestionsEnum.MULTIPLE_CHOICE: {
                return <MultipleChoiceView questionsToSave={questionsToSave} studentAnswer={question.studentAnswer} answerIndexAnswer={question.answerIndex} pointsEarned={question.pointsEarned} grading={grading} key={question.uuid} uuid={question.uuid} questionNumber={index} points={question.points} question={question.question} options={question.shuffle && !grading ? [...question.options].sort(() => Math.random() - 0.5) : question.options}/>
            }
            case TypesOfQuestionsEnum.PROGRAMMING: {
                return <CodeResponseView questionsToSave={questionsToSave} useSyntaxHighlighting={useSyntaxHighlighting} studentAnswer={question.studentAnswer} pointsEarned={question.pointsEarned} contents={contents} grading={grading} uuid={question.uuid} location={question.location} useAutocompletion={question.useAutocompletion} key={question.uuid} questionNumber={index} points={question.points} question={question.question} language={question.language} isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme}/>
            }
            case TypesOfQuestionsEnum.CONTEXT_BLOCK: {
                return <ContextBlockView questionsToSave={questionsToSave} key={question.uuid} questionNumber={index} points={question.points} uuid={question.uuid} question={question.question}/>
            }
            case TypesOfQuestionsEnum.BANK_OF_QUESTIONS: {
                if (question.questions.length === 0) {
                    return <></>
                }
                const ajdustedIndex = index - 1
                if (ajdustedIndex >= arrayOfIndexes.length) {
                    const randomIndex = Math.floor(Math.random() * question.questions.length)
                    updateArrayOfIndexes(randomIndex)
                    return typeOfQuestionToReturn(question.questions[randomIndex], index);
                } else {
                    return typeOfQuestionToReturn(question.questions[arrayOfIndexes[ajdustedIndex]], index);
                }
            }
            default: {
                return <></>
            }
        }
    }

    return (
        <>
            {
                questions && Object.keys(questions).length > 0

                ?

                <>
                    <div className="custom-exam-wrapper-view" ref={ref}>
                        {
                            questions
                                .map((question, index) => (
                                        typeOfQuestionToReturn(question, index + 1)
                                )                                    
                            )
                        }
                    </div>
                </>

                :

                <>
                    <div className="no-questions">
                        <div className="no-assignments-container" style={{ paddingTop: "0px"}}>
                            <div className="no-assignments"> 
                                There are currently no { grading ? "answers to grade" : "questions to preview" }
                                <div className="save-changes-2" onClick={() => {
                                    if (grading) {
                                        updateCurrentViewer(TypesOfLocationEnum.SETTINGS);
                                    } else {
                                        updateIsEditing(true);
                                        updateAddAQuestion(true);
                                    }
                                }}>
                                    { grading ? "Back To Submission" : "Create A Question" }
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default CustomExamWrapperView;