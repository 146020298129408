import React from "react";

const YearAndTerm = (props) => {
    const handleYearChange = (e) => {
        props.updateYear(e.target.value)
    }

    const handleTermChange = (e) => {
        props.updateTerm(e.target.value)
    }

    return (
        <>
            <div className="year-and-term-container">
                <div className="year-term">
                    <div className="top-input">
                        Term
                        {
                            props.required

                            ?
                                <div className="required">
                                    *
                                </div>
                            :
                                <></>
                                
                        }
                    </div>
                    <select className="input-info" value={props.term} onChange={(e) => {handleTermChange(e)}}>
                        <option value="Fall">Fall</option>
                        <option value="Summer">Summer</option>
                        <option value="Spring">Spring</option>
                        <option value="Winter">Winter</option>
                    </select>
                </div>
                <div className="year-term">
                    <div className="top-input">
                        Year
                        {
                            props.required

                            ?
                                <div className="required">
                                    *
                                </div>
                            :
                                <></>
                                
                        }
                    </div>
                    <input type="number" min={2024} className="input-info" value={props.year} onChange={(e) => {handleYearChange(e)}} />
                </div>
            </div>
        </>
    );
}

export default YearAndTerm;