import React from "react";
import Square from "./square";

const SquareWithTextView = (props) => {
    return (
        <>
            <div className="square-with-text-view">
                <Square text={props.text} index={props.index} correct={props.correct} updateIndex={props.updateIndex}/>
                { props.text }
            </div>
        </>
    );
}

export default SquareWithTextView;