import React from "react";
import useWindowWidth from "./useWindowWidth";
import intToLetters from "./intToLetters";
import formatTime from "./formatTime";
import FeedbackWindow from "./feedbackWindow";
import GradedPercentWrapper from "./gradedPercentWrapper";
import Loading from "./loading";
import OnlyIInfo from "./onlyIInfo";

const LeftInfoGradingPage = ({ currentViewer, requestLifeCycle, handleBlur, handleFocus, assessmentInformation }) => {
    const width = useWindowWidth(1000);

    return (
        <>
            <div className={`left-info fullscreen ${width ? "small" : ""}`} style={{ paddingBottom: "0px" }}>
                <div className="left-info-content" style={{ border: "none" }}>
                    {
                        requestLifeCycle

                        ?

                        <>
                            <div className="loading-container">
                                <Loading />
                            </div>  
                        </>

                        :

                        <>
                            {
                                assessmentInformation && Object.keys(assessmentInformation).length > 0

                                ?

                                <>
                                    <div className="side-nav-info-header" style={{ display: "flex", justifyContent: "space-between", columnGap: "10px", width: "100%" }}>
                                        <span className="student-name-overflow">
                                            { assessmentInformation.name }
                                        </span>
                                    </div>
                                    <div className="side-nav-info-header">
                                        Score
                                    </div>
                                    <div className="text-side-nav display-2">
                                        <div className="points">
                                            { Number(assessmentInformation.pointsEarned).toFixed(2) + " "} / { Number(assessmentInformation.points).toFixed(2) }   
                                        </div>                                     
                                    </div>                                    
                                    {
                                        assessmentInformation.formIds.length > 1 

                                        ?

                                        <>
                                            <div className="side-nav-info-header">
                                                Form Id
                                            </div>
                                            <div className="text-side-nav display-2">
                                                {
                                                    assessmentInformation.formIds.includes(assessmentInformation.formId)
                                                    
                                                    ?
                                                    
                                                    intToLetters(assessmentInformation.formIds.indexOf(assessmentInformation.formId))
                                                    
                                                    :

                                                    "N/A"
                                                }
                                            </div>
                                        </>

                                        :

                                        <>
                                        </>
                                    }
                                    {
                                        'completionTime' in assessmentInformation

                                        ?

                                        <>
                                            <div className="side-nav-info-header">
                                                Completion Time
                                            </div>
                                            <div className="text-side-nav display-2">
                                                { formatTime(assessmentInformation.completionTime) }
                                            </div>
                                        </>
                                        

                                        :

                                        <>
                                        </>
                                    }                                      
                                    <div className="side-nav-info-header" style={{ display: "flex", justifyContent: "space-between", columnGap: "10px", width: "100%" }}>
                                        Feedback
                                    </div>
                                    <FeedbackWindow handleFocus={handleFocus} handleBlur={handleBlur} feedback={'feedback' in assessmentInformation ? assessmentInformation.feedback  : ""} feedbackCode={'feedbackCode' in assessmentInformation ? assessmentInformation.feedbackCode  : ""}/>
                                    <GradedPercentWrapper />
                                </>

                                :

                                <>
                                </>

                            }   
                        </>                                            

                    }
                </div>
            </div>
            <div className={ `pdf-content-container  ${ width ? "small" : "" }` } style={{ minHeight: width ? "600px" : "", position: "relative"}}>
                <div className="loading-zip-container" style={{ flexDirection: "column", columnGap: "10px" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 16 16">
                        <path fill="#0B73FE" stroke="#0B73FE" strokeWidth=".3" d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1M6.354 9.854a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 8.707V12.5a.5.5 0 0 1-1 0V8.707z"/>
                    </svg>
                    Navigate through resources below
                </div>
                <OnlyIInfo currentViewer={currentViewer}/>
            </div>
        </>
    );
}

export default LeftInfoGradingPage;