import React, { useState } from "react";
import AddProgrammingEvalForm from "./addProgrammingEvalForm";
import useWindowWidth from "./useWindowWidth";
import NewProgrammingExamIcon from "./newProgrammingExamIcon";

const AddProgrammingEval = (props) => {
    const [adding, setAdding] = useState(false);
    const width = useWindowWidth(800);

    const updateCreating = (bool) => {
        setAdding(bool)
        props.updateCreating(bool)
    }

    return (
        <>
            <div className={`add-course basic-container`} onClick={ () => {updateCreating(true)} } style={{ columnGap: "10px", width: width ? "60px" : "140px", paddingLeft: width ? "10px" : "", paddingRight: width ? "10px" : "" }}>
                {
                    width

                    ?

                    <>
                        { "New" }
                        <NewProgrammingExamIcon />
                    </>

                    :

                    <>                    
                        <NewProgrammingExamIcon />
                        { "New Evaluation" }
                    </>
                }
            </div>
            { adding || props.creating ? <AddProgrammingEvalForm updateCreating={updateCreating}/> : <></> }
        </>
    );
}

export default AddProgrammingEval;