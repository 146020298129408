import React from "react";
import { useNavigate } from "react-router-dom";

const ToDocumentation = () => {
    const navigate = useNavigate();

    const handleClick = (event) => {
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/autograder-docs`, '_blank');
        } else {
            // Navigate in the same tab
            navigate("/autograder-docs");
        }
    }

    return (
        <>
            <div className="input-field">
                <div className="top-input" style={{ columnGap: "10px" }}>
                    Zip File Docs:
                    <div className="to-docs-container">
                        <div className="docs-wrapper-icon" onClick={handleClick}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" viewBox="0 0 16 16">
                                <path stroke="currentColor" strokeWidth=".3" d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ToDocumentation;            