const markdownDocs = `
### Autograder Documentation

### Glossary

- **Overview**: 1.
- **Zip File Requirements**: 2
- **JSON File Requirements**: 3.
- **Example**: 4.

### 1. Overview

This document provides an outline of the GoValuate Autograder, including required files, best practices, and an explanation of the autograder run-time environment.

### 2. Zip File Directory Structure

The following directory tree illustrates the requirenments of an uploaded zip file for the autograder:

\`\`\`plaintext
root/
├── setup.sh (required)
├── tests_config.json (required)
├── <any and all files for question mapping> (optional)
\`\`\`

The GoValuate Autograder uses the AL2023 Linux operating system. There is 10 Gigabytes of storage available for each invocation. However, an uploaded starter zip file cannot exceed 5MB in size.The setup.sh file is the entry point of your scripts
that will be called upon build of the custom GoValuate Container Image. You may use \`dnf install\` to install any necessary dependencies
that are required to run your test files. You may also use \`yum install\`; however, this is a pointer to the dnf command. After installation of dependencies, your setup.sh file should call your test files.
The tests_config.json is the destination for the results of your tests, which should be within the initial zip file uploaded before
your evaluation is taken (see more in part 3). The \`tests_config.json\` file and \`setup.sh\` file should always 
be at the root of the zip file.

### 3. tests_config.json

Initial tests_config.json file within the uploaded zip file:

\`\`\`json
{
  "ignore": [                       (required: may have length zero)
    <string>,
    <string>,
    ...,
    <string>
  ],
  "tests": [                        (required: may have length zero)
    {
        "name": <string>,           (required)
        "totalPoints": <float>,     (required)
        "id": <string>              (required)
    },
    {
        "name": <string>,
        "totalPoints": <float>,
        "id": <string>
    },    
    ...,
    {
        "name": <string>,
        "totalPoints": <float>,
        "id": <string>,
    }
  ]
}
\`\`\`

Final tests_config.json file returned after setup.sh finishes execution:

\`\`\`json
{
  "ignore": [
    <string>,
    <string>,
    ...,
    <string>
  ],
  "tests": [
    {
        "name": <string>,        
        "pointsEarned": <float>, (optional: will default to 0.0)
        "totalPoints": <float>,  
        "output": <string>,      (optional)
        "id": <string>           
    },
    {
        "name": <string>,
        "pointsEarned": <float>,
        "totalPoints": <float>,
        "output": <string>,
        "id": <string>
    },    
    ...,
    {
        "name": <string>,
        "pointsEarned": <float>,
        "totalPoints": <float>,
        "output": <string>,
        "id": <string>,
    }
  ]
}
\`\`\`

The ignore array will store strings of full paths to files
relative to the zip file root. Each string will have the effect 
of popping it's specified file from student viewing and access.
A question cannot map to an ignored file. Furthermore, each id 
should be universally unqiue within the tests array. The ignore and tests 
arrays may have length zero but are required. Finally, 
the initial json version is used for question mapping, and the final json version is 
used for displaying and autograding questions for the user.

### 4. Example

Zip File Directory Structure:

\`\`\`plaintext
root/
├── setup.sh             (will call test_controller.py)
├── tests_config.json
├── test_controller.py
├── question_one.py      (mapping file for user input)


\`\`\`

Initial tests_config.json file within the uploaded zip file:

\`\`\`json
{
  "ignore": [
    "setup.sh",
    "test_controller.py"
  ],
  "tests": [
    {
        "name": "Test 1",
        "totalPoints": 2.0,
        "id": "0"
    },
    {
        "name": "Test 2",
        "totalPoints": 1.0,
        "id": "1"
    },
    {
        "name": "Test 3",
        "totalPoints": 3.0,
        "id": "2"
    }
  ]
}
\`\`\`

The file location and test selection mapping will take place during the 
evaluation build process on GoValuate within the settings section. Multiple questions can map to the same file path within the zip file.
You may include starter code within your files that will be available to students upon the start of the evaluation.


`;

export default markdownDocs;