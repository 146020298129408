import React from "react";
import TypesOfQuestionsEnum from "./typesOfQuestionsEnum";
import TextResponseEdit from "./textResponseEdit";
import TrueFalseQuestionEdit from "./trueFalseQuestionEdit";
import SelectAllThatApplyEdit from "./selectAllThatApplyEdit";
import MultipleChoiceEdit from "./multipleChoiceEdit";
import CodeResponseEdit from "./codeResponseEdit";
import ContextBlockEdit from "./contextBlockEdit";
import { useParams } from "react-router-dom";
import BankOfQuestions from "./BankOfQuestionsEdit";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import OnlyIInfo from "./onlyIInfo";
import CompleteOnPaperEdit from "./completeOnPaperEdit";

const CustomExamWrapperEdit = ({ questionsToSave, ignoredPaths, testsConfigDict, updateFetchData, updateQuestionsToSave, contents, updateQuestions, updateIsEditing, updateAddAQuestion, updateDeleteQuestion, questions, useSyntaxHighlighting, zipFileExists }) => {
    const { courseCode, assessmentCode } = useParams();

    const typeOfQuestionToReturn = (question, index, provided, showDrag, bankUuid) => {
        switch (question.questionType) {
            case TypesOfQuestionsEnum.TRUE_FALSE: {
                return <TrueFalseQuestionEdit questionsToSave={questionsToSave} bankUuid={bankUuid} showDrag={showDrag} updateQuestionsToSave={updateQuestionsToSave} provided={provided} key={question.uuid} index={index} updateDeleteQuestion={updateDeleteQuestion} points={question.points} question={question.question} uuid={question.uuid} questionType={question.questionType} bubbled={question.bubbled}/>
            }
            case TypesOfQuestionsEnum.SELECT_ALL: {
                return <SelectAllThatApplyEdit questionsToSave={questionsToSave} bankUuid={bankUuid} showDrag={showDrag}updateQuestionsToSave={updateQuestionsToSave} shuffle={question.shuffle} provided={provided} key={question.uuid} index={index} updateDeleteQuestion={updateDeleteQuestion} points={question.points} question={question.question} uuid={question.uuid} questionType={question.questionType} options={question.options} answerArray={question.answerArray}/>
            }
            case TypesOfQuestionsEnum.SHORT_RESPONSE: {
                return <TextResponseEdit questionsToSave={questionsToSave} answerString={question.answerString} bankUuid={bankUuid} showDrag={showDrag} updateQuestionsToSave={updateQuestionsToSave} provided={provided} key={question.uuid} index={index} updateDeleteQuestion={updateDeleteQuestion} points={question.points} question={question.question} uuid={question.uuid} questionType={question.questionType}/>
            }
            case TypesOfQuestionsEnum.SCAN_AND_GRADE: {
                return <CompleteOnPaperEdit questionsToSave={questionsToSave} bankUuid={bankUuid} showDrag={showDrag} updateQuestionsToSave={updateQuestionsToSave} provided={provided} key={question.uuid} index={index} updateDeleteQuestion={updateDeleteQuestion} points={question.points} question={question.question} uuid={question.uuid} questionType={question.questionType}/>
            }
            case TypesOfQuestionsEnum.MULTIPLE_CHOICE: {
                return <MultipleChoiceEdit questionsToSave={questionsToSave} bankUuid={bankUuid} showDrag={showDrag} updateQuestionsToSave={updateQuestionsToSave} shuffle={question.shuffle} provided={provided} key={question.uuid} index={index} updateDeleteQuestion={updateDeleteQuestion} points={question.points} question={question.question} uuid={question.uuid} questionType={question.questionType} options={question.options} answerIndex={question.answerIndex}/>
            }
            case TypesOfQuestionsEnum.PROGRAMMING: {
                return <CodeResponseEdit questionsToSave={questionsToSave} ignoredPaths={ignoredPaths} tests={question.tests} testsConfigDict={testsConfigDict} bankUuid={bankUuid} showDrag={showDrag} updateQuestionsToSave={updateQuestionsToSave} provided={provided} useAutocompletion={question.useAutocompletion} contents={contents} zipFileExists={zipFileExists} key={question.uuid} useSyntaxHighlighting={useSyntaxHighlighting} index={index} updateDeleteQuestion={updateDeleteQuestion} points={question.points} question={question.question} uuid={question.uuid} questionType={question.questionType} location={question.location} language={question.language}/>
            }
            case TypesOfQuestionsEnum.CONTEXT_BLOCK: {
                return <ContextBlockEdit questionsToSave={questionsToSave} bankUuid={bankUuid} updateQuestionsToSave={updateQuestionsToSave} provided={provided} key={question.uuid} index={index} updateDeleteQuestion={updateDeleteQuestion} points={question.points} question={question.question} uuid={question.uuid} questionType={question.questionType}/>
            }
            case TypesOfQuestionsEnum.BANK_OF_QUESTIONS: {
                return <BankOfQuestions updateQuestions={updateQuestions} updateFetchData={updateFetchData} typeOfQuestionToReturn={typeOfQuestionToReturn} provided={provided} key={question.uuid} index={index} updateDeleteQuestion={updateDeleteQuestion} points={question.points} questions={question.questions} uuid={question.uuid}/>
            }
            default: {
                return <></>
            }
        }
    }

    const onDragEnd = (result) => {
        const { destination, source } = result;
    
        // Check if the item was dropped outside the list
        if (!destination) {
            return;
        }
    
        // Check if the item was dropped in the same place
        if (destination.index === source.index) {
            return;
        }
        console.log(result)
        const newQuestionsOrder = Array.from(questions);
        const [movedQuestion] = newQuestionsOrder.splice(source.index, 1);
        newQuestionsOrder.splice(destination.index, 0, movedQuestion);
        console.log(newQuestionsOrder)
        // Call your state updater function to save the new order
        updateQuestions(newQuestionsOrder); // You'll need to implement this

        // make api call
        changeOrder(newQuestionsOrder);
    };

    const changeOrder = async (newQuestionsOrder) => {
        const url = process.env.REACT_APP_EVALUATION_API_URL + `/update-question-order`

        const urlOptions = {
            method: "POST",
            credentials: "include",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                courseCode: courseCode,
                assessmentCode: assessmentCode,
                newQuestionsOrder: newQuestionsOrder.map((question) => question.uuid)
            })
        }

        try {
            const res = await fetch(url, urlOptions);
            const data = await res.json();
            console.log(data);
            if (res.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            }
        } catch (error) {
            console.log(error);
        }    
    }

    return (
        <>
            {
                questions && Object.keys(questions).length > 0

                ?

                <>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="questionsOrder">
                            {
                                (provided) => (
                                    <div className="custom-exam-wrapper-view" {...provided.droppableProps} ref={provided.innerRef}>
                                        {                                                
                                            questions
                                                .map((question, index) => (
                                                    <Draggable key={question.uuid} draggableId={question.uuid} index={index}>
                                                        {
                                                            (provided) => (
                                                                <div
                                                                    {...provided.draggableProps}
                                                                    ref={provided.innerRef}
                                                                    className="draggable-question"
                                                                >{ typeOfQuestionToReturn(question, index + 1, provided, true, "") }</div>
                                                                
                                                            )
                                                        }
                                                    </Draggable>
                                                )
                                            )
                                        }
                                        {provided.placeholder}
                                    </div>
                                )
                            }
                        </Droppable>
                    </DragDropContext>
                </>

                :

                <>
                    <div className="no-questions">
                        <div className="no-assignments-container" style={{ paddingTop: "0px"}}>
                            <div className="no-assignments"> 
                                You currently have no questions
                                <div className="save-changes-2" onClick={() => {
                                    updateIsEditing(true);
                                    updateAddAQuestion(true);
                                }}>
                                    Create A Question
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            <OnlyIInfo special={true}/>
        </>
    );
}

export default CustomExamWrapperEdit;