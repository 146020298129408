import React from "react";
import Chevron from "./chevron";
import ChevronLeft from "./chevronLeft";
import useWindowWidth from "./useWindowWidth";

const PDF = (props) => {
    const width = useWindowWidth(1000);

    return (
        <>
            {
                props.multiForms

                ?

                <>
                    <div className="form-container" style={{  borderBottom: width ? "1px solid var(--gray-four)" : "none" }}>
                        <iframe
                            src={props.pdf}
                            style={{ width: "100%", height: "100%", padding: "0px", borderBottom: width ? "1px solid var(--gray-four)" : "none", minHeight: width ? "600px" : "", marginBottom: width ? "75px" : ""  }}
                            title="PDF Viewer"
                        />
                        <Chevron numForms={props.numForms} updateFormIndex={props.updateFormIndex} formIndex={props.formIndex}/>
                        <ChevronLeft updateFormIndex={props.updateFormIndex} formIndex={props.formIndex}/>

                    </div>
                </>

                :

                <>
                    <iframe
                        src={props.pdf}
                        className="pdf-container-non-multi"
                        style={{ borderBottom: width ? "1px solid var(--gray-four)" : "none", minHeight: width ? "600px" : "", marginBottom: width ? "70px" : "" }}
                        title="PDF Viewer"
                    />
                </>

            }
        </>
    );
};

export default PDF;