import React from "react";
import Instructor from "./instructor";

const InstructorInfo = (props) => {
    return (
        <>
            <div className="instructor-name" style={{ cursor: "pointer" }} onClick={() => {
                props.updateInstructor(props.instructor, props.email);
                props.updateRemove(true);
            }}>
                <Instructor /> { props.instructor }
            </div>
        </>
    );
}

export default InstructorInfo;