import React from "react";
import Star from "./star";

const Primary = () => {
    return (
        <>
            <div className="primary-button-container">
                <Star />
                Primary Address
            </div>
        </>
    );
}

export default Primary;