import React, { useEffect, useState } from "react";
import CourseInput from "./courseInput";
import SaveChanges from "./saveChanges";
import CourseSelect from "./courseSelect";
import WhiteLoading from "./whiteLoading";
import MetricSelect from "./metricSelect";
import moment from "moment-timezone";
import { useParams } from "react-router-dom";
import Info from "./info";

const ChangeExtraTimeForm = (props) => {
    const [text, setText] = useState(props.assessments.length > 0 ? "Enter new extensions below" : "Create an evaluation before adding extensions")
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    function checkAssessmentCode(assessmentCode) {
        for (const student of props.students) {
            if (student.email === props.email) {
                for (const key in student) {
                    if (key === assessmentCode) {
                        return {
                            extraTime: student[key]['extraTime'].split(" ")[0], 
                            metric: student[key]['extraTime'].split(" ")[1],
                            startTime: student[key]['startTime'],
                            endTime: student[key]['endTime']
                        };
                    }
                }
                return 0; // Return 0 if assessment code is not found
            }
        }
        return 0; // Return 0 if email does not match
    }

    function updateMetric(metric) {
        setForm(prevFrom => ({
            ...prevFrom,
            metric: metric
        }));
    }

    const findMetric = () => {
        const object = checkAssessmentCode(props.assessments.length > 0 ? props.assessments[0].assessmentCode : "");
        if (object !== 0) {
            return object.metric
        } else {
            return "minutes"
        }
    }

    const findExtraTime = () => {
        const object = checkAssessmentCode(props.assessments.length > 0 ? props.assessments[0].assessmentCode : "");
        if (object !== 0 && "extraTime" in object && object.extraTime) {
            return object.extraTime
        } else {
            return 0
        }
    }
    
    const findStartTime = () => {
        const object = checkAssessmentCode(props.assessments.length > 0 ? props.assessments[0].assessmentCode : "");
        if (object !== 0 && "startTime" in object && object.startTime) {
            return moment.utc(object.startTime).local().format('YYYY-MM-DDTHH:mm')
        } else {
            return ""
        }
    }

    const findEndTime = () => {
        const object = checkAssessmentCode(props.assessments.length > 0 ? props.assessments[0].assessmentCode : "");
        console.log(object)
        if (object !== 0 && "endTime" in object && object.endTime) {
            return moment.utc(object.endTime).local().format('YYYY-MM-DDTHH:mm')
        } else {
            return ""
        }
    }

    const { courseCode } = useParams();
    const [form, setForm] = useState({
        courseCode: courseCode,
        assessmentCode: props.assessments.length > 0 ? props.assessments[0].assessmentCode : "",
        email: props.email,
        extraTime: String(findExtraTime()),
        metric: findMetric(),
        start: findStartTime(),
        end: findEndTime()
    });

    console.log(form)

    function updateAssessmentCode(assessmentCode) {
        setForm(prevFrom => ({
            ...prevFrom,
            assessmentCode: assessmentCode,
            extraTime: findExtraTime()
        }));
    }

    function updateExtraTime(extraTime) {
        setForm(prevFrom => ({
            ...prevFrom,
            extraTime: extraTime
        }))
    }

    const resetForm = () => {
        props.updateAddTime(false);
    }

    const submitForm = async (e) => {
        e.preventDefault();

        // form validation
        if (form.metric !== "minutes" && form.metric !== "hours" && form.metric !== "days") {
            console.log(form.metric)
            setText("Invalid metric");
            return;
        }

        // forms
        if (props.assessments.length <= 0) {
            setText("Create an evaluation first please");
            return;
        }

        // check extraTime
        const trimmedStr = form.extraTime.toString().trim();
        if (isNaN(trimmedStr) || trimmedStr === '') {
            setText("Invalid extension quantity")
            return;
        }

        if (!form.start && form.end) {
            setText("Remove end time or add a start time");
            return;
        }

        if (form.start && !form.end) {
            setText("Remove start time or add an end time");
            return;
        }

        const url = process.env.REACT_APP_COURSE_API_URL + "/extra-time";
        
        const urlOptions = {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                courseCode: form.courseCode,
                assessmentCode: form.assessmentCode,
                email: form.email,
                extraTime: form.extraTime,
                metric: form.metric,
                start: form.start ? new Date(form.start).toISOString() : "",
                end: form.end ? new Date(form.end).toISOString() : ""
            })
        };

        try {
            setRequestLifeCycle(true)
            const res = await fetch(url, urlOptions);
            const data = await res.json();
            console.log(data);
            if (res.status === 200) {
                setText("Time settings updated for student")
                props.updateAddTime(false);
                window.location.reload();
            } else if (res.status === 401) {
                setText("Authentication error")
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            } else if (res.status === 409) {
                setText("Invalid extension metric");
            } else {
                setText("An error occurred")
            }

        } catch (error) {
            setText("An error occurred")
            console.log(error);
        } finally {
            setRequestLifeCycle(false)
        }
    }

    useEffect(() => {
        setText(props.assessments.length > 0 ? "Enter new extensions below" : "Create an evaluation before adding extensions")
    }, [form])

    useEffect(() => {
        if (form.start && form.end) {
          const start = moment(form.start);
          const end = moment(form.end);
          
          if (start.isAfter(end)) {
            setForm(prevForm => ({
              ...prevForm,
              end: ""
            }));
          }
        }
      }, [form.start]);
    
    useEffect(() => {
        if (form.start && form.end) {
            const start = moment(form.start);
            const end = moment(form.end);
            
            if (end.isBefore(start)) {
            setForm(prevForm => ({
                ...prevForm,
                start: ""
            }));
            }
        }
    }, [form.end]);

    function updateStart(start) {
        setForm(prevForm => ({
            ...prevForm,
            start: start,
        }))
        console.log(form);
    }

    function updateEnd(end) {
        setForm(prevForm => ({
            ...prevForm,
            end: end,
        }))
        console.log(form);
    }

    console.log(form)

    return (
        <>
            <div className="black-back" onClick={ () => {props.updateAddTime(false)} }>

            </div>
            <div className="add-a-course-form-container">
                <div className="add-a-course-form-content-container">
                    <form className="form-width">
                        <div className="add-course-title">
                            Change Time Settings
                        </div>
                        <div className="account-settings-content">
                            <div className="required-container">
                                Enter extension or custom schedule
                            </div>   
                            <div className="banner">
                                {
                                    requestLifeCycle 

                                    ?
                                    
                                    <>
                                        <WhiteLoading />
                                    </>

                                    :

                                    <div className="banner-info">
                                        <Info />
                                        { text }
                                    </div>

                                }
                            </div>
                            {
                                props.assessments.length > 0

                                ?

                                <CourseSelect top={"Choose Evaluation"} assessments={props.assessments} value={form.assessmentCode} updateValue={updateAssessmentCode}/>

                                :

                                <></>
                            }                        
                            <div className="points-viewing-container" style={{ width: "100%" }}>
                                <div style={{ width: "45%" }}>
                                    <CourseInput top={props.name + "'s Extension"} value={form.extraTime} updateValue={updateExtraTime} type={"number"} required={true} autoComplete={"off"} min={0}/>
                                </div>
                                <div style={{ width: "45%" }}>
                                    <MetricSelect top={"Metric"} value={form.metric} updateValue={updateMetric} metrics={
                                        [
                                            {
                                                name: "Minutes",
                                                value: "minutes"
                                            },
                                            {
                                                name: "Hours",
                                                value: "hours"
                                            },
                                            {
                                                name: "Days",
                                                value: "days"
                                            }
                                    ]
                                        
                                    } type={"number"} required={true} autoComplete={"off"} min={0}/>
                                </div>
                            </div>
                            <CourseInput value={form.start} updateValue={updateStart} top={"Available From"} type={"datetime-local"} min={new Date().toISOString().slice(0, 16)} required={true} autoComplete={"off"} placeholder={"Select date and time"}/>
                            <CourseInput value={form.end} updateValue={updateEnd} top={"Available Until"} type={"datetime-local"} min={new Date().toISOString().slice(0, 16)} required={true} autoComplete={"off"} placeholder={"Select date and time"}/>  
                            <SaveChanges left="Cancel" right={{ small : "Update Time", large : "Update Time" }} resetForm={resetForm} submitForm={submitForm}/> 
                        </div>
                    </form> 
                </div>
            </div>
        </>
    );
}

export default ChangeExtraTimeForm;