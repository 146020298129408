import React from "react";
import Circle from "./circle";
import TrashOption from "./trashOption";

const CircleWithTextEdit = ({ text, question, correct, updateIndex, index, updateQuestion, blur }) => {
    const handleClick = () => {
        const newOptions = question.options
            .filter((option) => option.index !== index)
        updateQuestion("options", newOptions);

        if (correct) {
            updateQuestion("answerIndex", -1);
        }
    }

    const handleChange = (newValue) => {
        // Find the option in the list by matching the index
        const newOptions = question.options.map((opt) => {
            // Update the option where the index matches the current index
            if (opt.index === index) {
                return { ...opt, option: newValue }; // Keep the index, update the option value
            }
            return opt; // Return other options unchanged
        });
    
        updateQuestion("options", newOptions);
        console.log("new options", newOptions);
    };     

    return (
        <>
            <div className="edit-select">
                <div className="square-with-text-view" style={{ width: "100%", paddingRight: "10px" }}>
                    <Circle text={text} index={index} correct={correct} updateIndex={updateIndex}/>
                    <input onBlur={blur} className="select-edit" placeholder="Enter option here" type="text" value={text} onChange={(e) => {
                        handleChange(e.target.value);
                    }}/>
                </div>
                <div onClick={handleClick}>
                    <TrashOption />
                </div>
            </div>
        </>
    );
}

export default CircleWithTextEdit;