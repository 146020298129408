import React, { useState, useEffect } from "react";
import FileEditor from "./fileEditor";
import FileTreeVisualizer from "./fileTreeVisualizer";
import { useParams } from "react-router-dom";
import Loading from "./loading";
import useWindowWidth from "./useWindowWidth";
import parseZipFile from "./parseZipFile";
import fetchWithPreSigned from "./fetchWithPreSigned";

const ZipFileViewer = () => {
    const { courseCode, assessmentCode } = useParams();
    const [contents, setContents] = useState([]);
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const [currentFilename, setCurrentFilename] = useState("");
    const [currentFileData, setCurrentFileData] = useState("");
    const [blob, setBlob] = useState();
    const [isDarkTheme, setIsDarkTheme] = useState(false);
    const width = useWindowWidth(1000);

    useEffect(() => {
        const fetchZip = async () => {
            try {
                setRequestLifeCycle(true)
                const res = await fetch( process.env.REACT_APP_EVALUATION_API_URL + `/get-assessment-zip/${courseCode}/${assessmentCode}`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await res.json()
                if (res.status === 200) {
                    const [status, blob] = await fetchWithPreSigned(data.url); // Get the ZIP as a blob
                    
                    if (status !== 200) {
                        return;
                    }
                    setBlob(blob);
                    const contents = await parseZipFile(blob);
                    setContents(contents);
                } else if (res.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                }
            } catch (error) {
                console.log(error);
            } finally {
                setRequestLifeCycle(false); 
            }
        };
        
        fetchZip();          

    }, [])

    useEffect(() => {
        const fetchTheme = async () => {
            try {
                const response = await fetch( process.env.REACT_APP_SUBMISSION_API_URL + `/get-theme`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await response.json();
                if (response.status === 200) {
                    setIsDarkTheme(data.isDarkTheme);
                } else if (response.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                }
            } catch (error) {
                console.log(error);
            }
        };
        
        fetchTheme();
    }, [])

    function updateCurrentFilename(filename) {
        setCurrentFilename(filename);
    }

    function updateCurrentFileData(data) {
        setCurrentFileData(data);
    }

    function updateIsDarkTheme(theme) {
        setIsDarkTheme(theme);
        submitForm(theme);
    }

    const submitForm = async (theme) => {
        try {
            const url = process.env.REACT_APP_SUBMISSION_API_URL + "/update-theme";
            const urlOptions = {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    isDarkTheme: theme
                })
            }

            const response = await fetch(url, urlOptions);
            const data = response.json();
            console.log(data);
            if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL
            }

        } catch(error) {
            console.log(error);
        }
    }

    console.log(currentFilename)

    return (
        <>
            <div className={ `pdf-content-container  ${ width ? "small" : "" }` } style={{ minHeight: width ? "800px" : "" }}>
                <div className="editor-tree-container" style={{ flexDirection: width ? "column" : "", color: isDarkTheme? "var(--vs-icon)" : "var(--almost-black)", backgroundColor: isDarkTheme ? "var(--dark-background)" : "var(--gray-three)" }}>
                    {
                        requestLifeCycle

                        ?

                        <div className="loading-zip-container" style={{ color: "var(--almost-black)" }}>
                            <Loading />
                        </div>

                        :

                        <>
                            <FileTreeVisualizer contents={contents} updateCurrentFilename={updateCurrentFilename} updateCurrentFileData={updateCurrentFileData}/>
                            <FileEditor isDarkTheme={isDarkTheme} blob={blob} currentFileData={currentFileData} currentFilename={currentFilename} updateIsDarkTheme={updateIsDarkTheme}/>
                        </>

                    }
                </div>
            </div>
        </>
    );
}

export default ZipFileViewer;