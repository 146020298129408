import React, { useState, useEffect } from "react";
import TrashQuestion from "./trashQuestion";
import Grid from "./grid";
import SquareWithTextEdit from "./squareWithTextEdit";
import AddOption from "./addOption";
import { useParams } from "react-router-dom";
import saveFields from "./saveFields";

const SelectAllThatApplyEdit = ({ questionsToSave, showDrag, updateQuestionsToSave, shuffle, provided, updateDeleteQuestion, index, points, question, uuid, questionType, options, answerArray, bankUuid }) => {
    const { courseCode, assessmentCode } = useParams();
    const [form, setForm] = useState({
        question: question,
        points: Number(points).toFixed(2),
        uuid: uuid,
        questionType: questionType,
        options: options,
        answerArray: answerArray,
        shuffle: shuffle
    });

    function updateForm(key, value) {
        setForm((prev) => (
            {
                ...prev,
                [key]: value
            }
        ))
        console.log(form)
    }

    function updateIndex(newIndex) {
        if (form.answerArray.includes(newIndex)) {
            updateForm("answerArray", form.answerArray.filter(index => index !== newIndex))
        } else {
            updateForm("answerArray", [...form.answerArray, newIndex]); // Add newIndex
        }
    }

    const submitForm = async () => {
        // construct a new body for post with all values that have changed
        const body = {};
        body.uuid = uuid
        body.questionType = questionType
        body.fields = {}

        // Check each property for changes
        Object.keys(form).forEach(key => {
            if (form[key] !== { shuffle, points, question, uuid, question, options, answerArray, questionType }[key]) {
                if (key !== "uuid" || key !== "questionType") {
                    if (key === "points") {
                        if (form.points !== Number(points).toFixed(2)) {
                            body.fields[key] = form[key];
                        }
                    } else {
                        body.fields[key] = form[key];
                    }
                }
            }
        });

        if (Object.keys(body.fields).length === 0) {
            return;
        }

        // updateQuestionsToSave
        updateQuestionsToSave((prev) => ({
            ...prev,
            [form.uuid]: body
        }))
    };

    useEffect(() => {
        // Define a timeout to submit the form after 0.5s
        const timer = setTimeout(() => {
            submitForm();
        }, 500); // 500ms delay
    
        // Cleanup function to clear the timeout if dependencies change
        return () => {
            clearTimeout(timer);
        };
    }, [form.points, form.question]);

    useEffect(() => {
        submitForm();
    }, [form.answerArray, form.options, form.shuffle])

    useEffect(() => {
        if (uuid in questionsToSave) {
            const savedFields = questionsToSave[uuid].fields;
            if (savedFields) {
                // Iterate over savedFields and update the form with the key-value pairs
                saveFields(form, savedFields, updateForm);
            }
        }
    }, [questionsToSave, uuid]);
    
    return (
        <>
            <div className="true-false-question-view">
                <div className="question-number">
                    <div className="grid-number-container">
                        {
                            showDrag

                            ?

                            <>
                                <Grid provided={provided}/>
                            </>

                            :

                            <>
                            </>

                        }
                        Q{ index }
                    </div>
                    <div className="pts">
                        <>
                            <input onBlur={submitForm} className="grading-box-question" type="none" value={form.points} onChange={(e) => {
                                updateForm("points", e.target.value);
                            }}/>
                            pts
                        </>
                        <TrashQuestion uuid={uuid} updateDeleteQuestion={updateDeleteQuestion} bankUuid={bankUuid}/>
                    </div>
                </div>
                <div className="text-response-area">
                    <textarea onBlur={submitForm} className="text-response-area-input" placeholder="Enter question" style={{ minHeight: "100px" }} value={form.question} onChange={(e) => {
                        updateForm("question", e.target.value);
                    }}/>
                </div>
                <div className="select-all-that-apply-array">
                    {
                        form && form.options.length > 0

                        ?

                        form.options.map((option) => {
                            return <SquareWithTextEdit blur={submitForm} key={option.index} correct={form.answerArray.includes(option.index)} index={option.index} text={option.option} updateIndex={updateIndex} updateQuestion={updateForm} question={form}/>
                        })

                        :

                        <>
                        </>
                    }
                    <AddOption updateQuestion={updateForm} options={form.options}/>
                    <div className="message-container" style={{ alignItems: "center" }}>
                        Answer Shuffling:
                        <input className="check-auto" onBlur={submitForm} type="checkbox" checked={form.shuffle} onChange={(e) => updateForm("shuffle", e.target.checked)} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default SelectAllThatApplyEdit;