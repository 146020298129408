import React from "react";
import SearchIcon from "./searchIcon";

const Search = (props) => {
    return (
        <>
            <div className="search-container">
                <input value={props.searchName} onChange={props.updateSearchName} className="search-input" placeholder="Search"/>   
                <SearchIcon />
            </div>
        </>
    );
}

export default Search;