import React, { useState } from "react";

const AddCourseDescription = (props) => {
    const illegalCharacters = [
        "\\",
        '"',
    ]

    const handleChange = (e) => {
        const inputValue = e.target.value;

        if (!illegalCharacters.some(char => inputValue.includes(char))) {
            props.updateValue(inputValue);
        }
    }

    return (
        <>
            <div className="input-field" style={{ height: "auto" }}>
            <div className="top-input">
                    { props.top }
                </div>
                <textarea className="bottom-input-2" type={ "text" } value={ props.value } required={ props.required } onChange={ handleChange } autoComplete={ "off" } placeholder={ "" } />
            </div>
        </>
    );
}

export default AddCourseDescription;