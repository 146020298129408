import React, { useState, useEffect } from "react";
import FeedbackRow from "./feedbackRow";
import { useParams } from "react-router-dom";
import Loading from "./loading";

const FeedbackDropdown = (props) => {
    const { courseCode, assessmentCode } = useParams();
    const [feedbackList, setFeedbackList] = useState([]);

    useEffect(() => {
        const fetchFeedBackList = async () => {
            try {
                const response = await fetch( process.env.REACT_APP_SUBMISSION_API_URL + `/get-feedback-list/${courseCode}/${assessmentCode}`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await response.json();
                if (response.status === 200) {
                    setFeedbackList(data.feedbackList);
                    console.log(data);
                } else if (response.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                } else {
                    console.log("An error occurred");
                }

            } catch (error) {
                console.log(error);
            }
        }

        if (props.requestLifeCycle) {
            fetchFeedBackList();
            props.updateRequestLifeCycle(false);
        }
        
    }, [props.requestLifeCycle])
    

    return (
        <>
            {((props.isHovering && feedbackList.length > 0) || props.requestLifeCycle) && (
                <div className="names-dropdown" style={{ border: "1px solid var(--gray-four)", overflow: "auto" }}>
                    {
                        props.requestLifeCycle

                        ?

                        <div className="feedback-loading">
                            <Loading />
                        </div>

                        :
                        
                        <>
                            {feedbackList.map((item, index) => {
                                return <FeedbackRow updateFeedbackCode={props.updateFeedbackCode} updateFeedback={props.updateFeedback} key={item.feedbackCode} feedbackCode={item.feedbackCode} feedback={item.feedback} index={index}/>
                            })}
                        </>
                    }
                </div>
            )}
        </>
    );
};

export default FeedbackDropdown;