import StatusCircle from "./statusCircle"
import Clock from "./clock"

const HandleStatus = (status) => {
    if (status === "In Progress") {
        return <StatusCircle color={"rgba(11, 115, 254, 255)"}/>
    } else if (status === "Upcoming") {
        return <Clock />
    } else if (status === "Submitted") {
        return <StatusCircle color={"rgba(1, 164, 33, 255)"}/>
    } else {
        return <StatusCircle color={"rgb(60,60,60)"}/>
    }
}

export default HandleStatus;