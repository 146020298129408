import React from "react";
import useWindowWidth from "./useWindowWidth";

const Clock = () => {
    const width = useWindowWidth(500);

    return (
        <>
            <div style={{ paddingRight: width ? "5px" : "10px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                    <path fill="rgb(60,60,60)" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
                </svg>
            </div>
        </>
    );
}

export default Clock;