import React from "react";

const MetricSelect = (props) => {
    const handleChange = (e) => {
        props.updateValue(e.target.value)
    }

    return (
        <>
            <div className="input-field">
                <div className="top-input">
                    { props.top }
                    {
                        props.search || props.star

                        ?

                        <span className="required-star">
                            *
                        </span>

                        :

                        <>
                        </>
                    }
                </div>
                <select className="bottom-input metric-select" required={ props.required } value={ props.value } onChange={ handleChange }>
                    {
                        props.metrics.map((metric) => {
                            return <option key={metric.name} value={metric.value}>
                                        { metric.name }
                                    </option>
                                    
                        })
                    }
                </select>
            </div>
        </>
    );
}

export default MetricSelect;