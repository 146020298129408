import React from "react";

const WhiteLoading = () => {
    return (
        <>  
            <div className="loading-dots">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
            </div>
        </>
    );
}

export default WhiteLoading;