import React from "react";

const SelectZipFile = (props) => {
    const handleChange = (e) => {
        const zip = e.target.files[0];
        props.updateValue(zip);
    }

    return (
        <>
            <div className="input-field" style={{ height: "auto" }}>
                <div className="top-input">
                    { props.top } 
                    {
                        props.star

                        ?

                        <span className="required-star">
                            *
                        </span>

                        :

                        <>
                        </>
                    }
                </div>
                <div className="pdf-upload-container" style={{ height: "30px" }}>
                    <input type="file" className="pdf-choose" onChange={handleChange} required={ props.required } accept="application/zip"/>
                </div>
            </div>
        </>
    );
}

export default SelectZipFile;