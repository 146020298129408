import React from "react";

const PaintBrush = (props) => {
    const handleDragStart = (e, color) => {
        e.dataTransfer.setData("color", color);
    }

    return (
        <>
            <div className="paint-brush" draggable="true" onDragStart={ (e) => {handleDragStart(e, props.color)}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 576 512">
                    <path fill={ props.color } d="M339.3 367.1c27.3-3.9 51.9-19.4 67.2-42.9L568.2 74.1c12.6-19.5 9.4-45.3-7.6-61.2S517.7-4.4 499.1 9.6L262.4 187.2c-24 18-38.2 46.1-38.4 76.1L339.3 367.1zm-19.6 25.4l-116-104.4C143.9 290.3 96 339.6 96 400c0 3.9 .2 7.8 .6 11.6C98.4 429.1 86.4 448 68.8 448H64c-17.7 0-32 14.3-32 32s14.3 32 32 32H208c61.9 0 112-50.1 112-112c0-2.5-.1-5-.2-7.5z"/>
                </svg>
            </div>
        </>
    );
}

export default PaintBrush;