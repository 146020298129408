import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DownChevron from "./downChevron";
import FeedbackDropdown from "./feedbackDropdown";

const FeedbackWindow = (props) => {
    const { courseCode, assessmentCode, email } = useParams();
    const [proppedFeeback, setProppedFeedback] = useState(props.feedback);
    const [feedback, setFeedback] = useState(props.feedback);
    const [feedbackCode, setFeedbackCode] = useState(props.feedbackCode);
    const [isHovering, setIsHovering] = useState(false);
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const illegalCharacters = [
        "\\",
        '"',
    ]

    const handleChange = (e) => {
        const inputValue = e.target.value;

        if (!illegalCharacters.some(char => inputValue.includes(char)) && inputValue.length < 2001) {
            updateFeedback(inputValue)
        }
    }

    function updateFeedback(feedback) {
        setFeedback(feedback);
    }

    function updateFeedbackCode(code){
        setFeedbackCode(code)
    }

    const submitForm = async () => {
        if (feedback === proppedFeeback) {
            return;
        }

        try {
            const url = process.env.REACT_APP_SUBMISSION_API_URL + `/update-feedback/${courseCode}/${assessmentCode}/${email}`
            const urlOptions = {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    feedback: feedback,
                    feedbackCode: feedbackCode
                })
            }
            
            const response = await fetch(url, urlOptions);
            const data = await response.json();
            console.log(data);
            if (response.status === 200) {
                setProppedFeedback(feedback)
            }
            else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL
            }

        } catch (error) {
            console.log(error);
        }
    }

    function updateRequestLifeCycle(bool) {
        setRequestLifeCycle(bool);
    }

    const handleBlur = () => {
        props.handleBlur();
        submitForm();
    }

    return (
        <>            
            <div className="text-side-nav display-2" style={{ width: "100%", position: "relative" }}>
                <div className="metric-select feedback" onMouseEnter={() => {
                        setIsHovering(true);
                    }} onMouseLeave={() => {
                        setIsHovering(false);
                    }} onClick={() => {
                        updateRequestLifeCycle(true);
                    }}>
                    Previous Feedback <DownChevron />
                </div>
                <div onMouseEnter={() => {
                        setIsHovering(true);
                    }} onMouseLeave={() => {
                        setIsHovering(false);
                    }}>          
                    <FeedbackDropdown updateFeedbackCode={updateFeedbackCode} updateRequestLifeCycle={updateRequestLifeCycle} requestLifeCycle={requestLifeCycle} isHovering={isHovering} feedback={feedback} updateFeedback={updateFeedback}/>          
                </div>
            </div>
            <div className="remaining">
                <textarea className="feedback-window-container" onFocus={props.handleFocus} onBlur={handleBlur} value={feedback} onChange={handleChange}/>
                <div className="remaining-bar" style={{ right: "0px" }}>
                    { feedback !== "" && feedback !== undefined ? 2000 - feedback.length : 2000 } characters remaining
                </div>
            </div>
        </>
    );
}

export default FeedbackWindow;