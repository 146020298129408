import React, { useState, useEffect } from "react";
import GradingBoxQuestion from "./gradingBoxQuestion";
import MarkdownWithLaTeX from "./markDownWithLatex";

const TextResponseView = ({ questionsToSave, questionNumber, question, points, grading, pointsEarned, uuid, studentAnswer }) => {
    const [answer, setAnswer] = useState(studentAnswer);
    const [form, setForm] = useState({
        points: points,
        question: question
    })

    function updateAnswer(e) {
        setAnswer(e.target.value);
    }
    
    function updateForm(key, value) {
        setForm((prev) => (
            {
                ...prev,
                [key]: value
            }
        ))
    }

    useEffect(() => {
        if (uuid in questionsToSave) {
            const savedFields = questionsToSave[uuid].fields;

            if (savedFields) {
                // Update the local state for points and question
                if (savedFields.points && savedFields.points !== points) {
                    updateForm("points", savedFields.points)
                }
                if (savedFields.question && savedFields.question !== question) {
                    updateForm("question", savedFields.question)
                }
            }
        }
    }, [questionsToSave, points, question]);

    return (
        <>
            <div className="true-false-question-view">
                <div className="question-number">
                    <>
                        Q{ questionNumber }
                    </>
                    <div className="pts">
                        {
                            grading

                            ?

                            <>
                                <GradingBoxQuestion pointsEarned={pointsEarned} uuid={uuid}/>
                            </>

                            :

                            <>
                            </>
                        }
                        { (grading ? "/ " : "") + Number(form.points).toFixed(2) } pts
                    </div>
                </div>
                <div className="true-false-top">
                    <MarkdownWithLaTeX content={form.question} />
                </div>
                <div className="text-response-area">
                    <textarea className="text-response-area-input" value={answer} onChange={updateAnswer}/>
                </div>
            </div>
        </>
    );
}

export default TextResponseView;