import React from "react";

const CustomSwitch = (props) => {
    return (
        <>
            <div className="form-check form-switch" style={{ cursor: "pointer" }}>
                <input style={{ cursor: "pointer" }} className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={props.checked} onChange={props.updateChecked}/>
            </div>
        </>
    );
}

export default CustomSwitch;