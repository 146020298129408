import React, { useState, useEffect } from "react";
import CustomExamWrapperView from "./customExamWrapperView";
import CustomExamWrapperEdit from "./customExamWrapperEdit";
import AddAQuestionForm from "./addAQuestionForm";
import WhiteLoading from "./whiteLoading";
import parseZipFile from "./parseZipFile";
import parseFileStructure from "./parseFileStructureToReturnPaths";
import { useParams } from "react-router-dom";
import InitSortQuestions from "./initSortQuestions";
import { MathJaxContext } from "better-react-mathjax";
import mathJaxConfig from "./mathJaxConfig";
import fetchWithPreSigned from "./fetchWithPreSigned";

const CustomProgrammingExam = ({ questionsToSave, updateQuestionsToSave, isEditing, addAQuestion, updateIsEditing, updateAddAQuestion, updateDeleteQuestion, useSyntaxHighlighting, zipFileExists, updateQuestionsParent, fetchData, updateFetchData }) => {
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [contents, setContents] = useState({});
    const { courseCode, assessmentCode } = useParams();
    const [testsConfigDict, setTestsConfigDict] = useState({});
    const [ignoredPaths, setIgnoredPaths] = useState([]);

    const fetchCustomExam = async () => {
        try {
            setRequestLifeCycle(true);
            const response = await fetch( process.env.REACT_APP_EVALUATION_API_URL + `/get-custom-exam/${courseCode}/${assessmentCode}`, {
                method: "GET",
                credentials: "include"
            });
            const data = await response.json();
            if (response.status === 200) {
                const questionsInOrder = InitSortQuestions(data.questionsOrder, data.questions);
                setQuestions(questionsInOrder);
                updateQuestionsParent(questionsInOrder);
                updateFetchData(false);
            } else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            }
        } catch (error) {
            console.log(error);
        } finally {
            setRequestLifeCycle(false);
        }
    }

    useEffect(() => {
        if (fetchData) {
            fetchCustomExam();
        }
    }, [fetchData])

    useEffect(() => {
        fetchCustomExam();
    }, [])

    function updateQuestions(questions) {
        setQuestions(questions);
        updateQuestionsParent(questions);
    }

    useEffect(() => {
        const fetchZip = async () => {
            try {
                const res = await fetch( process.env.REACT_APP_EVALUATION_API_URL + `/get-assessment-zip/${courseCode}/${assessmentCode}`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await res.json();
                if (res.status === 200) {
                    const [status, blob] = await fetchWithPreSigned(data.url); // Get the ZIP as a blob
                    
                    if (status !== 200) {
                        return;
                    }

                    const contents = parseFileStructure(await parseZipFile(blob));
                    setContents(contents);
                    const testsConfig = "tests_config.json" in contents ? JSON.parse(contents["tests_config.json"].content) : {}
                    setTestsConfigDict(testsConfig?.tests.reduce((acc, curr) => {
                        acc[curr.id] = curr;
                        return acc;
                      }, {}));
                    setIgnoredPaths(testsConfig?.ignore.reduce((acc, path) => {
                        acc[path] = null;
                        return acc;
                    }, {}))
                } else if (res.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                }
            } catch (error) {
                console.log(error);
            }
        };
        
        if (zipFileExists) {
            fetchZip();  
        }        

    }, [zipFileExists])

    console.log(questionsToSave)

    return (
        <>
            {
                requestLifeCycle

                ?

                <div className="loading-zip-container">
                    <WhiteLoading />
                </div>

                :

                    <div className="custom-programming-exam-wrapper" style={{ position: "relative" }}>
                        {
                            isEditing
        
                            ?
        
                            <>
                                <CustomExamWrapperEdit questionsToSave={questionsToSave} ignoredPaths={ignoredPaths} testsConfigDict={testsConfigDict} updateFetchData={updateFetchData} updateQuestionsToSave={updateQuestionsToSave} contents={contents} updateQuestions={updateQuestions} zipFileExists={zipFileExists} useSyntaxHighlighting={useSyntaxHighlighting} questions={questions} updateIsEditing={updateIsEditing} updateAddAQuestion={updateAddAQuestion} updateDeleteQuestion={updateDeleteQuestion}/>
                            </>
        
                            :
        
                            <>
                                <MathJaxContext config={mathJaxConfig}>
                                    <CustomExamWrapperView questionsToSave={questionsToSave} useSyntaxHighlighting={useSyntaxHighlighting} contents={contents} questions={questions} updateIsEditing={updateIsEditing} updateAddAQuestion={updateAddAQuestion} grading={false}/>
                                </MathJaxContext>
                            </>
                        }
                    </div>                         

            }
            { addAQuestion ? <AddAQuestionForm updateQuestions={updateQuestions} updateQuestionsToSave={updateQuestionsToSave} updateAddAQuestion={updateAddAQuestion} questions={questions}/> : <></> }
        </>
    );
}

export default CustomProgrammingExam;