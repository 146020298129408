import React, { useState, useEffect } from "react";
import MarkdownWithLaTeX from "./markDownWithLatex";

const ContextBlockView = ({ questionsToSave, questionNumber, question, points, uuid }) => {
    const [form, setForm] = useState({
        points: points,
        question: question
    })

    function updateForm(key, value) {
        setForm((prev) => (
            {
                ...prev,
                [key]: value
            }
        ))
    }

    useEffect(() => {
        if (uuid in questionsToSave) {
            const savedFields = questionsToSave[uuid].fields;
            
            if (savedFields) {
                // Update the local state for points and question
                if (savedFields.points && savedFields.points !== points) {
                    updateForm("points", savedFields.points)
                }
                if (savedFields.question && savedFields.question !== question) {
                    updateForm("question", savedFields.question)
                }
            }
        }
    }, [questionsToSave, points, question]);

    return (
        <>
            <div className="true-false-question-view">
                <div className="question-number">
                    <>
                        C{ questionNumber }
                    </>
                    <div className="pts">
                        { form.points } pts
                    </div>
                </div>
                <div className="true-false-top">
                    <MarkdownWithLaTeX content={form.question} />
                </div>
            </div>
        </>
    );
}

export default ContextBlockView;