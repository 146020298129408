import React from "react";
import SearchIcon from "./searchIcon";

const CourseInput = (props) => {
    const illegalCharacters = [
        "\\",
        '"',
    ]

    const handleChange = (e) => {
        const inputValue = e.target.value;

        if (!illegalCharacters.some(char => inputValue.includes(char))) {
            props.updateValue(inputValue)
        }
    }

    return (
        <>
            <div className="input-field">
                <div className="top-input">
                    { props.top }
                    {
                        props.search || props.star

                        ?

                        <span className="required-star">
                            *
                        </span>

                        :

                        <>
                        </>
                    }
                </div>
                <div style={{ position: "relative", width: "100%" }}>
                    <input style={{ width: props.width ? "auto" : "100%", paddingLeft: props.search ? "15px" : "0px" }} className="bottom-input" type={ props.type } min={ props.min } max={ props.max } value={ props.value } onChange={ handleChange } required={ props.required } autoComplete={ props.autoComplete } placeholder={ props.placeholder } />
                    {
                        props.search 

                        ?

                        <>
                            <div style={{ position: "absolute", bottom: "-3px", color: "rgb(108, 117, 125)" }}>
                                <SearchIcon />
                            </div>
                        </>

                        :

                        <>
                        </>
                    }
                </div>
            </div>
        </>
    );
}

export default CourseInput;