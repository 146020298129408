import React from "react";

const Bubble = (props) => {
    const handleClick = () => {
        if (props.text === "True") {
            props.updateBubbled(1)
        } else {
            props.updateBubbled(0)
        }
    }

    return (
        <>
            <div className={`bubble ${props.bubbled ? "bubbled" : ""}`} onClick={handleClick}>

            </div>
        </>
    );
}

export default Bubble;