import React from "react";

const AddOption = ({ updateQuestion, options }) => {
    const generateUniqueIndex = (existingIndexes) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let uniqueIndex = '';
        const maxRetries = 10; // Set maximum retries
        let attempts = 0; // Initialize attempt counter

        // Function to create a new random 8-character string
        const createRandomString = () => {
            let result = '';
            for (let i = 0; i < 8; i++) {
                result += characters.charAt(Math.floor(Math.random() * characters.length));
            }
            return result;
            };

            do {
                uniqueIndex = createRandomString();
                attempts++;
            } while (existingIndexes.includes(uniqueIndex) && attempts < maxRetries); // Check for uniqueness and limit retries

            // If max retries reached, return a fallback value or handle the error as needed
            if (attempts === maxRetries) {
                console.warn("Max retries reached. Could not generate a unique index.");
                return null; // or return a fallback value
            }

            return uniqueIndex;
        };

    const handleClick = () => {
        const existingIndexes = options.map(option => option.index); // Extract current indexes
        const newUniqueIndex = generateUniqueIndex(existingIndexes); // Generate a unique index

        // Handle the case where a unique index could not be generated
        if (newUniqueIndex === null) {
            // Handle the error as needed (e.g., alert the user or skip adding a new option)
            return;
        }

        const newOptions = [...options, { option: "", index: newUniqueIndex }]; // Add new option with unique index
        updateQuestion("options", newOptions); // Update the question with the new options
    };    

    return (
        <>
            <div className="add-option" onClick={handleClick}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                    <path stroke={"var(--almost-black)"} strokeWidth={"1"} d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
                </svg>
            </div>
        </>
    );
}

export default AddOption;