import React from "react";
import { Tree } from "react-arborist";
import Directory from "./directory";
import DocumentTreeIcon from "./documentTreeIcon";
import useWindowWidth from "./useWindowWidth";
import useResizeObserver from "use-resize-observer";

const FileTreeVisualizer = ({ contents, updateCurrentFilename, updateCurrentFileData }) => {
    const width = useWindowWidth(1000);
    const { refParent, widthParent, heightParent } = useResizeObserver();

    function Node({ node, style }) {
        return (
          <div className={`node ${ node.isLeaf ? "node-hover" : ""}`} style={style} onClick={() => {
            if (node.isLeaf) {
                updateCurrentFilename(node.data.name);
                updateCurrentFileData(node.data.text);
            }
          }}>
            {node.isLeaf ? <DocumentTreeIcon /> : <Directory />}
            {node.data.name}
          </div>
        );
      }
    
    return (
        <>
            <div className="file-visualizer-open" style={{ width: width ? "100%" : "", height: width ? "fit-content" : "" }} ref={refParent}>
                {
                    contents.length > 0

                    ?

                    <Tree initialData={contents} width={widthParent} height={heightParent}>
                        {Node}
                    </Tree>

                    :

                    <>
                    </>
                }
            </div>
        </>
    );
};


export default FileTreeVisualizer;
