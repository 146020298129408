import React from "react";

const ASingleTestGrading = ({ name, id, totalPoints, index, pointsEarned, output }) => {
    return (
        <>
            <div className="if-output-container">
                <div className="a-single-test" style={{ color: pointsEarned >= totalPoints ? "var(--correct)" : "var(--incorrect)", borderRadius: output ? "5px 5px 0px 0px" : "" }}>
                    <div className="left-of-test">
                        { "Q" + index + ": " + name}
                    </div>
                    <div>
                        { Number(pointsEarned).toFixed(2) + "/" + Number(totalPoints).toFixed(2) + " pts"}
                    </div>
                </div>
                {
                    output

                    ?

                    <div className="bottom-output">
                        { output }
                    </div>

                    :

                    <>
                    </>

                }
            </div>
        </>
    );
}

export default ASingleTestGrading;