import React from "react";
import Square from "./square";
import TrashOption from "./trashOption";

const SquareWithTextEdit= ({ text, question, correct, updateIndex, index, updateQuestion, blur }) => {
    const handleClick = () => {
        const newOptions = question.options
            .filter((option) => option.index !== index)
            .map((option) => ({
                ...option, // Spread the existing option object
                index: option.index > index ? option.index - 1 : option.index // Decrement the index if greater
            }));
        updateQuestion("options", newOptions);
        
        const newAnswerArray = question.answerArray
            .slice() // Creates a shallow copy of the array
            .filter((newIndex) => newIndex !== index) // Remove the value if it's equal to index
            .map((newIndex) => (newIndex > index ? newIndex - 1 : newIndex)); // Increment if it's less than index
        

        updateQuestion("answerArray", newAnswerArray)
    }

    const handleChange = (newValue) => {
        // Find the option in the list by matching the index
        const newOptions = question.options.map((opt) => {
            // Update the option where the index matches the current index
            if (opt.index === index) {
                return { ...opt, option: newValue }; // Keep the index, update the option value
            }
            return opt; // Return other options unchanged
        });
    
        updateQuestion("options", newOptions);
        console.log("new options", newOptions);
    };

    return (
        <>
            <div className="edit-select">
                <div className="square-with-text-view" style={{ width: "100%", paddingRight: "10px" }}>
                    <Square text={text} index={index} correct={correct} updateIndex={updateIndex}/>
                    <input onBlur={blur} className="select-edit" placeholder="Enter option here" type="text" value={text} onChange={(e) => {
                        handleChange(e.target.value);
                    }}/>
                </div>
                <div onClick={handleClick}>
                    <TrashOption />
                </div>
            </div>
        </>
    );
}

export default SquareWithTextEdit;