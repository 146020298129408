import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { MathJax } from "better-react-mathjax";

const MarkdownWithLaTeX = ({ content }) => {
    const [containsLaTeX, setContainsLaTeX] = useState(false);

    useEffect(() => {
        // Ensure content is set only after the component mounts
        if (content) {
            setContainsLaTeX(/\$[^$]+\$|\$\$[^$]+\$\$/.test(content));
        }
    }, [content]);

    return (
        <>
            {containsLaTeX ? (
                <MathJax dynamic hideUntilTypeset="every">
                    <ReactMarkdown>{content}</ReactMarkdown>
                </MathJax>
            ) : (
                <ReactMarkdown>{content}</ReactMarkdown>
            )}
        </>
    );
};

export default MarkdownWithLaTeX;
