import React from "react";
import CustomExamWrapperView from "./customExamWrapperView";
import { MathJaxContext } from "better-react-mathjax";
import mathJaxConfig from "./mathJaxConfig";
import OnlyIInfo from "./onlyIInfo";

const GradingPageCustomExamWrapper = ({ contents, gradedQuestions, updateCurrentViewer }) => {

    return (
        <>
            <div className="custom-programming-exam-wrapper" style={{ position: "relative" }}>
                <MathJaxContext config={mathJaxConfig}>
                    <CustomExamWrapperView useSyntaxHighlighting={true} updateCurrentViewer={updateCurrentViewer} contents={contents} questions={gradedQuestions} grading={true}/>
                </MathJaxContext>
                <OnlyIInfo special={true}/>
            </div>
        </>
    );
}

export default GradingPageCustomExamWrapper;