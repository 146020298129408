import React, { useEffect, useState } from "react";
import useWindowWidth from "./useWindowWidth";
import { useParams, useNavigate } from "react-router-dom";
import convertDate from "./convertDate";
import CustomSwitch from "./customSwitch";
import Regenerate from "./regenerate";
import Trash from "./trash";
import intToLetters from "./intToLetters";
import Loading from "./loading";
import handleStatusDate from "./handleStatusDate";
import HandleStatus from "./handleStatus";
import EditAssessmentForm from "./editAssessmentForm";

const LeftInfoSettings = ({ requestLifeCycle, assessmentInformation, updateAssessmentInformationParent, updateDeleteForm    , updateDeleteZip }) => {
    const width = useWindowWidth(850);
    const navigate = useNavigate();
    const { courseCode, assessmentCode } = useParams();
    const [originalAttempts, setOriginalAttempts] = useState(-1);

    useEffect(() => {
        if (assessmentInformation && assessmentInformation.attempts != null) {
            setOriginalAttempts(assessmentInformation.attempts);
        }
    }, [assessmentInformation]);
    
    const submitForm = async () => {
        console.log(originalAttempts)
        if (originalAttempts === assessmentInformation.attempts) {
            return;
        }
        const url = process.env.REACT_APP_EVALUATION_API_URL + "/update-attempts";

        const urlOptions = {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                courseCode: courseCode,
                assessmentCode: assessmentCode,
                attempts: assessmentInformation.attempts
            })
        };
        
        try {
            const res = await fetch(url, urlOptions);
            const data = await res.json();
            if (res.status === 200) {
                setOriginalAttempts(assessmentInformation.attempts)
            } else if (res.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            }
            console.log(data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        // Set a timeout of 0.5 seconds (500 ms) to trigger form submission
        const timer = setTimeout(() => {
            if (assessmentInformation.attempts !== undefined) {
                submitForm();
            }
        }, 500); // 500 ms delay
    
        // Cleanup function to clear the timeout if the component unmounts or dependencies change
        return () => {
            clearTimeout(timer);
        };
    }, [assessmentInformation.attempts]);

    function updateAssessmentInformation(type, value, switchClick = false) {
        updateAssessmentInformationParent((prev) => ({
            ...prev,
            [type]: value
        }))

        if (switchClick) {
            handleSwitchClick(value, type);
        }
    }

    const handleCodeClick = async () => {
        const url = process.env.REACT_APP_EVALUATION_API_URL + `/regenerate-entry-code`

        const urlOptions = {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                courseCode: courseCode,
                entryCode: assessmentInformation.entryCode,
                assessmentCode: assessmentCode
            })
        }

        try {
            const res = await fetch(url, urlOptions);
            const data = await res.json();
            console.log(data);
            if (res.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            }
    
        } catch (error) {
            console.log(error);
        }
    }

    const handleSwitchClick = async (boolVal, updateVal) => {
        const url = process.env.REACT_APP_EVALUATION_API_URL + "/evaluation-item-toggle";

        const urlOptions = {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                boolVal: boolVal,
                updateVal: updateVal,
                courseCode: courseCode,
                start: assessmentInformation.start,
                end: assessmentInformation.end,
                assessmentCode: assessmentCode,
                isProgramming: assessmentInformation.isProgramming,
                duration: assessmentInformation.duration.toString(),
                metric: assessmentInformation.metric
            })
        };

        try {
            const res = await fetch(url, urlOptions);
            const data = await res.json();
            console.log(data);
            if (res.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            }
    
        } catch (error) {
            console.log(error);
        }
    }

    const handleFormClick = (event, formIndex) => {
        const queryParams = new URLSearchParams({
            formIndex
        }).toString();

        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${assessmentCode}/edit?${queryParams}`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${assessmentCode}/edit?${queryParams}`);
        }
    }

    const handleZipClick = (event) => {
        const zip = "ZIP"

        const queryParams = new URLSearchParams({
            zip
        }).toString();

        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${assessmentCode}/edit?${queryParams}`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${assessmentCode}/edit?${queryParams}`);
        }
    }

    const status = handleStatusDate(assessmentInformation.start, assessmentInformation.end, assessmentInformation.timeZone, assessmentInformation.submissionsLength);

    return (
        <>
            <div className="middle-content" style={{ borderLeft: width ? "none" : "", borderRight: width ? "none" : "" }}>
                {
                    requestLifeCycle

                    ?

                    <>
                        <div className="loading-container">
                            <Loading />
                        </div> 
                    </>

                    :

                    <>
                        {
                            assessmentInformation && Object.keys(assessmentInformation).length > 0

                            ?

                            <>
                                <div className="side-nav-info-header" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                    <span className="assessment-name">
                                        {assessmentInformation.name}
                                    </span>
                                </div>
                                <div className="side-nav-info-header">
                                    Status
                                </div>
                                <div className="text-side-nav display-2">
                                    { HandleStatus(status) } { status }
                                </div>
                                <div className="side-nav-info-header">
                                    Schedule
                                </div>
                                <div className="text-side-nav display">
                                    <div> { "Duration: " + (assessmentInformation.duration === -1 ? "Full-time" : assessmentInformation.duration + " " + assessmentInformation.metric) } </div>
                                    <div> { "Start: " + convertDate(assessmentInformation.start, false) } </div>
                                    <div> { "End: " + convertDate(assessmentInformation.end, false) } </div>
                                </div>
                                {
                                    assessmentInformation.formIds.length > 0 

                                    ?

                                    <>
                                        <div className="side-nav-info-header" style={{ display: "flex", justifyContent: "space-between", columnGap: "10px", width: "100%", alignItems: "center" }}>
                                            PDF Forms
                                            <Trash updateDeleteForm={updateDeleteForm}/>
                                        </div>
                                    </>

                                    :

                                    <>
                                    </>

                                }
                                <div className="text-side-nav display" style={{ marginTop: "-14px" }}>
                                    {
                                        assessmentInformation.formIds.map((formId, index) => {
                                            return <div key={formId} className="form-id" onClick={(e) => {
                                                handleFormClick(e, index);
                                            }}> Form { intToLetters(index) } </div>
                                        })
                                    }
                                </div>
                                <div className="side-nav-info-header" style={{ display: "flex", justifyContent: "space-between", columnGap: "10px", width: "100%" }}>
                                    Attempts
                                </div>
                                <div className="text-side-nav display">
                                    <div className="codes-container" style={{ justifyContent: "flex-start", columnGap: "10px" }}>
                                        {
                                            assessmentInformation.attempts !== -1
                                            
                                            ?

                                            <input type="number" className="grade-box-enter entry-exit" value={assessmentInformation.attempts} min={1} onChange={(e) => {
                                                updateAssessmentInformation("attempts", e.target.value, false);
                                            }} onBlur={() => {
                                                if (assessmentInformation.attempts !== undefined) {
                                                    submitForm();
                                                }
                                            }}/>

                                            :
                                            
                                            <>
                                            </>
                                        }
                                        <input type="checkbox" checked={assessmentInformation.attempts === -1} onChange={() => {
                                            if (assessmentInformation.attempts === -1) {
                                                updateAssessmentInformation("attempts", 1, false);  
                                            } else {
                                                updateAssessmentInformation("attempts", -1, false);  
                                            }                                                           
                                        }} onBlur={() => {
                                            if (assessmentInformation.attempts !== undefined) {
                                                submitForm();
                                            }
                                        }}/>
                                        Unlimited                                                                    
                                    </div>
                                </div>
                                {
                                    assessmentInformation.isProgramming && assessmentInformation.zipFileExists

                                    ?

                                    <>
                                        <div className="side-nav-info-header" style={{ display: "flex", justifyContent: "space-between", columnGap: "10px", width: "100%", alignItems: "center" }}>
                                            Zip File
                                            <Trash updateDeleteForm={updateDeleteZip}/>
                                        </div>
                                        {
                                            assessmentInformation?.zipFileExists && assessmentInformation.zipFilename && (
                                                <div className="text-side-nav display" style={{ marginTop: "-10px" }} onClick={handleZipClick}>
                                                    <div className="form-id">
                                                        { assessmentInformation.zipFilename }
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </>

                                    :

                                    <>
                                    </>
                                }
                                <div className="side-nav-info-header" style={{ display: "flex", justifyContent: "space-between", columnGap: "10px", width: "100%" }}>
                                    Entry Code 
                                    <CustomSwitch updateChecked={(e) => {updateAssessmentInformation("useEntryCode", e.target.checked, true)}} checked={assessmentInformation.useEntryCode}/>
                                </div>
                                {
                                    assessmentInformation.useEntryCode

                                    ?

                                        <div className="text-side-nav display">
                                            <div className="codes-container" style={{ justifyContent: "flex-start", columnGap: "10px" }}>
                                                <div type="number" className="grade-box-enter entry-exit">
                                                    {assessmentInformation.entryCode}
                                                </div>
                                                <div onClick={() => handleCodeClick()}>
                                                    <Regenerate />
                                                </div>
                                            </div>
                                        </div>

                                    :

                                    <>
                                    </>

                                }
                                <div className="side-nav-info-header" style={{ display: "flex", justifyContent: "space-between", columnGap: "10px", width: "100%" }}>
                                    Lockdown Environment                                    
                                    <CustomSwitch updateChecked={(e) => {updateAssessmentInformation("useLockdownEnvironment", e.target.checked, true)}} checked={assessmentInformation.useLockdownEnvironment}/>                            
                                </div>
                                {
                                    assessmentInformation.isProgramming

                                    ?

                                    <>
                                        <div className="side-nav-info-header" style={{ display: "flex", justifyContent: "space-between", columnGap: "10px", width: "100%" }}>
                                            Syntax Highlighting
                                            <CustomSwitch updateChecked={(e) => {updateAssessmentInformation("useSyntaxHighlighting", e.target.checked, true)}} checked={assessmentInformation.useSyntaxHighlighting}/>
                                        </div>
                                    </>

                                    :

                                    <>
                                    </>
                                }
                                <div className="side-nav-info-header" style={{ display: "flex", justifyContent: "space-between", columnGap: "10px", width: "100%", marginBottom: "45px" }}>
                                    Published Submissions
                                    <CustomSwitch updateChecked={(e) => {updateAssessmentInformation("published", e.target.checked, true)}} checked={assessmentInformation.published}/>
                                </div>
                                <EditAssessmentForm isProgramming={assessmentInformation.isProgramming} metric={assessmentInformation.metric} duration={assessmentInformation.duration} name={assessmentInformation.name} start={assessmentInformation.start} end={assessmentInformation.end} viewing={assessmentInformation.viewing} points={assessmentInformation.points}/>
                            </>

                            :

                            <>
                            </>
                        }
                    </>

                }
            </div>
        </>
    );
}

export default LeftInfoSettings;