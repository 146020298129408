import React, { useState, useEffect } from "react";
import Navigation from "./navigation";
import useScrollToTop from "./useScrollToTop";
import { useParams, useNavigate } from "react-router-dom"; // need for api call later
import NavInfoSubmissions from "./navInfoSubmissions";
import useWindowWidth from "./useWindowWidth";
import DeleteEvaluationForm from "./deleteEvaluationForm";
import NavInfoSubmissionsCollapsed from "./navInfoSubmissionsCollapsed";
import LeftInfoGradingPage from "./leftInfoGradingPage";
import PdfContainerGradingPage from "./pdfContainerGradingPage";
import LocationSettingsHover from "./locationSettingsHover";
import ZipFileViewerGradingPage from "./zipFileViewerGradingPage";
import TypesOfLocationEnum from "./typesOfLocationEnum";
import GradingPageCustomExamWrapper from "./gradingPageCustomExamWrapper";
import parseFileStructure from "./parseFileStructureToReturnPaths"
import parseZipFile from "./parseZipFile"
import TestsViewerGrading from "./testsViewerGrading";

const GradingPage = () => {
    useScrollToTop();
    const navigate = useNavigate();
    const width = useWindowWidth(1000);
    const { courseCode, assessmentCode, email } = useParams(); // need for api call later
    const [deleteEvaluation, setDeleteEvaluation] = useState(false);
    const [assessmentInformation, setAssessmentInformation] = useState({});
    const [isCursorInside, setIsCursorInside] = useState(false);
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const [fetchData, setFetchData] = useState(false);
    const [contentsForViewer, setContentsForViewer] = useState([]);
    const [blob, setBlob] = useState();
    const [requestLifeCycleTwo, setRequestLifeCycleTwo] = useState(false);
    const [contentsForCustomExam, setContentsForCustomExam] = useState({});
    const [currentViewer, setCurrentViewer] = useState(TypesOfLocationEnum.SETTINGS);
    const [testsConfigDict, setTestsConfigDict] = useState({});

    function updateFetchData(bool) {
        setFetchData(bool);
    }

    const fetchAssessmentInformation = async () => {
        try {
            setRequestLifeCycle(true);
            const res = await fetch( process.env.REACT_APP_SUBMISSION_API_URL + `/get-submission-information/${courseCode}/${assessmentCode}/${email}`, {
                method: "GET",
                credentials: "include"
            });
            const data = await res.json();
            if (res.status === 200) {
                setAssessmentInformation(data);
                updateFetchData(false);
            } else if (res.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            }
    
        } catch (error) {
            console.log(error);
        } finally {
            setRequestLifeCycle(false);
        }
    }

    useEffect(() => {
        const fetchZip = async () => {
            try {
                setRequestLifeCycleTwo(true)
                const res = await fetch( process.env.REACT_APP_SUBMISSION_API_URL + `/get-submission-zip/${courseCode}/${assessmentCode}/${email}`, {
                    method: "GET",
                    credentials: "include"
                });
                if (res.status === 200) {
                    const blob = await res.blob(); // Get the ZIP as a blob
                    setBlob(blob)
                    const contents = await parseZipFile(blob);
                    setContentsForViewer(contents);
                    const parsedContents = parseFileStructure(contents)
                    setContentsForCustomExam(parsedContents)
                    const testsConfigArray = "tests_config.json" in parsedContents ? JSON.parse(parsedContents["tests_config.json"].content) : []
                    setTestsConfigDict(testsConfigArray.tests.reduce((acc, curr) => {
                        acc[curr.id] = curr;
                        return acc;
                    }, {}));
                } else if (res.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                }
            } catch (error) {
                console.log(error);
            } finally {
                setRequestLifeCycleTwo(false);
            }
        };
        
        if (assessmentInformation.zipFileExists) {
            fetchZip();
        }

    }, [assessmentInformation.zipFileExists, currentViewer])

    useEffect(() => {
        if (fetchData) {
            fetchAssessmentInformation();
        }
    }, [fetchData])

    useEffect(() => {
        fetchAssessmentInformation();
    }, [])

    const handleDetailsClick = (event) => {
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${assessmentCode}/settings`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${assessmentCode}/settings`);
        }
    }

    const handleStatisticsClick = (event) => {
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${assessmentCode}/settings`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${assessmentCode}/statistics`);
        }
    }

    const handleDashboardClick = (event) => {
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${assessmentCode}/settings`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${assessmentCode}/`);
        }
    }

    function updateDeleteEvaluation(deleteVal) {
        setDeleteEvaluation(deleteVal);
    }

    const handleFocus = () => {
        setIsCursorInside(true);
    };
    
    const handleBlur = () => {
        setIsCursorInside(false);
    };

    function updateCurrentViewer(newViewer) {
        setCurrentViewer(newViewer);
    }

    function currentViewerToReturn() {
        switch(currentViewer) {
            case TypesOfLocationEnum.PDF: {
                return <PdfContainerGradingPage />
            }
            case TypesOfLocationEnum.ZIP: {
                return <ZipFileViewerGradingPage requestLifeCycle={requestLifeCycleTwo} blob={blob} contents={contentsForViewer}/>
            }
            case TypesOfLocationEnum.CUSTOM_EXAM: {
                return <GradingPageCustomExamWrapper updateCurrentViewer={updateCurrentViewer} contents={contentsForCustomExam} updateFetchData={updateFetchData} gradedQuestions={assessmentInformation.gradedQuestions}/>
            }
            case TypesOfLocationEnum.TESTS: {
                return <TestsViewerGrading requestLifeCycle={requestLifeCycle || requestLifeCycleTwo} gradedQuestions={assessmentInformation.gradedQuestions} testsConfigDict={testsConfigDict} updateCurrentViewer={updateCurrentViewer}/>
            }
            default: {
                return <></>
            }
        }
    }

    return (
        <>
            <Navigation isCursorInside={isCursorInside} boolFive={true} bool={false} info={ <NavInfoSubmissions isProgramming={assessmentInformation.isProgramming} updateDeleteEvaluation={updateDeleteEvaluation} handleDashboardClick={handleDashboardClick} handleStatisticsClick={handleStatisticsClick} handleDetailsClick={handleDetailsClick} name={assessmentInformation.name} description={"assessment settings. You may change assessment settings up to 30 minutes before the scheduled start time."} title={"Manage Settings"}/> } infoCollapsed={ <NavInfoSubmissionsCollapsed handleDashboardClick={handleDashboardClick} updateDeleteEvaluation={updateDeleteEvaluation} handleStatisticsClick={handleStatisticsClick} handleDetailsClick={handleDetailsClick}/>}/>
            <div className="courses-container" style={{ position: width ? "relative" : "fixed" }}>
                <div className={`single-course-container viewer full ${width ? "small" : "height"}`}>
                    <div className={`assessment-page-content min ${width ? "small" : ""}`}>
                        {
                            currentViewer === TypesOfLocationEnum.SETTINGS

                            ?                    

                            <>
                                <LeftInfoGradingPage currentViewer={currentViewer} requestLifeCycle={requestLifeCycle} assessmentInformation={assessmentInformation} isCursorInside={isCursorInside} handleBlur={handleBlur} handleFocus={handleFocus}/>
                            </>

                            :
                            
                            <>
                            </>

                        }
                        {
                            currentViewerToReturn()
                        }
                    </div>
                </div>
            </div>
            <LocationSettingsHover updateFetchData={updateFetchData} pdfExists={assessmentInformation.pdfExists} zipFileExists={assessmentInformation.zipFileExists} updateCurrentViewer={updateCurrentViewer} isProgramming={assessmentInformation.isProgramming}/>
            { deleteEvaluation ? <DeleteEvaluationForm name={assessmentInformation.name} updateDeleteEvaluation={updateDeleteEvaluation} /> : <></> }
        </>
    );
}

export default GradingPage;