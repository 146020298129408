import React, { useState } from "react";
import SaveChanges from "./saveChanges";
import { useParams, useNavigate } from "react-router-dom";
import WhiteLoading from "./whiteLoading";
import Info from "./info";

const DeleteEvaluationForm = (props) => {
    const [text, setText] = useState("Are you sure you want to delete this evaluation?");
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const { courseCode, assessmentCode } = useParams();
    const navigate = useNavigate();

    const resetForm = () => {
        props.updateDeleteEvaluation(false);
    }

    const submitForm = async (e) => {
        e.preventDefault();

        const url = process.env.REACT_APP_EVALUATION_API_URL + `/delete-assessment/${courseCode}/${assessmentCode}`

        const urlOptions = {
            method: "POST",
            credentials: "include"
        }

        try {
            setRequestLifeCycle(true)
            const res = await fetch(url, urlOptions);
            const data = await res.json();
            console.log(data);
            if (res.status === 200) {
                setText("Evaluation Deleted")
                navigate(`/${courseCode}`)
                props.updateDeleteEvaluation(false);
            } else if (res.status === 403) {
                setText("Evaluation deletion requires zero submissions")
            } else if (res.status === 404) {
                setText("Course not found")
            } else if (res.status === 405) {
                setText("Evaluation not found")
            } else if (res.status === 401) {
                setText("Authentication error")
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            } else {
                setText("An error occurred")
            }
            
        } catch (error) {
            setText("An error occurred")
            console.log(error);
        } finally {
            setRequestLifeCycle(false)
        }
    }

    return (
        <>
            <div className="black-back" onClick={ () => {props.updateDeleteEvaluation(false)} }>

            </div>
            <div className="add-a-course-form-container">
                <div className="add-a-course-form-content-container">
                    <form className="form-width">
                        <div className="add-course-title">
                            Delete Evaluation
                        </div>
                        <div className="account-settings-content">
                            <div className="required-container">
                                All Fields Required
                            </div>   
                            <div className="banner">
                                {
                                    requestLifeCycle 

                                    ?
                                    
                                    <>
                                        <WhiteLoading />
                                    </>

                                    :

                                    <div className="banner-info">
                                        <Info />
                                        { text }
                                    </div>

                                }
                            </div>
                            <SaveChanges left="Cancel" right={{ small : "Delete", large : "Delete" }} resetForm={resetForm} submitForm={submitForm}/> 
                        </div>
                    </form> 
                </div>
            </div>
        </>
    );
}

export default DeleteEvaluationForm;