import SemesterDashboard from "./components/semesterDashboard";
import CourseDashboard from "./components/courseDashboard";
import AccountSettings from "./components/accountSettings";
import AssessmentPage from "./components/assessmentPage";
import CourseRoster from "./components/courseRoster";
import SubmissionDashboard from "./components/submissionDashboard";
import GradingPage from "./components/gradingPage";
import SubmissionStatistics from "./components/submissionStatistics";
import AutograderDocs from "./components/autograderDocs";
import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import EditPage from "./components/editPage";
import PrivacyPolicy from "./components/privacyPolicy";
import TermsAndConditions from "./components/termsAndConditions";

function App() {

    useEffect(() => {
        const fetchRole = async () => {
            try {
                const response = await fetch( process.env.REACT_APP_USER_API_URL + "/get-role", {
                    method: "GET",
                    credentials: "include"
                })
                const data = await response.json()
                if (response.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;               // redirect to login page
                }
                else if (data.role === "student") {
                    window.location.href = process.env.REACT_APP_403_REDIRECT_URL;                     // redirect to student page
                } 
                console.log(data)

            } catch (error) {
                console.log(error)
            }
        }

        fetchRole();
    }, [])

    return (
        <>            
            <Router>
                <Routes>
                    <Route path="/autograder-docs" element={ <AutograderDocs /> } />
                    <Route path="/" element={ <SemesterDashboard /> } />
                    <Route path="/:courseCode" element={ <CourseDashboard /> } />
                    <Route path="/:courseCode/:assessmentCode" element={ <SubmissionDashboard /> } />
                    <Route path="/:courseCode/:assessmentCode/:email" element={ <GradingPage /> } />
                    <Route path="/:courseCode/:assessmentCode/settings" element={ <AssessmentPage /> } />
                    <Route path="/:courseCode/:assessmentCode/edit" element={ <EditPage /> } />
                    <Route path="/:courseCode/:assessmentCode/statistics" element={ <SubmissionStatistics /> } />
                    <Route path="/account-settings" element={ <AccountSettings /> } />
                    <Route path="/:courseCode/roster" element={ <CourseRoster /> } />
                    <Route path="/privacy-policy" element={ <PrivacyPolicy /> } />
                    <Route path="/terms-and-conditions" element={ <TermsAndConditions /> } />
                </Routes>
            </Router>           
        </>
    );
}

export default App;
