import React from "react";

const Circle = (props) => {
    const handleClick = () => {
        props.updateIndex(props.index);
    }
    
    return (
        <>
            <div className={`bubble ${props.correct ? "bubbled" : ""}`} onClick={handleClick}>

            </div>
        </>
    );
}

export default Circle;