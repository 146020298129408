function intToLetters(n) {
    // Input Validation
    if (typeof n !== 'number') {
        throw new TypeError('Input must be a number.');
    }
    if (!Number.isInteger(n)) {
        throw new RangeError('Input must be an integer.');
    }
    if (n < 0) {
        throw new RangeError('Input must be a non-negative integer.');
    }

    let result = '';

    // Edge case: If n is 0, return 'A'
    if (n === 0) {
        return 'A';
    }

    while (n >= 0) {
        const remainder = n % 26;
        const char = String.fromCharCode(65 + remainder); // 65 is the ASCII code for 'A'
        result = char + result;
        n = Math.floor(n / 26) - 1; // Decrement n to handle zero-based indexing

        if (n < 0) {
            break;
        }
    }

    return result;
}

export default intToLetters;