import React from "react";
import useWindowWidth from "./useWindowWidth";

const SaveChanges = (props) => {
    const width = useWindowWidth(410);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevents default behavior (e.g., form submission)
            props.submitForm();    // Calls the submitForm function
        }
    };

    return (
        <>
            <div className="save-changes-container" onKeyDown={handleKeyDown}>
                <input 
                    className="cancel" 
                    type="reset" 
                    value={props.left} 
                    onClick={props.resetForm} 
                />
                <input 
                    className="save-changes" 
                    type="submit" 
                    value={width ? props.right.small : props.right.large} 
                    onClick={props.submitForm} 
                    onKeyDown={handleKeyDown} // Optional: Add this to individual inputs if needed
                    style={{ width: "160px" }}
                />
            </div>
        </>
    );
}

export default SaveChanges;
