import React, { useState, useEffect } from "react";
import Navigation from "./navigation";
import useScrollToTop from "./useScrollToTop";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import NavInfoSubmissions from "./navInfoSubmissions";
import useWindowWidth from "./useWindowWidth";
import DeleteEvaluationForm from "./deleteEvaluationForm";
import NavInfoSubmissionsCollapsed from "./navInfoSubmissionsCollapsed";
import ZipFileViewer from "./zipFileViewer";
import PdfContainerSettings from "./pdfContainerSettings";
import LocationSettingsHover from "./locationSettingsHover";
import CustomProgrammingExam from "./customProgrammingExam";
import TypesOfLocationEnum from "./typesOfLocationEnum";
import DeleteQuestionForm from "./deleteQuestionForm";
import TestsViewer from "./testsViewer";

const EditPage = () => {
    const location = useLocation();
    useScrollToTop();
    const [isEditing, setIsEditing] = useState(true);
    const [addAQuestion, setAddAQuestion] = useState(false);
    const [deleteQuestion, setDeleteQuestion] = useState({
        bool: false,
        bankUuid: "",
        uuid: ""
    });
    const [questions, setQuestions] = useState([]);
    const navigate = useNavigate();
    const width = useWindowWidth(1000);
    const { courseCode, assessmentCode } = useParams();
    const [deleteEvaluation, setDeleteEvaluation] = useState(false);
    const [formIndex, setFormIndex] = useState(0);
    const [assessmentInformation, setAssessmentInformation] = useState({});
    const [fetchData, setFetchData] = useState(false);
    const [questionsToSave, setQuestionsToSave] = useState({});
    const [currentViewer, setCurrentViewer] = useState(TypesOfLocationEnum.CUSTOM_EXAM);

    useEffect(() => {
        const fetchAssessmentInformation = async () => {
            try {
                const res = await fetch( process.env.REACT_APP_EVALUATION_API_URL + `/get-assessment-settings/${courseCode}/${assessmentCode}`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await res.json();
                console.log(data)
                if (res.status === 200) {
                    setAssessmentInformation(data)
                } else if (res.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                }
        
            } catch (error) {
                console.log(error);
            }
        }

        fetchAssessmentInformation();
    }, [])

    function updateIsEditing(isEditing) {
        setIsEditing(isEditing);
    }

    function updateAddAQuestion(bool) {
        setAddAQuestion(bool);
    }

    function updateFormIndex(formIndex) {
        if (formIndex < 0) {
            return;
        } else if (formIndex >= assessmentInformation.formIds.length) {
            return;
        } else {
            setFormIndex(formIndex);
        }
    }
        
    const handleDetailsClick = (event) => {
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${assessmentCode}/settings`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${assessmentCode}/settings`);
        }
    }

    const handleStatisticsClick = (event) => {
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${assessmentCode}/settings`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${assessmentCode}/statistics`);
        }
    }

    const handleDashboardClick = (event) => {
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${assessmentCode}/settings`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${assessmentCode}/`);
        }
    }

    function updateDeleteEvaluation(deleteVal) {
        setDeleteEvaluation(deleteVal);
    }

    function updateCurrentViewer(newViewer) {
        setCurrentViewer(newViewer);
    }

    function updateDeleteQuestion(deleteQuestion) {
        setDeleteQuestion(deleteQuestion);
    }

    function updateQuestions(questions) {
        setQuestions(questions);
    }

    function updateFetchData(bool) {
        setFetchData(bool);
    }

    function updateQuestionsToSave(questionsToSave) {
        setQuestionsToSave(questionsToSave);
    }

    function currentViewerToReturn() {
        switch(currentViewer) {
            case TypesOfLocationEnum.PDF: {
                return <PdfContainerSettings assessmentInformation={assessmentInformation} formIndex={formIndex} updateFormIndex={updateFormIndex}/>
            }
            case TypesOfLocationEnum.CUSTOM_EXAM: {
                return <CustomProgrammingExam questionsToSave={questionsToSave} updateQuestionsToSave={updateQuestionsToSave} isEditing={isEditing} addAQuestion={addAQuestion} updateIsEditing={updateIsEditing} updateAddAQuestion={updateAddAQuestion} fetchData={fetchData} updateFetchData={updateFetchData} updateQuestionsParent={updateQuestions} zipFileExists={assessmentInformation.zipFileExists} useSyntaxHighlighting={assessmentInformation.useSyntaxHighlighting} updateDeleteQuestion={updateDeleteQuestion}/>
            }
            case TypesOfLocationEnum.ZIP: {
                return <ZipFileViewer />
            }
            case TypesOfLocationEnum.TESTS: {
                return <TestsViewer grading={true} updateCurrentViewer={updateCurrentViewer}/>
            }
            default: {
                return <></>
            }
        }
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const formIndex = queryParams.get("formIndex");
        const zip = queryParams.get("zip")
        if (formIndex) {
            setFormIndex(Number(formIndex));
            setCurrentViewer(TypesOfLocationEnum.PDF);
        }

        if (zip) {
            setCurrentViewer(TypesOfLocationEnum.ZIP)
        }

    }, [])

    return (
        <>
            <Navigation updateQuestionsToSave={updateQuestionsToSave} questionsToSave={questionsToSave} updateFetchData={updateFetchData} updateIsEditing={updateIsEditing} isEditing={isEditing} updateAddAQuestion={updateAddAQuestion} boolSix={currentViewer === TypesOfLocationEnum.CUSTOM_EXAM} info={ <NavInfoSubmissions isProgramming={assessmentInformation.isProgramming} updateDeleteEvaluation={updateDeleteEvaluation} handleDashboardClick={handleDashboardClick} handleStatisticsClick={handleStatisticsClick} handleDetailsClick={handleDetailsClick} name={assessmentInformation.name} description={"evaluation settings. You may change evaluation settings up to 30 minutes before the scheduled start time."} title={"Manage Settings"}/> } infoCollapsed={ <NavInfoSubmissionsCollapsed handleDashboardClick={handleDashboardClick} updateDeleteEvaluation={updateDeleteEvaluation} handleStatisticsClick={handleStatisticsClick} handleDetailsClick={handleDetailsClick}/>}/>
            <div className="courses-container" style={{ overflow: "hidden", position: currentViewer === TypesOfLocationEnum.PDF ? (width ? "relative" : "fixed") : "relative" }}>
                    <div className={`single-course-container viewer full ${width && currentViewer !== TypesOfLocationEnum.CUSTOM_EXAM ? "small" : "height"}`}>
                        <div className={`assessment-page-content min ${width && currentViewer !== TypesOfLocationEnum.CUSTOM_EXAM  ? "small" : ""}`}>
                            {
                                currentViewerToReturn()
                            }
                        </div>
                    </div>
            </div>
            <LocationSettingsHover pdfExists={assessmentInformation.pdfExists} zipFileExists={assessmentInformation.zipFileExists} updateCurrentViewer={updateCurrentViewer} isProgramming={assessmentInformation.isProgramming}/>
            { deleteEvaluation ? <DeleteEvaluationForm name={assessmentInformation.name} updateDeleteEvaluation={updateDeleteEvaluation} /> : <></> }
            { deleteQuestion.bool ? <DeleteQuestionForm questionsToSave={questionsToSave} uuid={deleteQuestion.uuid} bankUuid={deleteQuestion.bankUuid} updateFetchData={updateFetchData} updateDeleteQuestion={updateDeleteQuestion} questions={questions}/> : <></> }
        </>
    );
}

export default EditPage;