import React, { useState, useEffect } from "react";
import TypesOfQuestionsEnum from "./typesOfQuestionsEnum";
import TypesOfLocationEnum from "./typesOfLocationEnum";
import WhiteLoading from "./whiteLoading";
import ASingleTestGrading from "./aSingleTestGrading";
import SelectZipFile from "./selectZipFile";
import { useParams } from "react-router-dom";
import uploadFileToS3 from "./uploadFileToS3";
import InitSortQuestions from "./initSortQuestions";
import validateZipFile from "./validateZipFile";

const UploadAndViewTests = ({initQuestions, updateCurrentViewer }) => {
    const [questions, setQuestions] = useState([])
    const [requestLifeCycle, setRequestLifeCycle] = useState(false)
    const [inputZipFile, setInputZipFile] = useState("");
    const [testsConfigDict, setTestsConfigDict] = useState({});
    const [text, setText] = useState(initQuestions && initQuestions.length > 0 ? "Upload a test zip file below" : "No questions to test at this time");
    const [error, setError] = useState(null)
    const { courseCode, assessmentCode } = useParams();

    const updateZipFile = async (file) => {
        const [isValid, message] = await validateZipFile(file);
        
        if (!isValid) {
            setText(message);
            return;
        }

        setInputZipFile(file)
    }

    const uploadZipBeforeAutograde = async () => {
        try {
            const response = await fetch( process.env.REACT_APP_SUBMISSION_API_URL + `/get-url-test-zip/${courseCode}/${assessmentCode}`, { method: "GET", credentials: "include" })
            const data = await response.json()
            if (response.status === 200 && data.url) {
                const status = await uploadFileToS3(inputZipFile, data.url);

                if (status !== 200) {
                    setText("Zip failed to upload");
                }

                return 200;

            } else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_SUBMISSION_API_URL;
            } else {
                setText("Could not fetch signed url")
                return 400;
            }
        } catch (error) {        
            console.log(error)
            return 400;
        }
    }

    useEffect(() => {
        const uploadAndTestZipFile = async () => {
            try {
                setRequestLifeCycle(true)
                await uploadZipBeforeAutograde();

                const formData = new FormData();
                if (inputZipFile) {
                    formData.append("courseCode", courseCode)
                    formData.append("assessmentCode", assessmentCode)                
                } else {
                    return;
                }

                const url = process.env.REACT_APP_SUBMISSION_API_URL + "/test-input-zip-file";
                const urlOptions = {
                    method: "POST",
                    credentials: "include",
                    body: formData
                }

                const response = await fetch(url, urlOptions);
                const data = await response.json()
                if (response.status === 200) {
                    const gradedQuestions = InitSortQuestions(data.questionsOrder, data.gradedQuestions);
                    setTestsConfigDict(data.testsConfigDict);
                    if (gradedQuestions && gradedQuestions.length > 0) {
                        setQuestions(gradedQuestions.filter((question, index) => {
                            question.index = index;
                            return (question.questionType === TypesOfQuestionsEnum.PROGRAMMING) && question?.tests.length > 0
                        }))
                    }
                    setText(initQuestions && initQuestions.length > 0 ? "Upload a test zip file below" : "No questions to test at this time");
                } else if (response.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL
                } else {
                    setError(data.detail)
                }

            } catch (error) {
                console.log(error.detail)
                setError(error.detail)
                console.log(error)
            } finally {
                setRequestLifeCycle(false);
            }
        }

        if (inputZipFile) {
            uploadAndTestZipFile();
        }

    }, [inputZipFile])

    return (
        <>
            {
                requestLifeCycle

                ?

                <div className="loading-zip-container">
                    <WhiteLoading />
                </div>

                :

                <>
                    {
                        (questions && questions.length > 0) || error

                        ?

                        <>
                            <div className="question-tests-container">
                                <div className="clear-container">
                                    <div className="top-right-item" onClick={() => {
                                        setQuestions([]);
                                        setError(null)
                                    }}>
                                        Clear
                                    </div>
                                </div>
                                {
                                    questions.map((question) => (
                                        question.tests.map((id) => {
                                            if (id in testsConfigDict) {
                                                return <ASingleTestGrading key={id} {...testsConfigDict[id]} index={question.index + 1}/>
                                            }
                                        })
                                    ))
                                }
                                {
                                    error ?

                                    error

                                    :
                                    
                                    <></>
                                }
                            </div>
                        </>

                        :

                        <>
                            <div className="no-questions">
                                <div className="no-assignments-container" style={{ paddingTop: "0px"}}>
                                    <div className="no-assignments"> 
                                        { text }
                                        {
                                            initQuestions && initQuestions.length > 0 ?

                                            <div className="input-wrapper-test-zip">
                                                <div style={{ width: "80%", height: "27.5px" }}>
                                                    <SelectZipFile star={false} value={inputZipFile} updateValue={updateZipFile} required={true}/>
                                                </div>
                                            </div>

                                            :

                                            <>
                                                <div className="save-changes-2" onClick={() => {
                                                    updateCurrentViewer(TypesOfLocationEnum.CUSTOM_EXAM);
                                                }}>
                                                    Back To Questions
                                                </div>
                                            </>

                                        }
                                    </div>
                                </div>
                            </div>
                        </>

                    }
                </>

            }
        </>
    );
}

export default UploadAndViewTests;