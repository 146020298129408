function parseFileStructure(data, currentPath = "") {
    let result = {};

    data.forEach(item => {
        const filePath = currentPath ? `${currentPath}/${item.name}` : item.name;

        if (item.children) {
        // It's a directory
        result[filePath] = { content: null, isDir: true };
        // Recursively parse children
        Object.assign(result, parseFileStructure(item.children, filePath));
        } else {
        // It's a file
        result[filePath] = { content: item.text || "", isDir: false };
        }
    });

    return result;
}

export default parseFileStructure;