import TypesOfQuestionsEnum from "./typesOfQuestionsEnum";
import generateRandomString from "./generateRandomString";

const createQuestion = (question, uuid) => {
    const code = question.assessmentCode;
    console.log("POINTS")
    const points = Number(question.points).toFixed(2);

    const itemForVewing = {
        assessmentCode: code,
        question: "",
        points: points,
        uuid: uuid,
        questionType: question.questionType
    };

    // if true false
    if (question.questionType === TypesOfQuestionsEnum.TRUE_FALSE) {
        itemForVewing.bubbled = -1;
    }

    // if short response
    else if (question.questionType === TypesOfQuestionsEnum.SHORT_RESPONSE) {
        itemForVewing.answerString = "";
    }

    // if multiple choice
    else if (question.questionType === TypesOfQuestionsEnum.MULTIPLE_CHOICE) {
        const optionObject = {
            option: "",  // Placeholder for the actual option
            index: generateRandomString(8)  // Placeholder for the index
        }
        itemForVewing.options = [optionObject];
        itemForVewing.shuffle = false;
        itemForVewing.answerIndex = -1;

    }

    // if select all
    else if (question.questionType === TypesOfQuestionsEnum.SELECT_ALL) {
        const optionObject = {
            option: "",  // Placeholder for the actual option
            index: generateRandomString(8)  // Placeholder for the index
        }
        itemForVewing.options = [optionObject];
        itemForVewing.shuffle = false;
        itemForVewing.answerArray = [];

    }

    // if programming
    else if (question.questionType === TypesOfQuestionsEnum.PROGRAMMING) {
        itemForVewing.language = "plaintext";
        itemForVewing.location = "";
        itemForVewing.useAutocompletion = false;
        itemForVewing.tests = [];
    }

    // if context block
    else if (question.questionType === TypesOfQuestionsEnum.CONTEXT_BLOCK) {
        itemForVewing.points = 0;
    }

    // if bank of questions
    else if (question.questionType === TypesOfQuestionsEnum.BANK_OF_QUESTIONS) {
        itemForVewing.points = 0;
        itemForVewing.questions = [];
    }

    else {
        // invalid question type
        return null;
    }

    return itemForVewing;
}

export default createQuestion;