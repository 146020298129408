import React, { useEffect, useState } from "react";
import SquareWithTextView from "./squareWithTextView";
import MarkdownWithLaTeX from "./markDownWithLatex";
import SquareWithTextViewGradingPage from "./squareWithTextViewGradingPage";
import GradingBoxQuestion from "./gradingBoxQuestion";

const SelectAllThatApplyView = ({ questionsToSave, questionNumber, question, points, options, grading, pointsEarned, studentAnswer, answerArrayAnswer, uuid }) => {
    const [answerArray, setAnswerArray] = useState([]);
    const [form, setForm] = useState({
        points: points,
        question: question,
        options: options
    })

    function updateIndex(newIndex) {
        if (answerArray.includes(newIndex)) {
            setAnswerArray(answerArray.filter(index => index !== newIndex)); // Remove newIndex
        } else {
            setAnswerArray([...answerArray, newIndex]); // Add newIndex
        }
    }

    const studentAnswerIsAnOptionAndIsInAnswerArray = (arrayOfAnswers, arrayOfStudentAnswers, answerToLookFor) => {
        if (!arrayOfStudentAnswers.includes(answerToLookFor)) {
            return false;
        } else if (arrayOfAnswers.includes(answerToLookFor)) {
            return true;
        }
    }

    const handleStateCalculation = (answerState) => {
        const studentAnswerIsInAnswerArray = studentAnswerIsAnOptionAndIsInAnswerArray(answerArrayAnswer, studentAnswer, answerState)
        // check if answers match or check if they match the possible answerState
        if (studentAnswerIsInAnswerArray && (answerArrayAnswer.includes(answerState))) {
            return 1;
        } 
        // check if the answers dont't match and the correct answer state is a match
        else if (!studentAnswerIsInAnswerArray && (studentAnswer.includes(answerState))) {
            return 0;
        }
        else if (!studentAnswerIsInAnswerArray && (answerArrayAnswer.includes(answerState))) {
            return 2;
        }
        // if both fail, we have a blank answer
        else {
            return -1;
        }
    }

    function updateForm(key, value) {
        setForm((prev) => (
            {
                ...prev,
                [key]: value
            }
        ))
    }

    useEffect(() => {
        if (uuid in questionsToSave) {
            const savedFields = questionsToSave[uuid].fields;
            
            if (savedFields) {
                // Update the local state for points and question
                if (savedFields.points && savedFields.points !== points) {
                    updateForm("points", savedFields.points)
                }
                if (savedFields.question && savedFields.question !== question) {
                    updateForm("question", savedFields.question)
                }
                if (savedFields.options && savedFields.options !== options) {
                    updateForm("options", savedFields.options)
                }
            }
        }
    }, [questionsToSave, points, question, options]);
    
    return (
        <>
            <div className="true-false-question-view">
                <div className="question-number">
                    <>
                        Q{ questionNumber }
                    </>
                    <div className="pts">
                        {
                            grading

                            ?

                            <>
                                <GradingBoxQuestion pointsEarned={pointsEarned} uuid={uuid}/>
                            </>

                            :

                            <>
                            </>
                        }
                        { (grading ? "/ " : "") + Number(form.points).toFixed(2) } pts
                    </div>
                </div>
                <div className="true-false-top">
                    <MarkdownWithLaTeX content={form.question} />
                </div>
                <div className="select-all-that-apply-array">
                    {
                        form.options && form.options.length > 0

                        ?

                        form.options.map((option) => (
                            grading
                            
                            ?

                            <SquareWithTextViewGradingPage key={option.index} state={handleStateCalculation(option.index)} text={option.option}/>      

                            :
                            
                            <SquareWithTextView key={option.index} correct={answerArray.includes(option.index)} index={option.index} text={option.option} updateIndex={updateIndex}/>
                        ))

                        :

                        <>
                        </>
                    }
                </div>
            </div>
        </>
    );
}

export default SelectAllThatApplyView;