import React, { useState, useEffect } from "react";
import PDFIcon from "./pdfIcon";
import PDF from "./pdf";
import useWindowWidth from "./useWindowWidth";
import Loading from "./loading";
import { useParams } from "react-router-dom";
import OnlyIInfo from "./onlyIInfo";

const PdfContainerGradingPage = (props) => {
    const [pdf, setPdf] = useState("");
    const width = useWindowWidth(1000);
    const { courseCode, assessmentCode, email } = useParams();
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);

    useEffect(() => {
        const fetchPdf = async () => {
            try {
                setRequestLifeCycle(true);
                const res = await fetch( process.env.REACT_APP_SUBMISSION_API_URL + `/get-submission-pdf/${courseCode}/${assessmentCode}/${email}`, {
                    method: "GET",
                    credentials: "include"
                });
                if (res.status === 200) {
                    const blob = await res.blob();
                    const fileReader = new FileReader();
                    fileReader.onload = (e) => {
                        const pdfDataUrl = e.target.result;
                        setPdf(pdfDataUrl); // This will store the PDF data as base64
                    };
                    fileReader.readAsDataURL(blob);
                    props.updateNoPdf(false);
                } else if (res.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                } 

            } catch (error) {
                console.log(error);
            } finally {
                setRequestLifeCycle(false);
            }
        };

        fetchPdf();

    }, [])

    return (
        <>
            <div className={ `pdf-content-container  ${ width ? "small" : "" }` } style={{ minHeight: width ? "600px" : "", position: "relative"}}>
                {
                    requestLifeCycle

                    ?

                    <div className="loading-zip-container">
                        <Loading />
                    </div>

                    :

                    <>
                        {
                            pdf === "" 
        
                            ?
        
                            <div className="loading-zip-container">
                                <PDFIcon />
                            </div>
        
                            :
        
                            <>
                                <PDF pdf={pdf}/>
                            </>
                        }
                    </>
                }
                <OnlyIInfo />
            </div>
        </>
    );
}

export default PdfContainerGradingPage;