import React from "react";
import Bubble from "./bubble";

const BubbleWithTextView = (props) => {
    return (
        <>
            <div className="bubble-with-text-view">
                <Bubble bubbled={props.bubbled} text={props.text} updateBubbled={props.updateBubbled}/>
                { props.text }
            </div>
        </>
    );
}

export default BubbleWithTextView;