const handleState = (state) => {
    if (state === 1) {
        return "correct";
    } else if (state === 0) {
        return "incorrect";
    } else if (state === -1) {
        return ""
    } else if (state === 2) {
        return "correct-but-not-selected"
    }
}

export default handleState;