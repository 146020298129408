import React from "react";

const ASingleTest = ({ name, id, totalPoints, index }) => {
    return (
        <>
            <div className="a-single-test">
                <div className="left-of-test">
                    { "Q" + index + ": " + name}
                </div>
                <div>
                    { Number(totalPoints).toFixed(2) + " pts"}
                </div>
            </div>
        </>
    );
}

export default ASingleTest;