import React, { useState, useEffect } from "react";
import Editor from "@monaco-editor/react";
import DarkThemeQuestion from "./darkThemeQuestion";
import GradingBoxQuestion from "./gradingBoxQuestion";
import MarkdownWithLaTeX from "./markDownWithLatex";

const CodeResponseView = ({ questionsToSave, useSyntaxHighlighting, location, contents, useAutocompletion, questionNumber, question, language, isDarkTheme, updateIsDarkTheme, points, grading, uuid, pointsEarned, studentAnswer }) => {
    const [answer, setAnswer] = useState(studentAnswer);
    const [contentState, setContentState] = useState("");
    const [form, setForm] = useState({
        points: points,
        question: question,
        useAutocompletion: useAutocompletion,
        language: language,
        location: location
    })

    const handleAnswer = (value) => {
        setAnswer(value);
    }

    const handleContentState = (content) => {
        setContentState(content);
    }

    useEffect(() => {
        // Check if contents and location are valid
        if (contents && location && contents[location]?.content) {
            setContentState(contents[location].content);
        }
    }, [contents, location]); // Depend on contents and location

    function updateForm(key, value) {
        setForm((prev) => (
            {
                ...prev,
                [key]: value
            }
        ))
    }

    useEffect(() => {
        if (uuid in questionsToSave) {
            const savedFields = questionsToSave[uuid].fields;
            
            if (savedFields) {
                // Update the local state for points and question
                if (savedFields.points && savedFields.points !== points) {
                    updateForm("points", savedFields.points)
                }
                if (savedFields.question && savedFields.question !== question) {
                    updateForm("question", savedFields.question)
                }
                if (savedFields.useAutocompletion && savedFields.useAutocompletion !== useAutocompletion) {
                    updateForm("useAutocompletion", savedFields.useAutocompletion)
                }
                if (savedFields.language && savedFields.language !== language) {
                    updateForm("language", savedFields.language)
                }
                if (savedFields.location && savedFields.location !== location) {
                    updateForm("location", savedFields.location)
                }
            }
        }
    }, [questionsToSave, points, question]);

    console.log(questionsToSave)

    return (
        <>
            <div className="true-false-question-view">
                <div className="question-number">
                    <>
                        Q{ questionNumber }
                    </>
                    <div className="pts">
                        {
                            grading

                            ?

                            <>
                                <GradingBoxQuestion uuid={uuid} pointsEarned={pointsEarned}/>
                            </>

                            :

                            <>
                            </>
                        }
                        { (grading ? "/ " : "") + Number(form.points).toFixed(2) } pts
                    </div>
                </div>
                <div className="true-false-top">
                    <MarkdownWithLaTeX content={form.question} />
                </div>
                <div className="code-response-area-wrapper">
                    <div className="code-response-area" style={{ backgroundColor: isDarkTheme ? "var(--vs-background)" : "white", color: isDarkTheme ? "white" : "var(--almost-black)" }}>
                        <div className="code-editor-header">
                            <DarkThemeQuestion isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme}/>
                        </div>                        
                        <Editor
                            language={useSyntaxHighlighting ? form.language : "plaintext"}
                            value={contents && form.location in contents ? contentState : answer}
                            theme={ isDarkTheme ? "vs-dark" : "vs"}
                            onChange={contents && form.location in contents ? handleContentState : handleAnswer}
                            options={{
                                fontFamily: "Consolas, 'Courier New', monospace", // Default font family
                                fontSize: 14, // Default font size (14px)
                                tabSize: 8,         // Set tab length to 4 spaces
                                fontWeight: "500", // Default font weight
                                suggestOnTriggerCharacters: form.useAutocompletion,
                                quickSuggestions: form.useAutocompletion,  
                                readOnly: grading                              
                            }}
                        />
                        <div className="code-editor-header" />
                    </div>
                </div>
            </div>
        </>
    );
}

export default CodeResponseView;