import React from "react";

const Square = (props) => {
    const handleClick = () => {
        props.updateIndex(props.index);
    }
    return (
        <>
            <div className={`square ${props.correct ? "bubbled" : ""}`} onClick={handleClick}>

            </div>
        </>
    );
}

export default Square;