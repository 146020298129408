import React, { useEffect, useState } from "react";
import CourseInput from "./courseInput";
import SaveChanges from "./saveChanges";
import AddFile from "./addFile";
import { useParams } from "react-router-dom";
import WhiteLoading from "./whiteLoading";
import MetricSelect from "./metricSelect";
import NamesDropdown from "./namesDropdown";
import Info from "./info";
import FormSelect from "./formSelect";
import SelectZipFile from "./selectZipFile";

const AddSubmissionProgrammingForm = (props) => {
    const [text, setText] = useState("Enter the submission information below");
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const { courseCode, assessmentCode } = useParams();
    const [students, setStudents] = useState([]);
    const [name, setName] = useState("");
    const [searchBool, setSearchBool] = useState(true);
    const [form, setForm] = useState({
        courseCode: courseCode,
        assessmentCode: assessmentCode,
        email: "",
        grade: "",
        graded: false,
        pdf: "",
        searchName: "",
        extraTime: "",
        zipFile: "",
        metric: "",
        index: 0,
        formId: props.formIds[0]
    })

    useEffect(() => {
        const fetchStudents = async () => {
            try {
                setRequestLifeCycle(true);
                const res = await fetch( process.env.REACT_APP_COURSE_API_URL + `/get-only-roster/${courseCode}`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await res.json();
                console.log(data)
                if (res.status === 200) {
                    setStudents(data);
                    setRequestLifeCycle(false);
                } else if (res.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                    setRequestLifeCycle(false);
                } else {
                    setRequestLifeCycle(false);
                }
        
            } catch (error) {
                setRequestLifeCycle(false);
                console.log(error);
            }
        }

        fetchStudents();
    }, [])

    function updateName(name) {
        setName(name);
    }

    function updateGrade(grade) {
        setForm(prevForm => ({
            ...prevForm,
            grade: grade,
        }))
        console.log(form);
    }

    function updatePdf(pdf) {
        setForm(prevForm => ({
            ...prevForm,
            pdf: pdf,
        }))
        console.log(form);
    }

    function updateFormId(index) {
        setForm(prevForm => ({
            ...prevForm,
            formId: props.formIds[index],
            index: index
        }))
        console.log(form);
    }

    function updateMetric(metric) {
        setForm(prevForm => ({
            ...prevForm,
            metric: metric,
        }));
        console.log(form);
    }
    
    function updateExtraTime(extraTime) {
        setForm(prevForm => ({
            ...prevForm,
            extraTime: extraTime,
        }));
        console.log(form);
    }

    function updateEmail(email) {
        setForm(prevForm => ({
            ...prevForm,
            email: email,
        }));
        console.log(form);
    }

    function updateZipFile(zipFile) {
        setForm(prevForm => ({
            ...prevForm,
            zipFile: zipFile,
        }))
        console.log(form);
    }

    function updateSearchName(name) {
        setForm(prevForm => ({
            ...prevForm,
            searchName: name,
        }));
        console.log(form);
    }

    function updateSearchBool(bool) {
        setSearchBool(bool);
    }

    const resetForm = () => {
        props.updateSubmission(false);
    }


    const submitForm = async (e) => {
        e.preventDefault();

        // form validation
        if (form.name === "") {
            setText("Please fill out all required fields");
            return;
        }

        // check email
        if (form.email === "") {
            setText("Please select a student");
            return;
        }


        // form validation
        if (form.metric !== "minutes" && form.metric !== "hours" && form.metric !== "days") {
            setText("Invalid metric");
            return;
        }

        const formData = new FormData();
        formData.append('courseCode', form.courseCode);
        formData.append('assessmentCode', form.assessmentCode);
        formData.append('name', form.searchName);
        formData.append('email', form.email);
        formData.append('grade', form.grade !== "" ? form.grade : 0);
        formData.append('graded', form.grade !== "" ? true : false);
        formData.append('extraTime', form.extraTime !== "" ? form.extraTime : 0);
        formData.append('metric', form.metric);
        formData.append('formId', form.formId);
        if (form.pdf != "") {
            formData.append('pdf', form.pdf);
        }
        if (form.zipFile != "") {
            formData.append('zipFile', form.zipFile);
        }

        const url = process.env.REACT_APP_SUBMISSION_API_URL + "/upload-submission-programming"

        const urlOptions = {    
            method: "POST",
            credentials: "include",
            headers: {
                "Accept": "application/json",
            },
            body: formData
        }

        try {
            setRequestLifeCycle(true)
            const res = await fetch(url, urlOptions);
            const data = await res.json();
            console.log(data);
            if (res.status === 200) {
                setText("Submission uploaded")
                props.updateSubmission(false);
                window.location.reload();
            } else if (res.status === 403) {
                setText("You are not an instructor in this course")
            } else if (res.status === 405) {
                setText("Student is not enrolled in course")
            } else if (res.status === 401) {
                setText("Authentication error")
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            }
            setRequestLifeCycle(false)

        } catch (error) {
            setText("An error occurred")
            console.log(error);
        } finally {
            setRequestLifeCycle(false)
        }
        
    }

    useEffect(() => {
        setText("Enter the submission information below");
    }, [form])

    useEffect(() => {
        if (name === form.searchName) {
            updateSearchBool(false);
        } else {
            updateSearchBool(true);
        }
    }, [form.searchName])

    return (
        <>
            <div className="black-back" onClick={ () => {props.updateSubmission(false)} }>

            </div>
            <div className="add-a-course-form-container">
                <div className="add-a-course-form-content-container">
                    <form className="form-width">
                        <div className="add-course-title">
                            Upload Submission
                        </div>
                        <div className="account-settings-content">
                            <div className="required-container">                                
                                <span className="required-star">
                                    *
                                </span>
                                Required
                            </div>   
                            <div className="banner">
                                {
                                    requestLifeCycle 

                                    ?
                                    
                                    <>
                                        <WhiteLoading />
                                    </>

                                    :

                                    <div className="banner-info">
                                        <Info />
                                        { text }
                                    </div>

                                }
                            </div>
                            <div style={{ position: "relative", width: "100%" }}>
                                <CourseInput value={form.searchName} updateValue={updateSearchName} search={true} top={"Student Name"} type={"text"} required={true} autoComplete={"one-time-code"} placeholder={"Search"}/>
                                <>
                                    <NamesDropdown searchName={form.searchName} assessmentCode={assessmentCode} updateEmail={updateEmail} updateExtraTime={updateExtraTime} updateMetric={updateMetric} students={students} updateSearchName={updateSearchName} searchBool={searchBool} updateName={updateName} updateSearchBool={updateSearchBool}/>
                                </>
                            </div>                       
                            <div className="points-viewing-container">
                                <div style={{ width: "45%" }}>
                                    <AddFile value={form.pdf} star={false} updateValue={updatePdf} top={"Select PDF"} required={true}/>
                                </div>
                                <div className="points-viewing-container" style={{ width: "45%" }}>
                                    <SelectZipFile star={false} value={form.zipFile} updateValue={updateZipFile} top={"Select Zip File"} required={true}/>
                                </div>
                            </div>
                            {
                                props.formIds.length > 1

                                ?

                                <div style={{ position: "relative", width: "50%" }}>                                       
                                    <FormSelect top={"Form Id"} star={true} uuids={props.formIds} updateValue={updateFormId} value={form.index}/>
                                </div>

                                :

                                <>
                                </>
                            }
                            <div className="points-viewing-container">
                                <div style={{ width: "45%" }}>
                                    <CourseInput top={"Student Grade"} value={form.grade} updateValue={updateGrade} type={"number"} required={true} autoComplete={"off"}/>
                                </div>
                                <div className="points-viewing-container" style={{ width: "45%" }}>
                                    <div style={{ width: "45%" }}>
                                        <CourseInput top={"Extension"} value={form.extraTime} updateValue={updateExtraTime} type={"number"} required={true} autoComplete={"off"} min={0}/>
                                    </div>
                                    <div style={{ width: "45%" }}>
                                        <MetricSelect top={"Metric"} value={form.metric} updateValue={updateMetric} metrics={
                                            [
                                                {
                                                    name: "Minutes",
                                                    value: "minutes"
                                                },
                                                {
                                                    name: "Hours",
                                                    value: "hours"
                                                },
                                                {
                                                    name: "Days",
                                                    value: "days"
                                                }
                                        ]
                                            
                                        } type={"number"} required={true} autoComplete={"off"} min={0}/>
                                    </div>
                                    </div>
                            </div>
                            <SaveChanges left="Cancel" right={{ small : "Upload Submission", large : "Upload Submission" }} resetForm={resetForm} submitForm={submitForm}/> 
                        </div>
                    </form> 
                </div>
            </div>
        </>
    );
}

export default AddSubmissionProgrammingForm;