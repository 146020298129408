import React from "react";
import Primary from "./primary";

const EmailAddress = (props) => {
    return (
        <>
            <div className={`bottom-input email ${ props.primary ? "primary" : "" }`}>
                <div className="input-container-pad">
                    { props.email }
                    {
                        props.primary

                        ?

                        <Primary />

                        :

                        <></>

                    }
                </div>
            </div>
        </>
    );
}

export default EmailAddress;