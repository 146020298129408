import React, { useEffect, useState } from "react";
import BubbleWithTextView from "./bubbleWithTextView";
import MarkdownWithLaTeX from "./markDownWithLatex";
import BubbleWithTextViewGradingPage from "./bubbleWithTextViewGradingPage";
import GradingBoxQuestion from "./gradingBoxQuestion";

const TrueFalseQuestionView = ({ questionsToSave, questionNumber, question, points, grading, pointsEarned, studentAnswer, bubbledAnswer, uuid }) => {
    const [bubbled, setBubbled] = useState(-1);
    const [form, setForm] = useState({
        points: points,
        question: question
    })

    function updateBubbled(newBubbled) {
        if (newBubbled === bubbled) {
            setBubbled(-1);
        } else {
            setBubbled(newBubbled);
        }
    }    

    const handleStateCalculation = (answerState) => {
        // check if answers match or check if they match the possible answerState
        if (studentAnswer === bubbledAnswer && (bubbledAnswer === answerState)) {
            return 1;
        } 
        // check if the answers dont't match and the correct answer state is a match
        else if (studentAnswer !== bubbledAnswer && (studentAnswer === answerState)) {
            return 0;
        }
        // if correct answer and student did not answer it correctly
        else if (studentAnswer !== bubbledAnswer  && (bubbledAnswer === answerState)) {
            return 2
        }
        // if both fail, we have a blank answer
        else {
            return -1;
        }
    }

    function updateForm(key, value) {
        setForm((prev) => (
            {
                ...prev,
                [key]: value
            }
        ))
    }

    useEffect(() => {
        if (uuid in questionsToSave) {
            const savedFields = questionsToSave[uuid].fields;
            
            if (savedFields) {
                // Update the local state for points and question
                if (savedFields.points && savedFields.points !== points) {
                    updateForm("points", savedFields.points)
                }
                if (savedFields.question && savedFields.question !== question) {
                    updateForm("question", savedFields.question)
                }
            }
        }
    }, [questionsToSave, points, question]);

    return (
        <>
            <div className="true-false-question-view">
                <div className="question-number">
                    <>
                        Q{ questionNumber }
                    </>
                    <div className="pts">
                        {
                            grading

                            ?

                            <>
                                <GradingBoxQuestion pointsEarned={pointsEarned} uuid={uuid}/>
                            </>

                            :

                            <>
                            </>
                        }
                        { (grading ? "/ " : "") + Number(form.points).toFixed(2) } pts
                    </div>
                </div>
                <div className="true-false-top">
                    <MarkdownWithLaTeX content={form.question} />
                </div>
                <div className="true-false-answer-block">
                    {
                        grading

                        ?

                        <>
                            <BubbleWithTextViewGradingPage text={"True"} state={handleStateCalculation(1)}/>
                            <BubbleWithTextViewGradingPage text={"False"} state={handleStateCalculation(0)}/>
                        </>

                        :

                        <>
                            <BubbleWithTextView text={"True"} bubbled={bubbled === 1} updateBubbled={updateBubbled}/>
                            <BubbleWithTextView text={"False"} bubbled={bubbled === 0} updateBubbled={updateBubbled}/>
                        </>

                    }
                </div>
            </div>
        </>
    );
}

export default TrueFalseQuestionView;