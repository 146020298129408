import React from "react";

const FeedbackRow = (props) => {
    return (
        <>
            <div className="feedback-row" style={{ borderTopWidth: props.index === 0 ? "0px" : "1px" }} onClick={() => {
                props.updateFeedback(props.feedback);
                props.updateFeedbackCode(props.feedbackCode);
            }}>
                { props.feedback }
            </div>
        </>
    );
}

export default FeedbackRow;