import React from "react";

const Chevron = (props) => {
    const handleClick = () => {
        props.updateFormIndex(props.formIndex + 1);
    }

    return (
        <>
            <div className={`chevron-container-2 ${props.formIndex === (props.numForms - 1) ? "" : "hover"}`} onClick={handleClick}>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" viewBox="0 0 16 16">
                    <path stroke="white" strokeWidth={"1"} d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
                </svg>
            </div>

        </>
    );
}

export default Chevron;