import React from "react";
import CourseInput from "./courseInput";
import LittleX from "./littleX";
import MetricSelect from "./metricSelect";

const Duration = (props) => {
    return (
        <>
            {
                props.duration === -1 

                ?

                <>
                    <div className="input-field">
                        <div className="top-input">
                            Full-time Duration
                            <span className="required-star">
                                *
                            </span>
                        </div>
                        <input style={{ width: "auto", marginTop: "-10px", cursor: "pointer" }} className="bottom-input" type={ "checkbox" } checked={ true } required={true} onChange={() => {
                            props.updateDuration(1);
                        }}/>
                    </div>
                </>

                :

                <>
                    <LittleX updateValue={props.updateDuration}/>
                    <div style={{ width: "45%" }}>
                        <CourseInput star={true} top={"Duration"} value={props.duration} updateValue={props.updateDuration} type={"number"} required={true} autoComplete={"off"} min={0}/>
                    </div>
                    <div style={{ width: "45%" }}>
                        <MetricSelect star={true} top={"Metric"} value={props.metric} updateValue={props.updateMetric} metrics={
                            [
                                {
                                    name: "Minutes",
                                    value: "minutes"
                                },
                                {
                                    name: "Hours",
                                    value: "hours"
                                },
                                {
                                    name: "Days",
                                    value: "days"
                                }
                        ]
                            
                        } type={"number"} required={true} autoComplete={"off"} min={0}/>
                    </div>
                </>
            }
        </>
    );
}

export default Duration;