import React, { useState } from "react";
import Dashboard from "./dashboard";
import Navigation from "./navigation";
import useScrollToTop from "./useScrollToTop";
import NavInfoSemester from "./navInfoSemester";
import NavInfoSemesterCollapsed from "./navInfoSemesterCollapsed";

const SemesterDashboard = () => {
    useScrollToTop();
    const [numCourses, setNumCourses] = useState(0);
    const [color, setColor] = useState("#0B73FE");

    function updateNumCourses(num) {
        setNumCourses(num)
    }

    function updateColor(color) {
        setColor(color);
    }
    
    return (
        <>
            <Navigation numCourses={numCourses} boolThree={false} boolTwo={false} bool={true} info={<NavInfoSemester updateColor={updateColor} color={color}/>} infoCollapsed={<NavInfoSemesterCollapsed updateColor={updateColor} color={color}/>}/>
            <Dashboard numCourses={numCourses} updateNumCourses={updateNumCourses}/>
        </>
    );
}

export default SemesterDashboard;