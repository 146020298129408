import moment from 'moment-timezone';

const handleStatusDate = (start, end, submissionsLength) => {
    // Parse the start and end dates with the provided time zone
    const startDate = moment.utc(start).local();
    const endDate = moment.utc(end).local();
    const current = moment();

    if (submissionsLength > 0) {
        return "Submitted"
    }

    if (current.isAfter(endDate)) {
        return "Submitted";
    } else if (current.isBetween(startDate, endDate)) {
        return "In Progress";
    } else if (current.isBefore(startDate)) {
        return "Upcoming";
    } else {
        return "Pending";
    }
}

export default handleStatusDate;