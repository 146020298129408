import React from "react";

const StudentRow = (props) => {
    const handleClick = () => {
        props.updateName(props.student.name)
        props.updateEmail(props.student.email)
        props.updateSearchName(props.student.name)
        props.updateMetric(props.assessmentCode in props.student ? props.student[props.assessmentCode].extraTime.split(" ")[1] : "minutes")
        props.updateExtraTime(props.assessmentCode in props.student ? props.student[props.assessmentCode].extraTime.split(" ")[0] : "0")
        props.updateSearchBool(false);
    }

    return (
        <>
            <div className="names-dropdown-item" onClick={handleClick} style={{ borderTopWidth: props.index === 0 ? "0px" : "1px" }}>
                <div>
                    { props.student.name }
                </div>
                <div>
                    {
                        props.assessmentCode in props.student

                        ?

                            <>
                                Extension: { props.student[props.assessmentCode].extraTime }
                            </>

                        :

                            <>
                                Extension: 0 minutes
                            </>
                    }
                </div>
            </div> 
        </>
    );
}

export default StudentRow;