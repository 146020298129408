import React, { useState } from "react";
import { isDesktop } from "react-device-detect";
import ColorPicker from "./colorPicker";
import PaintBrush from "./paintBrush";

const NavInfoSemesterCollapsed = ({ updateColor, color }) => {

    return (
        <>
            <div className="semester-nav-info" style={{ marginTop: "190px", alignItems: "flex-end" }}>
                {
                    isDesktop

                    ?

                        <>
                            <div className="collapsed" style={{ marginRight: "-5.5px" }}>
                                <ColorPicker color={color} updateColor={updateColor} collapsed={true}/>
                            </div>
                            <div className="collapsed" style={{ marginRight: "-10px" }}>
                                <PaintBrush color={color} />
                            </div>
                        </>

                    :

                        <>
                        </>

                }
            </div>
        </>
    );
}

export default NavInfoSemesterCollapsed;