import React from "react";

const ChevronLeft = (props) => {
    const handleClick = () => {
        props.updateFormIndex(props.formIndex - 1);
    }

    return (
        <>
            <div className={`chevron-container ${props.formIndex === 0 ? "" : "hover"}`} onClick={handleClick}>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" viewBox="0 0 16 16">
                    <path stroke="white" strokeWidth={"1"} d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0" />
                </svg>
            </div>

        </>
    );
}

export default ChevronLeft;