import React from "react";

const Plus = (props) => {
    return (
        <>
            <div className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="20"height="20" viewBox="0 0 16 16">
                    <path fill="currentColor" stroke="currentColor" strokeWidth="1" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                </svg>
            </div>
        </>
    );
}

export default Plus;